import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from 'src/app/svc/config';
import { State } from './State';
import { BaseService } from 'src/app/svc/company/base.service';

@Injectable({
  providedIn: 'root'
})

export class StateService extends BaseService{
  baseURL = `${Config.vWebUrl}state_uf/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getRows() {
    return this.http.get<State>(this.baseURL + '-1', {
      headers: this.getCommonHeaders_compToken2Conditional(false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getById(id){
    return this.http.get<State>(this.baseURL + `${id}`, {
      headers: this.getCommonHeaders_compToken2Conditional(false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getAllFromCountry(country_id) {
    return this.http.get<State>(this.baseURL + `${country_id}/-1`, {
      headers: this.getCommonHeaders_compToken2Conditional(false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

}
