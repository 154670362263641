import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {
  private cashRegisterState = new BehaviorSubject<boolean>(this.getInitialCashRegisterState());

  private getInitialCashRegisterState(): boolean {
    const savedState = localStorage.getItem('cashRegisterState');
    return savedState ? JSON.parse(savedState) : false;  // false representa caixa fechado por padrão
  }

  setCashRegisterState(state: boolean): void {
    this.cashRegisterState.next(state);
    localStorage.setItem('cashRegisterState', JSON.stringify(state));
  }

  get currentCashRegisterState() {
    return this.cashRegisterState.asObservable();
  }
}
