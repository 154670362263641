
export class CompanyUnlockDate {
  company_unlock_date_id: number;
  company_id: number;
  assinatura_id: number;
  status: string;
  blocked_by_payment: boolean;
  // all dates are in UTC
  date_end_trial: any;
  date_end_disblock: any;
  today_date: any;
  date_end_trial_str: string;
  date_end_disblock_str: string;
}