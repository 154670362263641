import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NovaAssinaturaComponent } from './nova-assinatura.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [NovaAssinaturaComponent],
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    RouterModule,
    FormsModule
  ]
})
export class NovaAssinaturaModule { }
