import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { CompanyUtils } from '../company/company-utils';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class LinkThemeService extends BaseService {

  baseURL = `${Config.vBusUrl}company_link_theme/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAvailableThemes(){
    // TODO: this function will be called when other themes become available
    return this.http.get(this.baseURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  setLinkTheme(objParam: any) {
    const obj = {...objParam};
    obj.companyId = CompanyUtils.getCompanyId();
    return this.http.patch(`${this.baseURL}${obj.companyId}`, JSON.stringify(obj), {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

}
