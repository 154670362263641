
<label *ngIf="isLoading" class="alignTxtCenter" style="width: 100%;">Carregando...</label>

<div *ngIf="!isLoading" class="client-detail mainPage">

  <div class="client-div">
    <label class="client-label">Nome:</label>
    <label>{{data.name}}</label>
  </div>

  <div class="client-div">
    <label class="client-label">E-mail:</label>
    <label>{{data.email}}</label>
  </div>

  <div class="client-div">
    <label class="client-label">Telefone:</label>
    <label>{{data.phone}}</label>
  </div>

  <div class="client-div">
    <label class="client-label">Total consumido:</label>
    <label>R${{data.value}}</label>
  </div>

  <div class="client-div">
    <label class="client-label">Ticket Médio:</label>
    <label>R${{data.ticket}}</label>
  </div>

  <div class="client-div">
    <label class="client-label">Data do ultimo agendamento:</label>
    <label>{{data.data_consumo}}</label>
  </div>

</div>