import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Config } from '../../config';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';


@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {
  baseURL = `${Config.vBusUrl}category/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAll() {
    const queryParams = new HttpParams().set("system_platform", 2);
    const httpContext = new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false);
    return this.http.get(this.baseURL + '-1', {
      params: queryParams,
      context: httpContext
    })
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

  getbyId(id) {
    return this.http.get(this.baseURL + `${id}`)
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.result;
        }),
        catchError(this.handleErrors)
    );
  }

}
