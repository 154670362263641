import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PorcentTwoDigitsPipe } from './porcentTwoDigits.pipe';
import { PriceBRPipe } from './price.pipe';


@NgModule({
  declarations: [
    PriceBRPipe,
    PorcentTwoDigitsPipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PriceBRPipe,
    PorcentTwoDigitsPipe
  ]
})
export class PriceBRModule {}
