import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class ServicoPublService extends BaseService {

  baseURL = `${Config.vWebUrl}servico_edp/`;
  servCatURL = `${Config.vWebUrl}service_category_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAllServices(company_id){
    return this.http.get(this.baseURL + `-1/${company_id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getAllServiceCategoriesLink01(company_id) {
    return this.http.get(this.servCatURL + `-1/${company_id}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

}
