
<div class="mainPage">

  

  <div class="mainPage">
    
    <div class="centerInScreen">
      <form class="formLogin">
        <div>

          <img src="assets/logo.png"/>

          <div class="form-field">
            <div>
                <label position="floating" class="labelCampos">Alterar email de login</label>
                <input class="camposCadastro" type="email" [(ngModel)]="data.email" name="pass" placeholder="Insira o novo email"/>
            </div>

            <div class="cadastreLabelDiv">
                <button class="btnResgate largeBtn" (click)="change()">Alterar</button>
            </div>
          </div>

        </div>
      </form>
    </div>

  </div>
</div>

