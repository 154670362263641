import { ProductOptionValue } from "./productOptionValue";

export class ProductOptionName {
    product_option_name_id: string;
    name: string;
    values: Array<ProductOptionValue>;
    selectedOption: ProductOptionValue;

    is_greater_price: boolean;
    considers_medium_price: boolean;
    list_position: number;
    // incremental: boolean;    // this property does not exist in PHP (and it is not supposed to)!
}
