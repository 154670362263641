import { ProductAditional } from './productAditional';

export class ProductAditionalCateg {
    product_aditional_categ_id: string;
    name: string;
    values: Array<ProductAditional>;
    hasChoosedValue: boolean = false;

    max_per_product: number;
    list_position: number;
}
