import { CompanyFreightConfigInterface } from "../company/frete-bairro/company-freight-config";
import { FreteBairroMain } from "../company/frete-bairro/frete-bairro";
import { FreteBase } from "../company/frete-bairro/frete-base";
import { FreteDistancia } from "../company/frete-bairro/frete-distancia";
import { FreteUnico } from "../company/frete-bairro/frete-unico";



export class FreteBaseWeb extends FreteBase {

  frete_unico: FreteUnico;

  frete_bairro: FreteBairroMain[];

  frete_distancia: FreteDistancia[];

  company_freight_config: CompanyFreightConfigInterface;

  constructor(){
    super();
    this.frete_unico = new FreteUnico();
    this.frete_bairro = [];
    this.frete_distancia = [];
  }

}