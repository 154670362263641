import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrMaskerModule } from 'br-mask';
import { AgmCoreModule } from '@agm/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../svc/globals';
import { PriceBRModule } from '../../app/svc/priceBR/priceBR.module';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPrintModule } from 'ngx-print';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AuthGuard } from '../svc/company/auth/auth.guard';
import { QRCodeModule } from 'angularx-qrcode';
import { PaywallModule } from '../company/paywall/paywall.module';
import { CapitalizeFirstLetterModule } from '../svc/capitalizeFirstLetter/capitalizeFirstLetter.module';
import { AmbienteAgendaRoutingModule } from './ambiente-agenda-routing.module';
import { CommonAmbienteModule } from '../common-ambiente/common-ambiente.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localePt from '@angular/common/locales/pt';


// Custom components
import { AmbienteAgendaComponent } from './ambiente-agenda.component';
import { AgendaClientDetailComponent } from '../company/agenda-client-detail/agenda-client-detail.component';
import { SubscriptionFeedbackModule } from '../subscription-feedback/subscription-feedback.module';
import { WaitContactComponent } from '../company/wait-contact/wait-contact.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
registerLocaleData(localePt)

@NgModule({
  declarations: [
    AmbienteAgendaComponent,
    AgendaClientDetailComponent,
    WaitContactComponent
  ],
  imports: [
    CommonModule,
    PriceBRModule,
    CapitalizeFirstLetterModule,
    FormsModule,
    NgxUiLoaderModule,
    HttpClientModule,
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    NgbModule,
    NgxChartsModule,
    BrMaskerModule,
    NgxPrintModule,
    QRCodeModule,
    ClipboardModule,
    CommonAmbienteModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwSB5_1xf6RKWQojoLXerSnh_bI4fh0Nc',
      libraries: ['places']
    }),
    PaywallModule,
    SubscriptionFeedbackModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AmbienteAgendaRoutingModule,
  ],
  providers: [
    Globals,
    NgbModule,
    AuthGuard
  ],
  bootstrap: [AmbienteAgendaComponent],
  exports: [AmbienteAgendaComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AmbienteAgendaModule { }
