

<div class="autoLayout paddingMedium flex-column w-100 h-100">

  <h2 class="headlineBold neutralColor800">Informações da empresa</h2>

  <form class="autoLayout flex-column" [formGroup]="subscriptionForm" [ngClass]="{'w-100' : (isMobile == true), 'w-50' : (isMobile == false)}">

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Nome da empresa</label>
      <input class="inputEnabled inputLight w-100" type="text" formControlName="nome_fantasia"/>
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <div class="autoLayout w-100">
        <div class="autoLayout w-50" (click)="isCpfSelect(true)">
          <input type="radio" [checked]="isCPF" (click)="isCpfSelect(true)">
          <label class="body1Semibold neutralColor600 textSmall">CPF</label>
        </div>
  
        <div class="autoLayout w-50" (click)="isCpfSelect(false)">
          <input type="radio" [checked]="!isCPF" (click)="isCpfSelect(false)">
          <label class="body1Semibold neutralColor600 textSmall">CNPJ</label>
        </div>
      </div>
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100" *ngIf="isCPF">
        <label class="body1Bold neutralColor800 textSmall">CPF</label>
        <input class="inputEnabled inputLight w-100" type="text" inputmode="numeric" [brmasker]="{mask: '000.000.000-00', type:'num', len:14}" formControlName="document_numberCPF">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100" *ngIf="!isCPF">
        <label class="body1Bold neutralColor800 textSmall">CNPJ</label>
        <input class="inputEnabled inputLight w-100" type="text" inputmode="numeric" [brmasker]="{mask: '00.000.000/0000-00', type:'num', len:18}" formControlName="document_numberCNPJ">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <div class="w-100">
        <label class="body1Bold neutralColor800 textSmall" style="width: 20%;">Estado</label>
        <label class="body1Bold neutralColor800 textSmall" style="width: 70%; margin-left: 10%;">Cidade</label>
      </div>
      <div class="w-100">
        <select class="inputEnabled inputLight" style="width: 20%;" name="state" (change)="getCities()" formControlName="state_id">
          <!-- <option disabled [value]="undefined">Estado</option> -->
          <option *ngFor="let s of states" [value]='s.state_id' selected="s.state_id === userData.state_id">{{s.sigla}}</option>
        </select>

        <button class="inputEnabled inputLight"  style="width: 70%; margin-left: 10%; text-align: start; padding-left: 5px;" (click)="showCityLst = true">
          {{chosenCity}}
        </button>
      </div>
    </div>
    

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Rua</label>
      <input class="inputEnabled inputLight w-100" type="text"  formControlName="street">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Número</label>
      <input class="inputEnabled inputLight w-100" type="text"  formControlName="street_number">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Complemento</label>
      <input class="inputEnabled inputLight w-100" type="text"  formControlName="complement">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Bairro</label>
      <input class="inputEnabled inputLight w-100" type="text"  formControlName="neighborhood">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">CEP</label>
      <input class="inputEnabled inputLight w-100" type="text" formControlName="zipcode" inputmode="numeric" [brmasker]="{mask:'00000-000', len:9}">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">DDD do telefone</label>
      <input placeholder="XX" class="inputEnabled inputLight w-100" type="text" inputmode="numeric" [brmasker]="{mask:'00', len:2}" formControlName="ddd">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Número de telefone (sem DDD) *</label>
      <input placeholder="XXXXX-XXXX" class="inputEnabled inputLight w-100" type="tel" inputmode="tel" maxlength="9" formControlName="number">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">WhatsApp comercial (receber pedidos)</label>
      <input class="inputEnabled inputLight w-100" type="tel" inputmode="tel" [brmasker]="{phone: true}"
        formControlName="whatsphone" name="whatsphone">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Categoria</label>
      <select class="inputEnabled inputLight w-100" name="category_id" formControlName="category_id">
        <option *ngFor="let c of categories" [value]='c.category_id'>
          {{c.name}}
        </option>
      </select>
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Nome do responsável</label>
      <input class="inputEnabled inputLight w-100" type="text" formControlName="name">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">E-mail</label>
      <input class="inputEnabled inputLight w-100" type="email" formControlName="email" name="email">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Senha</label>
      <input class="inputEnabled inputLight w-100" type="password" formControlName="password" name="password">
    </div>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Confirmar senha</label>
      <input class="inputEnabled inputLight w-100" type="password" formControlName="passAux" name="passAux">
    </div>

    <h2 class="headlineBold neutralColor800 marginVerticalSmall">Informações de cobrança</h2>

    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
      <label class="body1Bold neutralColor800 textSmall">Plano</label>
      <select class="inputEnabled inputLight w-100" name="plan_id" formControlName="plan_id" (change)="selectPlan()">
        <option *ngFor="let p of plans" [value]='p.plan_id'>
          {{p.name}} - R$ {{p.amount | priceBR}}
        </option>
      </select>
    </div>

    <!-- Selecionar modalidade de cobranca, cartão ou boleto -->
    <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100" *ngIf="planoPermiteEscolherCreditoBoleto">
      <label class="body1Bold neutralColor800 textSmall">Escolha entre pagamento via cartão de crédito ou boleto:</label>
      <div class="autoLayout w-100">
        <div class="autoLayout w-50" (click)="isPaymentCartaoSelect(true)">
          <input type="radio" [checked]="isPaymentCartao" (click)="isPaymentCartaoSelect(true)" >
          <label class="body1Semibold neutralColor600 textSmall">Cartão de crédito</label>
        </div>

        <div class="autoLayout w-50" (click)="isPaymentCartaoSelect(false)">
          <input type="radio" [checked]="!isPaymentCartao" (click)="isPaymentCartaoSelect(false)" >
          <label class="body1Semibold neutralColor600 textSmall">Boleto</label>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="isPaymentCartao">

      <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
        <label class="body1Bold neutralColor800 textSmall">Número do cartão *</label>
        <input class="inputEnabled inputLight w-100" type="text" inputmode="numeric" [brmasker]="{mask:'0000 0000 0000 0000 0000', len:24}" formControlName="card_number">
      </div>

      <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
        <label class="body1Bold neutralColor800 textSmall">Nome no cartão *</label>
        <input class="inputEnabled inputLight w-100" type="text" formControlName="card_holder_name">
      </div>

      <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
        <label class="body1Bold neutralColor800 textSmall">Expiração do cartão *</label>
        <input class="inputEnabled inputLight w-100" type="text" inputmode="numeric" [brmasker]="{mask:'00/00', len:5}" formControlName="card_expiration_date">
      </div>

      <div class="autoLayout marginVerticalSmall flex-column align-items-start w-100">
        <label class="body1Bold neutralColor800 textSmall">Dígito verificador *</label>
        <input class="inputEnabled inputLight w-100" type="text" inputmode="numeric" [brmasker]="{mask:'0000', len:4}" formControlName="card_cvv">
      </div>

    </div>

    <div class="autoLayout marginVerticalMedium flex-column w-100">

      <div class="d-flex flex-row align-items-center">

        <div class="d-flex flex-column">
          <label>Solucione o captcha abaixo para continuar</label>
          <img class="recaptchaImg" src="{{recaptchaImgSrc}}" alt="recaptcha">
        </div>

        <div>
          <button class="btnOutlinedOrange marginHorizontalSmall" (click)="getRecaptcha()">Recarregar imagem</button>
        </div>
      </div>

      <div class="autoLayout">
        <input class="inputEnabled inputLight" type="text" formControlName="recaptchaSolution">
      </div>
      
    </div>

    <div class="autoLayout marginVerticalSmall w-100">
      <label class="body1Bold neutralColor800 textBig text-center">A cobrança ocorrerá mensalmente após os {{trial_days}} dias de utilização.</label>
    </div>

    <div class="autoLayout marginVerticalSmall w-100">
      <label><input style="width: fit-content;" type="checkbox" [(ngModel)]="acceptedTerms"
          name="usageTerms" [ngModelOptions]="{standalone: true}"> Ao se cadastrar você concorda com os <a
          href="https://apiprod.loopingapp.com.br:7096/terms/termo_de_uso_whatsagenda.pdf" target="_blank">termos de uso</a>!
      </label>
    </div>

    <div class="autoLayout marginVerticalSmall w-100">
      <button class="btnContainedOrange textBig" (click)="register()">Cadastrar</button>
    </div>

  </form>



  <!-- MOSTRA LISTA DE CIDADES -->
  <div *ngIf="showCityLst"
    style="position: fixed; width: 80%; height: 80%; left: 10%; top: 10%; box-shadow: 2px 2px 5px; background-color: white; padding: 1rem; border-radius: 5px;">

    <div style="position: relative; width: 100%;">
      <label style="width: 100%; text-align: center; font-weight: bold;">Cidade</label>
    </div>

    <div style="position: relative; width: 100%; text-align: start;">
      <i class="fa fa-search" aria-hidden="true"></i>
      <input class="input-style-address" type="search" [(ngModel)]="cityStr" style="width: 90%;"
        (ngModelChange)="searchCity()" name="citySearch" placeholder="Pesquisar...">
    </div>

    <div style="position: relative; width: 100%; border-bottom: 1px solid grey; margin-top: 1rem;">
      <label
        style="width: 100%; text-align: start; font-weight: bold; font-weight: normal; margin-left: 2rem;">Selecione a
        cidade...</label>
    </div>

    <div
      style="overflow-y: scroll; position: relative; max-height: 65%; min-height: 65%; width: 100%; border-bottom: 1px solid grey;">
      <div *ngFor="let s of filteredCityLst" (click)="onCitySelected(s)"
        style="width: 100%; text-align: start; cursor: pointer; border-bottom: 1px solid grey; height: fit-content; min-height: 3rem; padding-top: 0.5rem;">
        <label style="font-weight: normal; padding-left: 2rem; cursor: pointer;">{{s.name}}</label>
      </div>
    </div>

    <div style="position: relative; margin-top: 2rem; display: flex; justify-content: center;">
      <button type="submit" (click)="showCityLst = false;"
        style="border-radius: 5px; border-style: none; color: white; background-color: orangered; padding: 0.5rem;">Cancelar</button>
    </div>
  </div>

</div>