import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Product } from '../svc/product/product';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../svc/product/product.service';
import { ProductCategory } from '../svc/product/product-category';
import { Pedido } from '../svc/pedido/pedido';
import { PedidoHandler } from '../svc/pedido/pedidoHandler';
import { WorkhourService2 } from '../svc/workhour/workhour2.service';
import { Workhour } from '../svc/workhour/workhour';
import { Globals } from '../svc/globals';
import { CompanyService } from '../svc/company/company.service';
import { Company } from '../svc/company/company';
import { Variant } from '../svc/product/variant';
import { City } from '../services/city/City';
import { CityService } from '../services/city/city.service';
import { AppuserAddress } from '../svc/appuser/appuserAddress';
// import { MapsAPILoader } from '@agm/core';
import { Bairro } from '../services/bairro/bairro';
import { BairroService } from '../services/bairro/bairro.service';
import { State } from '../services/state/State';
import { StateService } from '../services/state/state.service';
import { FreteBairro } from '../svc/frete-bairro/frete-bairro';
import { Utils } from '../shared/utils';
import { of, Subscription } from 'rxjs';
import { ConnectionService } from '../svc/pedido/connection.service';
import { CompanyDeliveryTime } from '../svc/delivery-time/delivery-time';
import { DeliveryTimeService } from '../svc/delivery-time/delivery-time.service';
import * as smoothscroll from "smoothscroll-polyfill";
import { GeocodingService } from '../svc/frete/geocoding.service';
// import { FreteBaseWeb } from '../svc/frete/frete-base-web';
import { FreteWebService } from '../svc/frete/frete-web.service';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { Country } from '../svc/country/country';
import { CountryService } from '../svc/country/country.service';
import { AppuserService } from '../svc/appuser/appuser.service';
import { CompanyDataHandler } from '../product-list/company-data-handler/company-data-handler';


@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit {
  pedido: Pedido;
  company_handle: string;
  menuLst: ProductCategory[];
  searchLst: ProductCategory[];
  wrongHandle = false;
  isLoading:boolean = false;
  unavailableDivHeight: number = 768;
  searched = false;
  searchText: string = '';

  constructor(
    public companyDataHandler: CompanyDataHandler,
    public globals: Globals,
    private actRoute: ActivatedRoute,
    private router: Router,
    private prodSvc: ProductService,
    private pedidoHandler: PedidoHandler,
    private whSvc: WorkhourService2,
    private cmpSvc: CompanyService,
    private citySvc: CityService,
    private bairroSvc: BairroService,
    private refresher: ChangeDetectorRef,
    private stateSvc: StateService,
    private connectionSvc: ConnectionService,
    private formBuilder: FormBuilder,
    private ngxUiLoader: NgxUiLoaderService,
    private appuserSvc: AppuserService,
  ) { }

  ngOnInit(): void {
      this.company_handle = this.actRoute.snapshot.paramMap.get("cid");
      console.log(this.company_handle);
      if (this.pedidoHandler.getPedido().productLst != undefined && this.pedidoHandler.getPedido().productLst.length > 0) {
        this.menuLst = this.prodSvc.getMenuLst();
        this.searchLst = this.prodSvc.getMenuLst();
        this.pedido = this.pedidoHandler.getPedido();
        // console.log(this.pedido);
      } else {
        this.getProducts();
        
        // TODO remove mock pedido
        // const mockPedidoData = new Pedido();
        // Object.keys(mockPedido).forEach((key)=>mockPedidoData[key] = mockPedido[key]);
        // this.pedidoHandler.setPedido(mockPedidoData);
        // this.pedido = this.pedidoHandler.getPedido();
      }
  
      this.getWorkhours();
      this.getCompany();
      console.log(this.menuLst)
      console.log(this.searchLst)
      console.log(this.pedido)
  }

  expandCategory(pcat: ProductCategory) {
    pcat.expanded = !pcat.expanded;
  }


  getCompany() {
    if (this.companyDataHandler.companyData.company_id) {
      // already got info, return
      return;
    }
    this.ngxUiLoader.start();
    this.cmpSvc.getCompanyByHandle(this.company_handle).pipe(take(1)).subscribe(
      (result) => {
        this.companyDataHandler.companyData = result;

        if(
          this.companyDataHandler.companyData.companyPayment.length === 0 
          && result.deliveryMethods.length == 0 
          && this.companyDataHandler.companyData.whatsapp_phone == undefined){
            this.wrongHandle = true;
            return;
        }

        /**
         * TODO
         * TODO
         * TODO
         * analise freteValueOnCompany applications
         */
        // this.freteValueOnCompany = this.company.frete;

        this.companyDataHandler.companyData.latitude = 
          Number.parseFloat(this.companyDataHandler.companyData.latitude.toString());
        this.companyDataHandler.companyData.longitude = 
          Number.parseFloat(this.companyDataHandler.companyData.longitude.toString());
        //
        this.companyDataHandler.deliveryMethods = result.deliveryMethods;
        this.pedido.opcao_retirada_id = this.companyDataHandler.deliveryMethods[0].delivery_method_id;

        this.ngxUiLoader.stop();
        
        this.updateUnavailableDivHeight();
      },
      (err) => {
        this.ngxUiLoader.stop();
        console.log(err);
      }
    );
  }

  searchProducts() {

    if (this.searchText.length === 0 || this.searchText === '' || this.searchText === undefined) {
      this.searchLst = this.menuLst;
      return;
    }

    this.searchLst = [];
    this.menuLst.forEach(categ => {
      categ.productLst.forEach(prod => {
        if(prod.title.toLowerCase().includes(this.searchText.toLowerCase())){

          let catIncluded = false;
          this.searchLst.forEach(c => {
            if(c.product_category_id == categ.product_category_id){
              catIncluded = true;
              c.productLst.push(prod);
            }
          });


          if(!catIncluded){
            const tempCat = new ProductCategory();
            tempCat.product_category_id = categ.product_category_id;
            tempCat.name = categ.name;
            tempCat.prodcateg_img = categ.prodcateg_img;
            tempCat.expanded = true;
            tempCat.productLst = [];
            tempCat.productLst.push(prod);

            this.searchLst.push(tempCat);
          }
        }
      });
    });

  }

  clearSearch() {
    this.searchText = '';
    this.searched = false;
  }

  getProducts() {
    this.isLoading = true;

    // let startTime = performance.now();
    // let endTimeRetorno;
    // let endTimeProcessamento;

    this.prodSvc.getToClientByCompanyId02(this.company_handle).pipe(take(1)).subscribe(
      (result) => {
        this.isLoading = false;
        // endTimeRetorno = performance.now();
        // this.printTimeDiffs('Retorno API: ', startTime, endTimeRetorno);
        // console.log(result);
        result.forEach((cat) => {
          cat.productLst.forEach((prod) => {

            prod.categoriaName = cat.name;

            // treat gender string
            if (prod.gender != undefined && prod.gender != "") {
              prod.gender = "Gênero: " + prod.gender;
            }

            if (!prod.img_path) {
              prod.img_path = '../../assets/defaultImageWhite.png';
            }
            
          });
        });

        this.menuLst = result;
        this.searchLst = result;
        // endTimeProcessamento = performance.now();
        // this.printTimeDiffs('Fim processamento: ', startTime, endTimeProcessamento);

        //
        this.updateUnavailableDivHeight();
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.globals.openDialog('Erro', "Não foi possível carregar os produtos");
      }
    );
  }

  updateUnavailableDivHeight(){

    if (this.companyDataHandler.companyData.blocked_by_payment) {
      //
      const div = document.getElementById('contentProductsListDiv');
      const tmpWindowHeight = div.offsetHeight;
      this.unavailableDivHeight = (tmpWindowHeight >= this.unavailableDivHeight) ? (tmpWindowHeight+100) : this.unavailableDivHeight;  
      this.refresher.detectChanges();
    }
  }

  getWorkhours() {
    if (this.companyDataHandler.companyWorkhours.length > 0){
      // does not query again
      this.checkTodayWorkhour();
      return;
    }
    this.ngxUiLoader.start();
    this.whSvc.getAllByCompanyHandle(this.company_handle).pipe(take(1)).subscribe(
      (rows: Array<Workhour>) => {
        this.ngxUiLoader.stop();
        this.companyDataHandler.companyWorkhours = rows;
        this.checkTodayWorkhour();
      },
      (error) => {
        this.ngxUiLoader.stop();
        console.log('ERR on getWorkhours():', error);
      }
    );
  }

  checkTodayWorkhour() {
    for(let i = 1; i < 8; i++){
      const whArray = this.companyDataHandler.companyWorkhours.filter(wh => {
        return wh.week_day_id == i;
      });

      if(whArray.length > 1){
        whArray[1].isSecondHour = true;
      }
    }

    const {shopStatus, closeOpenTime} = this.companyDataHandler.checkTodayWorkhour();
  }

}
