import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Globals } from '../../svc/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyUtils } from '../../svc/company/company-utils';
import { CompanyUser } from '../../svc/company/company-user/CompanyUser';
import { CompanyService } from 'src/app/svc/company/company.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-company-top-bar',
  templateUrl: './company-top-bar.component.html',
  styleUrls: ['./company-top-bar.component.scss']
})
export class CompanyTopBarComponent implements OnInit {

  @Output() menuBtnClicked = new EventEmitter();

  hasNotification = false;

  user: CompanyUser = new CompanyUser();

  isAdmin = false;
  company_id = CompanyUtils.getCompanyId();
  currentTheme: string = 'theme-default';
  gerenciarFuncionarios = false;
  planosAssinaturas = false;

  constructor(
    public globals: Globals,
    public router: Router,
    private route: ActivatedRoute,
    private companySvc: CompanyService,

  ) {
    this.user.name = CompanyUtils.getCompanyName();
  }

  ngOnInit() {
    this.gerenciarFuncionarios = CompanyUtils.canGerenciarFuncionarios();
    this.planosAssinaturas = CompanyUtils.canPlanosAssinaturas();
    this.isAdmin = CompanyUtils.getUserType() != 2;
    this.getCompanyTheme();
  }

  /**
   * Gets company's page theme
   */
  getCompanyTheme() {
    // this.currentTheme = this.companySvc.linkThemeName.getValue();
    this.companySvc.linkThemeName.pipe(take(2)).subscribe({
      next: (resCurrentTheme) => {
        this.currentTheme = resCurrentTheme;
      },
    });
  }

  goTo(param: string){
    this.router.navigate([param], { relativeTo: this.route });
    this.menuBtnClicked.emit();
  }

  logout(){
    CompanyUtils.clearStorageForLogout();
    this.globals.companyNoPay = false;
    this.globals.checkAgenda = false;
    this.router.navigateByUrl('empresa/login');
    window.location.reload();
  }

  toggleGlobalsPlayAlarm(event: Event) {
    event.stopPropagation();
    if(this.globals.playAlarm == 1){
      alert("Alerta de notificação desligado")
    }else{
      alert("Alerta de notificação ligado")
    }
    this.globals.playAlarm = this.globals.playAlarm === 1 ? 3 : 1;
    CompanyUtils.setAlarmPreference(this.globals.playAlarm);
    console.log(this.globals.playAlarm);
  }

  goToAgendamentoList() {
    this.router.navigateByUrl('ambiente/agenda/agendamento-list');
  }

  openExternalLink(linkParam) {
    window.open(linkParam, '_blank');
  }

  goToFinancial2() {
    this.closeSideBar();
    this.router.navigateByUrl('ambiente/agenda/financeiro/2');
  }

  closeSideBar(){

    if(this.globals.showPageMainContent || this.router.url.includes('agenda-home')){
      return;
    }

    document.getElementById("sidebar").style.left = "-100%";
    this.globals.showPageMainContent = true;
  }


}
