import { CompanyFreightConfigInterface } from "./company-freight-config";
import { FreteBairroMain } from "./frete-bairro";
import { FreteDistancia } from "./frete-distancia";
import { FreteUnico } from "./frete-unico";


export class FreteBase {

  company_id: number;
  frete_mode_other_locations: number;

  frete_unico: FreteUnico;

  frete_bairro: FreteBairroMain[];

  frete_distancia: FreteDistancia[];

  company_freight_config: CompanyFreightConfigInterface;

  constructor(){
    this.frete_unico = new FreteUnico();
    this.frete_bairro = [];
    this.frete_distancia = [];
  }

}