<button hidden="true" id="openMainAddrSelection" data-bs-toggle="modal" data-bs-target="#mainAddrSelection">Hidden Button Select endereço</button>
<button hidden="true" id="openNewAddrRegister" data-bs-toggle="modal" data-bs-target="#newaddr" data-backdrop="static" data-keyboard="false">Hidden Button novo endereco</button>


<div id="unavailableDiv" class="unavailableDiv" *ngIf="companyDataHandler.companyData.blocked_by_payment" [style.height.px]="unavailableDivHeight">

  <img src="assets/utilities/block_black_24dp.svg" alt="Indisponivel">

  <div>
    <h2>Link indisponível.</h2>
    <p>Entre em contato com o estabelecimento</p>
  </div>

</div>

<!-- modal sobre -->
<div class="modal fade info" id="aboutCompanyModal" tabindex="-1" aria-labelledby="aboutCompanyModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalinfoLabel">Informações da loja</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-info-title">Sobre o estabelecimento: </h4>
        <div class="modal-info-description">
          <p>{{companyDataHandler.companyData.description}}</p>
        </div>

        <h4 class="modal-info-title">Endereço: </h4>
        <p class="address">{{companyDataHandler.companyData.address_complete}} - {{companyDataHandler.companyData.city_name}} - {{companyDataHandler.companyData.state_name}}</p>
      </div>
      <div class="modal-footer-info marginVerticalMedium">
        <button type="button" class="btn close-info" data-bs-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>
<!-- end modal sobre -->

<div id="contentProductsListDiv" class="content-products-list" *ngIf="!wrongHandle">

  <ngx-ui-loader></ngx-ui-loader>

  <div class="banner">
    <img src="{{companyDataHandler.companyData.img_banner_link}}" class="cover" alt="">
  </div>

  <div class="bannerProfileImg" *ngIf="companyDataHandler.companyData.img_cartao_link != undefined">
    <div class="profileDiv">
      <img src="{{companyDataHandler.companyData.img_cartao_link}}" class="img-fluid profile" alt="">
    </div>
  </div>

  <div id="companyHeaderInfo" class="row">

    <div class="col-12 d-flex justify-content-center">
      <p class="company-name noBottomPadding">{{companyDataHandler.companyData.nome_fantasia}}</p>
    </div>
  
    <div class="col-6 d-flex justify-content-center">

      <button class="aboutBtn" data-bs-toggle="modal" data-bs-target="#aboutCompanyModal">
        Sobre
        <img src="assets/utilities/info.svg" alt="info">
      </button>
  
    </div>
  
  </div>

  <div class="autoLayout paddingSmall w-100">
    <label class="body1Regular neutralColor600 textBig">{{companyDataHandler.companyData.description}}</label>
  </div>

  <div class="autoLayout w-100" *ngIf="companyDataHandler.frete.company_freight_config?.company_id">

    <div class="columnFlexLayout w-50 paddingSmall" *ngIf="companyDataHandler.frete.company_freight_config?.free_freight_above">
      <label class="body1Regular neutralColor600 textMedium">Frete grátis acima de R$ {{companyDataHandler.frete.company_freight_config?.free_freight_above}}</label>
    </div>

    <div class="columnFlexLayout w-50 paddingSmall" *ngIf="this.companyDataHandler.frete.company_freight_config?.delivery_minimum_value">
      <label class="body1Regular neutralColor600 textMedium">Valor mínimo para delivery R$ {{companyDataHandler.frete.company_freight_config?.delivery_minimum_value}}</label>
    </div>

  </div>

  <div style="padding-top: 5px;">
    <div class="row d-flex justify-content-center">


      <div class="col-12 marginVerticalSmall">
        <div class="autoLayout w-100">
          <div class="autoLayout borderMainColorSecondary500 borderRadiusBig w-75" style="height: 2.5rem;">

            <input type="text" [(ngModel)]="searchText" class="inputEnabled h-100 borderRadiusLeftBig" style="border: none; width: 90%;"
                    placeholder="Pesquise pelo nome do produto" (ngModelChange)="searchProducts()"/>
            <i class="fa fa-search autoLayout neutralColor800 backgroundMainColorSecondary500 borderRadiusRightBig h-100" style="width: 10%;" aria-hidden="true"></i>

          </div>
        </div>
      </div>

      <div class="productCategoryHorScroll paddingHorizontalMedium w-100">
        <div *ngFor="let categItemHorizontal of searchLst; let categIndex = index;">
          
          <div class="productCategoryHor marginHorizontalSmall" (click)="scrollToCategory(categIndex)">
            <img class="roundImg" src="{{categItemHorizontal.prodcateg_img}}" alt="imagem categoria">
            <p>{{categItemHorizontal.name}}</p>
          </div>

        </div>
      </div>

      <div style="margin-bottom: 5rem;">

        <div class="autoLayout marginVerticalBig w-100" *ngIf="searchLst.length == 0">
          <label *ngIf="searchText == undefined || searchText.length === 0" class="body1Regular neutralColor800 textBig">Nenhum produto cadastrado</label>
          <label *ngIf="searchText != undefined || searchText.length > 0" class="body1Regular neutralColor800 textBig">Nenhum produto encontrado</label>
        </div>

        <div class="d-flex justify-content-center marginVerticalMedium" *ngFor="let categItemVertical of searchLst; let categIndex = index;">
          
          <div class="categoryVerticalDiv d-flex flex-column align-items-center" id="categItemVertical{{categIndex}}">
            
            <div class="d-flex align-items-center productCategoryVert" (click)="expandCategory(categItemVertical)">
              
              <label>{{categItemVertical.name}}</label>
              
              <button>
                <label *ngIf="!categItemVertical.expanded">Veja mais</label>
                <label *ngIf="categItemVertical.expanded">Veja menos</label>
              </button>

            </div>

            <div *ngIf="categItemVertical.expanded" style="width: 100%;">
              <div class="row align-items-center justify-content-left prodElementDiv" *ngFor="let prodItem of categItemVertical.productLst; let prodIndex = index;">
                <div class="col-3 text-center">
                  <img class="prodImg" src="{{prodItem.img_path}}" alt="produto">
                </div>
    
                <div class="col-7 d-flex align-items-center flex-column justify-content-left align-self-start">

                  <div class="autoLayout flex-column align-items-start w-100">
                    <p style="font-weight: bold;">{{prodItem.title}}</p>
    
                    <p class="descriptionP">{{prodItem.description}}</p>
                  </div>
                </div>
    
                <div class="col-2 align-self-end d-flex flex-column">
                  <p class="productPrice helpColorSuccess">{{prodItem.price | priceBR}}</p>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      

    </div>
  </div>

</div>

<div class="modal fade info" id="mainAddrSelection" tabindex="-1" aria-labelledby="mainAddrSelection" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="headlineBold neutralColor800">Selecionar endereço</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="dismissMainsAddrSelection">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

    </div>
  </div>

<div class="modal fade info" id="newaddr" tabindex="-1" aria-labelledby="newaddr" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">


      <div class="modal-header">
        <h5 class="headlineBold neutralColor800">Adicionar endereço</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="dismissNewAddr">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">


        
  </div>
</div>

<div class="modal fade" id="citySelection" tabindex="-1" aria-labelledby="citySelection" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">

          <div style="position: relative; width: 100%;">
            <label style="width: 100%; text-align: center; font-weight: bold;">Cidade</label>
          </div>
      
          <div style="position: relative; width: 100%; border-bottom: 1px solid grey; margin-top: 1rem;">
            <label style="width: 100%; text-align: start; font-weight: bold; font-weight: normal; margin-left: 2rem;">Selecione a cidade...</label>
          </div>
      

      
          <div style="position: relative; margin-top: 2rem; display: flex; justify-content: center;">
            <button type="submit" data-bs-toggle="modal" data-bs-target="#newaddr" style="border-radius: 5px; border-style: none; color: white; background-color: orangered; padding: 0.5rem;">Cancelar</button>
          </div>
    
        </div>
      </div>
    </div>


<div class="modal fade w-100" id="hour" tabindex="-1" aria-labelledby="hour" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalinfoLabel">Informações da loja</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-info-title">Horário de funcionamento: </h4>

        <div class="autoLayout w-100">

          <div class="modal-info-weekday autoLayout flex-column" style="width: 40%;">

            <p class="textMedium">Segunda-feira:</p>
            <p class="textMedium">Terça-feira:</p>
            <p class="textMedium">Quarta-feira:</p>
            <p class="textMedium">Quinta-feira:</p>
            <p class="textMedium">Sexta-feira:</p>
            <p class="textMedium">Sábado:</p>
            <p class="textMedium">Domingo:</p>
  
          </div>

        </div>

      </div>
      <div class="modal-footer-info">

        <div class="autoLayout paddingSmall">
          <button type="button" class="btn close-info" data-bs-dismiss="modal">Fechar</button>
        </div>

      </div>
    </div>
  </div>
</div>
