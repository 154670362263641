import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyUtils } from 'src/app/svc/company/company-utils';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from '../context-bearer';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // const token = localStorage.getItem('token');
    const needsCompanyBearer = request.context.get(NEEDS_COMPANY_BEARER);
    const needsUserBearer = request.context.get(NEEDS_USER_BEARER);

    let requestHeaders = request.headers.set('Content-Type', 'application/json');

    /**
     * Be aware that setting a null header cannot happen.
     * The default needsCompanyBearer and needsUserBearer are true, false.
     * When a request does not set these properties, they will have their default
     * values and theres a chance that company token will be null
     */
    if (needsCompanyBearer) {
      const bearer_token = CompanyUtils.getToken();
      const bearer2 = bearer_token ? bearer_token : '123456';
      requestHeaders = requestHeaders.append('bearer', bearer2);
    }
    else if (needsUserBearer) {
      const bearer_token = localStorage.getItem('appuserToken');
      const bearer2 = bearer_token ? bearer_token : '123456';
      requestHeaders = requestHeaders.append('bearer', bearer2);
    }
    
    const clonedRequest = request.clone({headers: requestHeaders});
    return next.handle(clonedRequest);
  }
}
