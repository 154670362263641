import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RootStylesService {

  constructor() { }

  setRootStyles(colorsToApply) {
    const rootStyles = document.documentElement.style;

    if (!colorsToApply || !colorsToApply.primary) {
      colorsToApply = {
        primary: '#30706F',
        secondary: '#f2f2f3',
        tertiary: '#ffffff',
        font: '#61565C',
        font2: '#f3f3f4',
        button: '#E96933'
      };
    }


    rootStyles.setProperty('--primary-color', colorsToApply.primary);
    rootStyles.setProperty('--secondary-color', colorsToApply.secondary);
    rootStyles.setProperty('--tertiary-color', colorsToApply.tertiary);
    rootStyles.setProperty('--font-color', colorsToApply.font);
    rootStyles.setProperty('--font-color2', colorsToApply.font2);
    rootStyles.setProperty('--button-color', colorsToApply.button);
  }
}
