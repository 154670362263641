export class CompanyAgendamentoSettings {

    company_agendamento_settings_id: number;
    company_id: number;
    tempo_abertura: number = 360;
    tempo_tolerancia: number;
    enabled: boolean;
    sends_whats_message: boolean;


    tempo_agendar_antecedencia: number;
    campo_flexivel_label: string;
    is_campo_flexivel_mandatory: boolean;
    is_login_mandatory: boolean;
    tempo_cancelar_antecedencia: number;

    pix_enable: boolean;
    pix_info: string;
    price_perc: number;

    lista_espera_enable: boolean;

    intervalo_agendamento: string;
}