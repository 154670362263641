import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDatepickerI18n, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Agendamento } from '../svc/agendamento/agendamento';
import { AgendamentoPublService } from '../svc/agendamento/agendamento-publ.service';
import { Company } from '../svc/company/company';
import { CompanyService } from '../svc/company/company.service';
import { CustomDatepickerI18n, I18n } from '../svc/datepickerI18n/datepicker-i18n';
import { Globals } from '../svc/globals';
import { CompanyAgendamentoSettings } from '../svc/agendamento-settings/CompanyAgendamentoSettings';
import { PrestadorWorkhour } from '../svc/prestador-workhour/PrestadorWorkhour';
import { PrestadorPublService } from '../svc/prestador/prestador-publ.service';
import { ServicoPrestador } from '../svc/servico-prestador/ServicoPrestador';
import { Servico } from '../svc/servico/servico';
import { ServicoPublService } from '../svc/servico/servico-publ.service';
import * as smoothscroll from "smoothscroll-polyfill";
import { take } from 'rxjs/operators';
import { ServiceCategory } from '../svc/servico/service-category';
import { AgendamentoEspera } from 'src/app/svc/agendamento-espera/agendamento-espera';
import { AgendamentoEsperaService } from 'src/app/svc/agendamento-espera/agendamento-espera.service';
import { LinkThemeService } from '../svc/link-theme/link-theme.service';
import { AgendamentoSettingsServicePubl } from '../svc/company/agendamento-settings/agendamento-settings-publ';
import { Subscription } from 'rxjs';
import { AgendamentoService } from '../agendamento-conf-new/agendamento.service';
import { CompanyUser } from '../svc/company/company-user/CompanyUser';
import { LinksService } from '../svc/company/links/links.service';
import { title } from 'process';
import { set } from 'date-fns';


@Component({
  selector: 'app-company-link-tree',
  templateUrl: './company-link-tree.component.html',
  styleUrls: ['./company-link-tree.component.scss']
})
export class CompanyLinkTreeComponent implements OnInit {

  company_id: string;
  company: Company;
  companyId: number
  company_handle: string = '';
  agendamentoAFazer: Agendamento;
  showDatePicker: boolean = false;
  filterDateStart: NgbDateStruct;
  filterDateStartView: string;
  filterDateStartDbFormat: string;
  agendamentoEspera: AgendamentoEspera;
  preSelectedHour: string;
  preSelectedPrestador: number;
  currentTheme: string = 'theme-default';
  currentButtonTheme: string = 'button-theme-default';
  wrongHandle: boolean = false;
  link_social: string;
  isClientLoggedin = false;
  links: any[] = [];
  linkWhatsapp: string;
  telefoneLimpo: string;

  

  constructor(
    private companySvc: CompanyService,
    private actRoute: ActivatedRoute,
    private servicoSvc: ServicoPublService,
    private prestadorSvc: PrestadorPublService,
    private agendamentoSvc: AgendamentoPublService,
    private esperaSvc: AgendamentoEsperaService,
    public globals: Globals,
    private refresher: ChangeDetectorRef,
    private modalService: NgbModal,
    private ngxLoaderSvc: NgxUiLoaderService,
    private router: Router,
    private themeSvc: LinkThemeService,
    private settingsSvcPubl: AgendamentoSettingsServicePubl,
    private agendamentoService: AgendamentoService,
    private linksSvc: LinksService
  ) {
    this.company = new Company();
    this.company.latitude = 0;
    this.company.longitude = 0;
    this.agendamentoAFazer = new Agendamento();
    this.agendamentoEspera = new AgendamentoEspera();

    const d = new Date();
    this.filterDateStart = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());
    this.filterDateStartView = this.dateToBrString(this.filterDateStart);
    this.filterDateStartDbFormat = this.dateToDBString(this.filterDateStart);

    smoothscroll.polyfill();

    // test data
    // this.openSchedule = OpenSchedule.returnMockOpenSchedule();
  }

  ngOnInit() {

    this.companyId= localStorage.getItem('CompanyId') ? Number(localStorage.getItem('CompanyId')) : 0;

    this.company_handle = this.actRoute.snapshot.paramMap.get("cid");
    this.company_id = this.actRoute.snapshot.paramMap.get("cid");
    
    if (
      this.actRoute.snapshot.paramMap.get("date") &&
      this.actRoute.snapshot.paramMap.get("hour") &&
      this.actRoute.snapshot.paramMap.get("pid")
    ) {
      const dateStr = this.actRoute.snapshot.paramMap.get("date");
      this.filterDateStart = {
        year: Number.parseInt(dateStr.substring(0, 4)),
        month: Number.parseInt(dateStr.substring(5, 7)),
        day: Number.parseInt(dateStr.substring(8))
      };
      this.filterDateStartView = this.dateToBrString(this.filterDateStart);
      this.filterDateStartDbFormat = this.dateToDBString(this.filterDateStart);
      this.preSelectedHour = this.actRoute.snapshot.paramMap.get("hour");
      this.preSelectedPrestador = Number.parseInt(
        this.actRoute.snapshot.paramMap.get("pid")
      );
    }

    const idAux = Number(this.company_handle);
    const segments = this.router.url.split('/');
    const url = this.router.url;

    if (idAux) {
      this.getInfosIniciais();
      localStorage.setItem('CompanyId', this.company_id);
    } else {
      this.getCompanyGuestRoomData();
    }


    setTimeout(() => {
      this.getLinks();
    },3000);
  }

  getLinks(){
    this.linksSvc.getAllLinks(this.companyId).subscribe(
      (result) => {
        console.log(result);
        this.links = result;
        const currentRoute = window.location.pathname.replace(/\/links$/, '');
        this.linkWhatsapp = `https://wa.me/+55` + this.telefoneLimpo
        this.links.unshift(
        {
          title: 'Faça seu agendamento',
          link: 'https://whatsagenda.com.br'+ currentRoute,
        },
        {
          title: 'Catálogo de produtos',
          link: 'https://whatsagenda.com.br' + currentRoute + '/catalogo'
        },
        {
          title: 'Fale pelo WhatsApp',
          link: this.linkWhatsapp
        }
      )
      },
      (err) => {
        console.log(err);
      }
    );
  }

  goToLink(link): void {
    if (!link.link.startsWith('http://') && !link.link.startsWith('https://')) {
        link = 'https://' + link.link;
    } else {
      link = link.link
    }
    console.log(link)
    window.open(link, '_blank');
}



  formatWhatsAppNumber(number: string): string {
    if (!number) {  
      return '';
    }
    const digitsOnly = number.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    return digitsOnly.startsWith('55') ? digitsOnly : '55' + digitsOnly;
  }

  getCompanyGuestRoomData(): void {
    this.ngxLoaderSvc.start();
    this.companySvc.getGuestRoomData(this.company_handle).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        //
        if (result == null) {
          // not found
          this.wrongHandle = true;
          this.router.navigate(['not-found']);
        } else {
          console.log(result);
          this.wrongHandle = false;
          this.company_id = result.company_id.toString();
          this.link_social = result.fb_mess_link;
          localStorage.setItem('CompanyId', this.company_id);
          this.currentButtonTheme = 'button-theme_pink';

          this.getInfosIniciais();
          return;
        }

      },
      (err) => {
        this.ngxLoaderSvc.stop();
        alert("Erro ao trazer informações da empresa");
        return;
      }
    );
  }

  dateToBrString(d: NgbDateStruct): string{
    return (d.day < 10? ('0' + d.day) : d.day) + '/' + (d.month < 10? ('0' + d.month) : d.month) + '/' + d.year;
  }

  dateToDBString(d: NgbDateStruct): string{
    return d.year + '-' + (d.month < 10? ('0' + d.month) : d.month) + '-' + (d.day < 10? ('0' + d.day) : d.day);
  }

  getInfosIniciais(){
    // this function is also called after a sucessful reservation
    this.getCompanyData();
  }

  getCompanyData() {
    // will return whatsapp_phone and company data
    this.ngxLoaderSvc.start();
    this.companySvc.getAgendamentoCompData(this.company_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.company = result;
        this.telefoneLimpo = this.company.whatsapp_phone.replace(/\D/g, '');
        this.currentTheme = !this.company.linkThemeName ? 'theme-default' : this.company.linkThemeName;

        // console.log(this.company);
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
        alert("Não foi possível carregar os dados da empresa.");
      }
    );
  }

  navigateToHome(){
    document.getElementById("dismissAcessoModal").click();
    this.router.navigateByUrl('/');
  }

  navigateToAcessoCliente() {
    document.getElementById("dismissAcessoModal").click();
  
    // Check if localStorage has the required items before setting isClientLoggedin
    const appuserToken = localStorage.getItem('appuserToken');
    const appuserId = localStorage.getItem('appuser_id');
    this.isClientLoggedin = appuserToken && appuserId ? true : false;
  
    if(this.isClientLoggedin){
      this.goToClientAgendamentoList();
    } else {
      this.goToClientLogin();
    }
  }

  
  goToClientLogin(){
    this.router.navigateByUrl('client/login');
  }

  goToClientAgendamentoList(){
    this.router.navigateByUrl('client/agendamento-list');
  }

}
