import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CompanyService } from '../svc/company/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Agendamento } from '../svc/agendamento/agendamento';
import { Company } from '../svc/company/company';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-agendamento-conf-new',
  templateUrl: './agendamento-conf-new.component.html',
  styleUrls: ['./agendamento-conf-new.component.scss']
})
export class AgendamentoConfNewComponent implements OnInit {

  company_handle: string = '';
  company_id: string;
  agendamentoCart: Array<Agendamento> = new Array<Agendamento>();
  company: Company;
  pixKeyType: string = '';
  pixKey: string = '';
  nomeContaPix: string = '';
  pixValue: number;
  currentTheme: string = 'theme-default';
  destinationPhone = '';
  unavailableDivHeight: number = 768;
  nomeClienteForm: string = '';
  phoneClienteForm: string = '';
  obsClienteForm: string = '';
  flexClienteForm: string = '';

  constructor(private companySvc: CompanyService,
    private actRoute: ActivatedRoute,
    private ngxLoaderSvc: NgxUiLoaderService,
    private router: Router,
    private refresher: ChangeDetectorRef,) {
      this.company = new Company();
    }

    ngOnInit(): void {
      this.company_handle = this.actRoute.snapshot.paramMap.get("cid");
      this.company_id = this.actRoute.snapshot.paramMap.get("cid");
    
      this.loadAgendamentosFromLocalStorage();
      console.log(this.agendamentoCart);
      this.getCompanyData();
    }
    

  loadAgendamentosFromLocalStorage(): void {
    const storedData = localStorage.getItem('agendamentoCart');
    if (storedData) {
      this.agendamentoCart = JSON.parse(storedData);
    }
  }

  getCompanyData() {
    // will return whatsapp_phone and company data
    this.ngxLoaderSvc.start();
    this.companySvc.getAgendamentoCompData(this.company_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.company = result;

        if(this.company.tempo_agendar_antecedencia != undefined){
          this.company.tempo_agendar_antecedencia *= 3600000;
        }

        if(this.company.is_login_mandatory && localStorage.getItem('appuser_id') == undefined){
          this.router.navigateByUrl('client/login');
          return;
        }

        if(this.company.pix_enable){
          const pixInfoArray = this.company.pix_info.split(';');
          this.pixKeyType = pixInfoArray[0];
          this.pixKey = pixInfoArray[1];
          this.nomeContaPix = pixInfoArray[2];
        }

        try {
          this.company.latitude = Number.parseFloat(this.company.latitude.toString());
          this.company.longitude = Number.parseFloat(this.company.longitude.toString());
        } catch (e) {
          this.company.latitude = 0;
          this.company.longitude = 0;
        }

        this.destinationPhone = '+55' + this.company.whatsapp_phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');

        this.currentTheme = !this.company.linkThemeName ? 'theme-default' : this.company.linkThemeName;
        this.calcPixValue();
        // console.log(this.company);
        this.updateUnavailableDivHeight();
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
      }
    );
  }
  

  calcPixValue(){
    this.pixValue = 0;
    this.agendamentoCart.forEach(a => {
      if (a.preco_mode !== 2) {
        this.pixValue += parseFloat(a.preco.toString());
      }
    });
    this.pixValue *= this.company.price_perc;
  }


  agendarMais(){
    this.router.navigate([this.company.handle]);
  }

  saveAgendamentosToLocalStorage(agendamentos: Agendamento[]) {
    localStorage.setItem('agendamentoCart', JSON.stringify(agendamentos));
  }

  removeAgendamento(index: number): void {
    this.agendamentoCart.splice(index, 1);
    this.saveAgendamentosToLocalStorage(this.agendamentoCart);
    console.log(this.agendamentoCart);
}


  updateUnavailableDivHeight(){

    if (this.company.blocked_by_payment) {
      //
      const div = document.getElementById('agendamentoRootDiv');
      const tmpWindowHeight = div.offsetHeight;
      this.unavailableDivHeight = (tmpWindowHeight >= this.unavailableDivHeight) ? (tmpWindowHeight+100) : this.unavailableDivHeight;
      this.refresher.detectChanges();
    }
  }

  formatarTelefone(telefone: string): string {
    // Remove todos os caracteres não numéricos do número de telefone
    const numeros = telefone.replace(/\D/g, '');
  
    // Verifica se o número tem 11 dígitos (incluindo DDD)
    if (numeros.length === 11) {
      return `(${numeros.slice(0, 2)}) ${numeros.slice(2, 7)}-${numeros.slice(7)}`;
    } else {
      // Se não tiver 11 dígitos, retorna o número original
      return telefone;
    }
  }

  finalizar() {
    if (!this.nomeClienteForm || !this.phoneClienteForm) {
      const camposFaltando = [];
      if (!this.nomeClienteForm) camposFaltando.push('nome');
      if (!this.phoneClienteForm) camposFaltando.push('telefone');
      alert(`Por favor, preencha os campos obrigatórios: ${camposFaltando.join(', ')}.`);
    } else {

      this.agendamentoCart.forEach((a, i) => {
        if (a.names && a.names.length > 0) {
          a.nome_cliente = this.nomeClienteForm;
          a.telefone_cliente = this.formatarTelefone(this.phoneClienteForm);
          a.obs = this.obsClienteForm;
    
          // Verifique se this.flexClienteForm não está vazio antes de atribuí-lo a a.campo_flexivel
          if (this.flexClienteForm.trim() !== '') {
            a.campo_flexivel = this.flexClienteForm;
          }
    
          a.names[i] = this.nomeClienteForm;
        }
      });
    
      // Imprimir os agendamentos atualizados (pode remover esta linha se não precisar)
      console.log(this.agendamentoCart);
    
      localStorage.setItem('agendamentoCart', JSON.stringify(this.agendamentoCart));
      localStorage.setItem('agendamentoCartTime', Date.now().toString());
      localStorage.setItem('NomeFantasia', this.company.nome_fantasia);

      this.router.navigate([this.company.handle, 'conf']);
    }
  }
  
  copyPixKey(inputElement: HTMLInputElement) {
    inputElement.select();
    document.execCommand('copy');
  }

  
  

}
