import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { City } from 'src/app/svc/city/City';
import { CityService } from 'src/app/svc/city/city.service';
import { Company } from 'src/app/svc/company/company';
import { CompanyService } from 'src/app/svc/company/company.service';
import { Globals } from 'src/app/svc/globals';
import { State } from 'src/app/svc/state/State';
import { StateService } from 'src/app/svc/state/state.service';

@Component({
  selector: 'app-company-not-found',
  templateUrl: './company-not-found.component.html',
  styleUrls: ['./company-not-found.component.scss']
})
export class CompanyNotFoundComponent implements OnInit {

  searchCompaniesForm = this.formBuilder.group({
    state_id: [0, [
      Validators.required
    ]],
    city_id: [0, [
      Validators.required
    ]],
    cityName: ['Cidade', []]
  });

  states: State[] = [];
  cityLst: City[] = [];
  filteredCityLst: City[];
  cityStr: string;
  showCityLst = false;

  companyList: Company[] = [];
  queryHappened: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private ngxUiLoaderSvc: NgxUiLoaderService,
    private stateSvc: StateService,
    private citySvc: CityService,
    private companySvc: CompanyService,
    private globals: Globals,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getStates();
  }

  searchCompanies() {
    if (this.searchCompaniesForm.value.city_id === 0) {
      return;
    }
    // this.searchCompaniesForm.value.city_id
    this.companySvc.getCompaniesByCityId(this.searchCompaniesForm.value.city_id).pipe(take(1)).subscribe({
      next: (result) => {
        this.companyList = result;
        this.queryHappened = true;
      },
      error: () => {
        this.companyList = [];
        this.queryHappened = true;
      }
    });
  }

  goToCompany(company: Company) {
    this.router.navigate(["/", company.handle]);
  }

  getStates(){
      const country_id = 1;
      this.ngxUiLoaderSvc.start();
      this.stateSvc.getAllFromCountry(country_id).pipe(take(1)).subscribe({
        next: (result) => {
          this.ngxUiLoaderSvc.stop();
          this.states = result;
        },
        error: () => {
          this.ngxUiLoaderSvc.stop();
          this.globals.openDialog('Erro', `Não foi possível carregar os estados, tente novamente mais tarde.`);
        }
      });
    }

  getCities(){
    const state_id = this.searchCompaniesForm.value.state_id;
    if (state_id === 0) {
      return;
    }
    this.ngxUiLoaderSvc.start();
    this.citySvc.getRows(state_id).pipe(take(1)).subscribe({
      next: (result) => {
        this.cityLst = result;
        this.filteredCityLst = this.cityLst;
        this.ngxUiLoaderSvc.stop();

      },
      error: (err) => {
        this.ngxUiLoaderSvc.stop();
        this.globals.openDialog('Erro', `Não foi possível carregar as cidades..`);
        console.log(err);
      }
    });
  }

  searchCity() {

    this.filteredCityLst = [];

    if(!this.cityStr || this.cityStr.length === 0){
      this.filteredCityLst = this.cityLst;
      return;
    }

    // starting with; global - g; case insensitive - i; flags
    this.cityLst.forEach(c => {
      const regex = new RegExp(`^${this.cityStr}`, 'gi');
      if(regex.test(c.name)){
        this.filteredCityLst.push(c);
      }
    });

  }

  onCitySelected(cityItem: City) {

    this.searchCompaniesForm.patchValue({
      cityName: cityItem.name,
      city_id: cityItem.city_id,
    });
    document.getElementById('dismissCitySelection').click();
    this.cityStr = '';
    this.filteredCityLst = this.cityLst;

    this.searchCompanies();
  }

}
