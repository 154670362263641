import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../../../svc/config'; // Supondo que você tenha essa configuração
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentOpenpixService {
  private apiUrl = `${Config.vBusUrl}company_payment_openpix/`;
  // private subaccountUrlBase = `https://gateway.pandoapps.com.br/api/companies/1/users/subaccount`; // URL base para buscar saldo da subconta
  private subaccountUrlBase = `${environment.open_pix_url}api/companies/1/users/subaccount`; // URL base para buscar saldo da subconta
  // private withdrawalUrlBase = `https://gateway.pandoapps.com.br/api/companies/1/withdrawal`; // URL base para solicitar saque da subconta
  private withdrawalUrlBase = `${environment.open_pix_url}api/companies/1/withdrawal`; // URL base para solicitar saque da subconta

  constructor(private http: HttpClient) {}

  getCompanyPaymentOpenpix(company_id): Observable<any> {
    // Configurando os parâmetros HTTP
    const httpParams = new HttpParams().set('company_id', company_id);

    return this.http.get(this.apiUrl, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
      params: httpParams // Passando os parâmetros HTTP
    }).pipe(
      tap((data: any) => {
        console.log('Dados completos retornados pela API:', data); // Adiciona o console.log para exibir tudo
      })
    );
  }



  updatePaymentSettings(companyId: number, settings: any): Observable<any> {
    console.log('Enviando para o backend:', settings); // Adicione isso para verificar o que está sendo enviado
    return this.http.patch(`${this.apiUrl}`, { company_id: companyId, ...settings }, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    });
}


  getPaymentStatus(companyId: number, uid_payment: number): Observable<any> {
    // Configurando os parâmetros HTTP
    const httpParams = new HttpParams()
      .set('company_id', companyId.toString())
      .set('uid_payment', uid_payment.toString());

    // const url = `https://gateway.pandoapps.com.br/api/companies/1/payment/scheduling/${uid_payment}`;
    const url = `${environment.open_pix_url}api/companies/1/payment/scheduling/${uid_payment}`;

    return this.http.get(url, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
      params: httpParams // Passando os parâmetros HTTP
    }).pipe(
      map((data: any) => {
        console.log('Status do pagamento:', data); // Adicione para depuração
        return data;
      }),
      catchError((error) => {
        console.error('Erro ao buscar o status do pagamento:', error);
        throw error;
      })
    );
  }

  getPaymentStatusByUid(uid_payment: number): Observable<any> {
    // const url = `https://gateway.pandoapps.com.br/api/companies/1/payment/scheduling/${uid_payment}`;
    const url = `${environment.open_pix_url}api/companies/1/payment/scheduling/${uid_payment}`;

    return this.http.get(url, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
    }).pipe(
      map((data: any) => {
        console.log('Status do pagamento pelo UID:', data); // Para depuração
        return data;
      }),
      catchError((error) => {
        console.error('Erro ao buscar o status do pagamento pelo UID:', error);
        throw error;
      })
    );
  }

  getPaymentsByPixKey(pix_key: string): Observable<any> {
    // const url = `https://gateway.pandoapps.com.br/api/companies/1/payments?pix_key=${pix_key}`;
    const url = `${environment.open_pix_url}api/companies/1/payments?pix_key=${pix_key}`;
    const params = new HttpParams().set('pix_key', pix_key);

    return this.http.get(url, {
      params: params,
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
    }).pipe(
      tap((data: any) => {
        console.log('Resposta completa da rota:', data);
      }),
      catchError((error) => {
        console.error('Erro ao buscar a lista de pagantes:', error);
        throw error;
      })
    );
  }

  updatePixKey(oldPixKey: string, newPixKey: string): Observable<any> {
    // const url = 'https://gateway.pandoapps.com.br/api/companies/1/users/subaccount_update'; // URL da API para atualizar a chave PIX
    const url = `${environment.open_pix_url}api/companies/1/users/subaccount_update`; // URL da API para atualizar a chave PIX
    const body = {
      old_pix_key: oldPixKey,
      new_pix_key: newPixKey
    };

    return this.http.post(url, body, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => {
        console.log('Resposta da atualização da chave PIX:', data); // Para depuração
        return data;
      }),
      catchError((error) => {
        console.error('Erro ao atualizar a chave PIX:', error);
        throw error;
      })
    );
  }


  // Método para buscar o saldo da subconta, enviando pix_key como parâmetro de consulta
  getSubaccountBalance(pix_key: string): Observable<any> {
    const url = `${this.subaccountUrlBase}`; // URL base
    const params = new HttpParams().set('pix_key', pix_key); // Adiciona pix_key como parâmetro de consulta

    return this.http.get(url, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false),
      params: params // Passa os parâmetros na requisição GET
    }).pipe(
      map((data: any) => {
        console.log('Saldo da Subconta:', data); // Para depuração
        return data;
      }),
      catchError((error) => {
        console.error('Erro ao buscar o saldo da subconta:', error);
        throw error;
      })
    );
  }

  // Método para solicitar o saque do saldo da subconta
  requestWithdrawal(pix_key: string, amount: number): Observable<any> {
    const url = `${this.withdrawalUrlBase}`; // URL base
    const amountInCents = amount * 100; // Converte o valor de reais para centavos
    const body = { pix_key: pix_key, amount: amountInCents }; // Corpo da requisição para o saque, incluindo pix_key

    return this.http.post(url, body, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => {
        console.log('Saque Solicitado:', data); // Para depuração
        return data;
      }),
      catchError((error) => {
        console.error('Erro ao solicitar o saque:', error);
        throw error;
      })
    );
  }

  // Método para buscar o histórico de saques da subconta usando GET com parâmetros de consulta
    getWithdrawalHistory(pix_payment_key: string): Observable<any> {
      // const url = `https://gateway.pandoapps.com.br/api/companies/1/withdrawals`; // URL para buscar o histórico de saques
      const url = `${environment.open_pix_url}api/companies/1/withdrawals`; // URL para buscar o histórico de saques
      const params = new HttpParams().set('pix_key', pix_payment_key); // Define pix_key como um parâmetro de consulta

      return this.http.get(url, {  // Usa GET para a requisição
        params: params, // Adiciona os parâmetros de consulta
        context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
      }).pipe(
        map((data: any) => {
          console.log('Histórico de Saques:', data); // Para depuração
          // Manipule os dados conforme necessário antes de retorná-los
          return data.map((item: any) => ({
            id: item.id, // ID do saque
            updated_at: item.updated_at, // Data e hora do saque
            value: item.value / 100, // Valor do saque (convertido de centavos para reais)
            status: item.status // Status do saque
          }));
        }),
        catchError((error) => {
          console.error('Erro ao buscar o histórico de saques:', error);
          throw error;
        })
      );
    }
}




