import { Injectable } from '@angular/core';
import { BaseService } from '../company/base.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../config';


@Injectable({
  providedIn: 'root'
})
export class CaptchaService extends BaseService {
  baseURL = `${Config.vBusUrl}get_recaptcha/`;

  private recaptchaSource: string;
  private recaptchaSolved: boolean = false;
  private recaptchaLoaded: boolean = false;

  constructor(private h: HttpClient) {
    super(h);
  }

  getRecaptcha() {
    return this.http.get(this.baseURL)
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        const ret = data.result.map( (el) => {
          this.recaptchaSource = el.source;
          return el.image;
        });
        this.recaptchaLoaded = true;
        this.recaptchaSolved = false;
        return ret;
      }),
      catchError(this.handleErrors)
    );
  }

  public getRecaptchaSolved(): boolean{
    return this.recaptchaSolved;
  }

  public getRecaptchaSource(): string{
    return this.recaptchaSource;
  }

  public getRecaptchaLoaded(): boolean{
    return this.recaptchaLoaded;
  }

  public solveRecaptcha(userChallenge: string): boolean {
    if (userChallenge === this.recaptchaSource){
      this.recaptchaSolved = true;
      return true;
    } else {
      return false;
    }
  }

  protected setRecaptchaExpiration(){
    // captcha expires in 10 minutes
    setTimeout( () => {
      this.recaptchaSolved = false;
    }, 600000);
  }
}
