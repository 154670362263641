import { Workhour } from "../../workhour/workhour";

export class CompanyUser {
    name: string;
    companyuser_id: number;
    email: string;
    company_id: number;
    category_id: number;
    category_id_2: number;
    password: string;
    banner: string;
    card: string;
    img_banner_link: string;
    img_cartao_link: string;
    user_type: number;
    description: string;
    horario_seg: string;
    horario_ter: string;
    horario_qua: string;
    horario_qui: string;
    horario_sex: string;
    horario_sab: string;
    horario_dom: string;
    fb_mess_link: string;
    latitude: number;
    longitude: number;
    address_complete: string;
    state_name: string;
    city_name: string;
    nome_fantasia: string;
    phone: string;
    receive_whatsapp_msg: boolean;
    whatsapp_phone: string;
    city_id: number;
    state_id:number;
    workhours: Array<Workhour>;
    frete: string;
    handle: string;
    frete_mode_other_locations: number;
    cnpj: string;
}