import { AppuserAddress } from '../appuser/appuserAddress';
import { Product } from '../product/product';

export class Pedido {

    pedido_id: number;
    productLst: Array<Product>;
    data_pedido: Date;

    total_pedido: number = 0;

    obs_pedido: string;
    opcao_retirada: string;
    opcao_retirada_id: number;
    mesa_restaurante: string;
    company_payment_id: number;
    company_payment_name: string;
    preciso_troco_para: number;
    quem_retira: string;
    telefone: string;

    subtotal_pedido: number;

    userAddr: AppuserAddress;

    consumptionBillId: number = undefined;

}
