import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterStateService {
  private isCashRegisterOpenSubject: BehaviorSubject<boolean>;
  private checkInterval = 1000;  

  constructor() {
    const savedState = localStorage.getItem('isCashRegisterOpen');
    const initialState = savedState ? JSON.parse(savedState) : false;
    this.isCashRegisterOpenSubject = new BehaviorSubject<boolean>(initialState);

    
    interval(this.checkInterval).subscribe(() => {
      this.syncWithLocalStorage();
    });
  }

  setCashRegisterState(state: boolean) {
    this.isCashRegisterOpenSubject.next(state);
    localStorage.setItem('isCashRegisterOpen', JSON.stringify(state));
  }

  getCashRegisterState() {
    return this.isCashRegisterOpenSubject.asObservable();
  }

  public syncWithLocalStorage() {
    const savedState = localStorage.getItem('isCashRegisterOpen');
    const currentState = savedState ? JSON.parse(savedState) : false;
    if (currentState !== this.isCashRegisterOpenSubject.value) {
      this.isCashRegisterOpenSubject.next(currentState);
    }
  }
}
