// import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';

export const APP_RUN_MODE_DEF = {
  WEB: "WEB",
  WEB_ANDROID: "WEB_ANDROID",
  WEB_IOS: "WEB_IOS",
};

export let APP_RUN_MODE = APP_RUN_MODE_DEF.WEB;


function GetDeviceInfo(): Promise<DeviceInfo | undefined> {
  return new Promise<DeviceInfo>((resolve) => {
    Device.getInfo()
      .then((deviceInfoResult) => {
        resolve(deviceInfoResult);
      })
      .catch(() => {
        console.log("could not determine device basic info, defaulting to WEB");
        APP_RUN_MODE = APP_RUN_MODE_DEF.WEB;
        resolve(undefined);
      })
  });
}

export function AppNativeLoader() {
  return (): Promise<void> => {
    return new Promise<void>((resolve) => {

      GetDeviceInfo()
        .then((res) => {
          if (res.operatingSystem === 'android') {
            APP_RUN_MODE = APP_RUN_MODE_DEF.WEB_ANDROID;
          } else if (res.operatingSystem === 'ios') {
            APP_RUN_MODE = APP_RUN_MODE_DEF.WEB_IOS;
          } else {
            APP_RUN_MODE = APP_RUN_MODE_DEF.WEB;
          }
        })
        .catch();

      // console.log("====== isAppNative", Capacitor.isNativePlatform());
      // console.log("====== plataforma", Capacitor.getPlatform());
      resolve();
    });
  };
}
