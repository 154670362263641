import { Product } from './product';

export class ProductCategory {

    product_category_id: number;
    name: string;
    productLst: Product[];
    expanded: boolean = false;
    prodcateg_img: string = '';

}