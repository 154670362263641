import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AssinActionsComponent } from '../company/assin-actions/assin-actions.component';
import { PagarPlanoComponent } from '../company/pagar-plano/pagar-plano.component';
import { CompanyNewPasswordComponent } from '../company/company-new-password/company-new-password.component';
import { CompanyEditLoginComponent } from '../company/company-edit-login/company-edit-login.component';
import { AuthGuard } from '../svc/company/auth/auth.guard';

import { AmbienteAgendaComponent } from './ambiente-agenda.component';
import { AgendaDashboardComponent } from '../company/agenda-dashboard/agenda-dashboard.component';
import { AgendaClientDetailComponent } from '../company/agenda-client-detail/agenda-client-detail.component';
import { AgendaHomeComponent } from '../company/agenda-home/agenda-home.component';
import { WaitContactComponent } from '../company/wait-contact/wait-contact.component';
import { ClubeDeAssinaturasComponent } from '../company/clube-de-assinaturas/clube-de-assinaturas.component';


const routes: Routes = [
  { path: 'ambiente/agenda', component: AmbienteAgendaComponent, children: [
    { path: '', redirectTo: 'agenda-home', pathMatch: 'full' },
    { path: 'workhours',
      loadChildren: () => import('../company/workhours/workhours.module').then(m => m.WorkhoursModule),
      canActivate: [AuthGuard]
    },
    { path: 'user-profile',
      loadChildren: () => import('../company/company-user-profile/company-user-profile.module').then(m => m.CompanyUserProfileModule),
      canActivate: [AuthGuard]
    },
    { path: 'usage-plans',
      loadChildren: () => import('../company/usage-plans/usage-plans.module').then(m => m.UsagePlansModule),
      canActivate: [AuthGuard]
    },
    { path: 'assin-actions/:act/:pid', component: AssinActionsComponent, canActivate: [AuthGuard] },
    { path: 'pagar-plano/:pid/:affiliateId', component: PagarPlanoComponent, canActivate: [AuthGuard] },
    { path: 'mobile-download',
      loadChildren: () => import('../company/mobile-download/mobile-download.module').then(m => m.MobileDownloadModule),
      canActivate: [AuthGuard]
    },
    { path: 'new-password', component: CompanyNewPasswordComponent, canActivate: [AuthGuard] },
    { path: 'edit-login', component: CompanyEditLoginComponent, canActivate: [AuthGuard] },

    { path: 'meu-link',
      loadChildren: () => import('../company/meu-link/meu-link.module').then(m => m.MeuLinkModule),
      canActivate: [AuthGuard]
    },
    { path: 'integracao-pagamento',
      loadChildren: () => import('../company/integracao-pagamento/integracao-pagamento.module').then(m => m.IntegracaoPagamentoModule),
      canActivate: [AuthGuard]
    },
    
    {
      path: 'agendamento-cadastro',
      loadChildren: () => import('../company/agendamento-cadastro/agendamento-cadastro.module').then(m => m.AgendamentoCadastroModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'agendamento-list',
      loadChildren: () => import('../company/agendamento-list/agendamento-list.module').then(m => m.AgendamentoListModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'dashboard',
      loadChildren: () => import('../company/agenda-dashboard/agenda-dashboard.module').then(m => m.AgendaDashboardModule),
      canActivate: [AuthGuard]
    },

    { path: 'client-detail', component: AgendaClientDetailComponent, canActivate: [AuthGuard] },

    {
      path: 'agenda-home', component: AgendaHomeComponent, canActivate: [AuthGuard]
    },
    {
      path: 'agenda-history',
      loadChildren: () => import('../company/agenda-history/agenda-history.module').then(m => m.AgendaHistoryModule),
      canActivate: [AuthGuard]
    },
    { path: 'financeiro',
      loadChildren: () => import('../company/company-financeiro/company-financeiro.module').then(m => m.CompanyFinanceiroModule),
      canActivate: [AuthGuard]
    },
    { path: 'wait', component: WaitContactComponent, canActivate: [AuthGuard] },
    { path: 'employees',
      loadChildren: () => import('../company/employee-register/employee-register.module').then(m => m.EmployeeRegisterModule),
      canActivate: [AuthGuard]
    },
    { path: 'recesso',
      loadChildren: () => import('../company/professional-vacation/professional-vacation.module').then(m => m.ProfessionalVacationModule),
      canActivate: [AuthGuard]
    },
    { path: 'clients',
      loadChildren: () => import('../company/appuser/appuser.module').then(m => m.AppuserModule),
      canActivate: [AuthGuard]
    },
    { path: 'lista-espera',
      loadChildren: () => import('../company/lista-espera/lista-espera.module').then(m => m.ListaEsperaModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'link-theme',
      loadChildren: () => import('../company/link-theme/link-theme.module').then(m => m.LinkThemeModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'clube-de-assinaturas',
      loadChildren: () => import('../company/clube-de-assinaturas/clube-de-assinaturas.module').then(m => m.ClubeDeAssinaturasModule),
      canActivate: [AuthGuard]
    },
    { 
      path: 'pacotes',
      loadChildren: () => import('../company/pacotes/pacotes.module').then(m => m.PacotesModule),
      canActivate: [AuthGuard]
    },
    { path: 'pdv',
      loadChildren: () => import('../company/company-pdv/company-pdv.module').then(m => m.CompanyPdvModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'products',
      loadChildren: () => import('../company/company-products/company-products.module').then(m => m.CompanyProductsModule),
      canActivate: [AuthGuard]
    },


  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AmbienteAgendaRoutingModule { }
