import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { Workhour } from './workhour';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})

export class WorkhourService2 extends BaseService{
  baseURL = `${Config.vWebUrl}company_workhour2/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAllByCompanyHandle(company_handle) {
    return this.http.get<Workhour>(this.baseURL + `${company_handle}/-1`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

}
