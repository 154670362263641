import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from 'src/app/svc/config';
import { Bairro } from './bairro';
import { BaseService } from 'src/app/svc/company/base.service';

@Injectable({
  providedIn: 'root'
})
export class BairroService extends BaseService {

  baseURL = `${Config.vWebUrl}bairro/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getByCityId(cityId) {
    return this.http.get<Bairro>(this.baseURL + `${cityId}/-1`, {
      headers: this.getCommonHeaders_compToken2Conditional(false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }
}
