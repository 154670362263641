import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Globals } from '../../svc/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { Plan } from '../../svc/company/plan/plan';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormAssinatura, Customer, Address, PhoneAssinatura } from '../../svc/company/plan/FormAssinatura';
import { PlanService } from '../../svc/company/plan/plan.service';
import { AssinaturaService } from '../../svc/company/plan/assinatura.service';
import { CompanyUtils } from '../../svc/company/company-utils';
import { CaptchaService } from 'src/app/svc/captcha/captcha.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProfileService } from 'src/app/svc/company/company-user/profile.service';
import { CompanyUser } from 'src/app/svc/company/company-user/CompanyUser';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pagar-plano',
  templateUrl: './pagar-plano.component.html',
  styleUrls: ['./pagar-plano.component.scss']
})
export class PagarPlanoComponent implements OnInit {

  plan: Plan;
  formPag: UntypedFormGroup;
  objAssinatura: FormAssinatura
  cardMask = '0000 0000 0000 0000 9999';
  expMask = "00/00";
  cvvMask = "0009";
  emailMask = "";
  isCPF: boolean;
  cpfMask = "000.000.000-00";
  cnpjMask = "00.000.000/0000-00";
  campoCPF: string;
  campoCNPJ: string;
  cepMask = "00000-000";
  dddMask = "00";
  phoneMask = "9 0000-0000";
  isPaymentCartao: boolean = true;
  planoPermiteEscolherCreditoBoleto: boolean = false;
  recaptchaImgSrc: string;
  recaptchaSolution: string;
  companyData: CompanyUser;
  affiliateId: string;
  installmentString: string;

  constructor(public globals: Globals,
    private router: Router,
    private actRoute: ActivatedRoute,
    private companySvc: ProfileService,
    private changeDetectorRef: ChangeDetectorRef,
    private planSvc: PlanService,
    private assinSvc: AssinaturaService,
    private recaptchaSvc: CaptchaService,
    private ngxLoaderSvc: NgxUiLoaderService) {
      this.plan = new Plan();
      this.plan.showKeyword = new Array<string>();
      this.objAssinatura = new FormAssinatura();
      this.objAssinatura.customer = new Customer();
      this.objAssinatura.customer.address = new Address();
      this.objAssinatura.customer.phone = new PhoneAssinatura();
      this.companyData = new CompanyUser();
    }

  ngOnInit() {
    this.createForm();

    this.globals.backRoute = 'usage-plans';

    this.plan = new Plan();
    this.plan.showKeyword = new Array<string>();
    // get routed pid (plan id)
    if(this.actRoute.snapshot.paramMap.get('pid') != null) {
      this.plan.plan_id = parseInt(this.actRoute.snapshot.paramMap.get('pid'), 10);
      this.affiliateId = this.actRoute.snapshot.paramMap.get('affiliateId');
    }

    this.getPlanInfo();
    this.getCompanyData();
    this.isCPF = true;
    this.isPaymentCartao = true;

    this.objAssinatura = new FormAssinatura();
    this.objAssinatura.customer = new Customer();
    this.objAssinatura.customer.address = new Address();
    this.objAssinatura.customer.phone = new PhoneAssinatura();
    this.createForm();

    this.getRecaptcha();
  }

  createForm(){
    this.formPag = new UntypedFormGroup({
      'card_number': new UntypedFormControl(this.objAssinatura.card_number, [
        Validators.required,
      ]),
      'card_holder_name': new UntypedFormControl(this.objAssinatura.card_holder_name,[
        Validators.required
      ]),
      'card_expiration_date': new UntypedFormControl(this.objAssinatura.card_expiration_date, [
        Validators.required
      ]),
      'card_cvv': new UntypedFormControl(this.objAssinatura.card_cvv,[
        Validators.required
      ]),
      'email': new UntypedFormControl(this.objAssinatura.customer.email,[
        Validators.required
      ]),
      'name': new UntypedFormControl(this.objAssinatura.customer.name,[
        Validators.required
      ]),
      
      'document_number': new UntypedFormControl(this.objAssinatura.customer.document_number),
      
      'street': new UntypedFormControl(this.objAssinatura.customer.address.street,[
        Validators.required
      ]),
      'street_number': new UntypedFormControl(this.objAssinatura.customer.address.street_number,[
        Validators.required
      ]),
      'complementary': new UntypedFormControl(this.objAssinatura.customer.address.complementary),
      'neighborhood': new UntypedFormControl(this.objAssinatura.customer.address.neighborhood,[
        Validators.required
      ]),
      'zipcode': new UntypedFormControl(this.objAssinatura.customer.address.zipcode,[
        Validators.required
      ]),
      'ddd': new UntypedFormControl(this.objAssinatura.customer.phone.ddd,[
        Validators.required
      ]),
      'number': new UntypedFormControl(this.objAssinatura.customer.phone.number,[
        Validators.required
      ]),
      'recaptchaSolution': new UntypedFormControl(this.recaptchaSolution, [
        Validators.required
      ])
      //
    });

    this.formPag.controls['document_number'].valueChanges.subscribe((value) => {
      this.isCPF = (value.length <= 14) ? true : false;
    })
  }

  setPaymentCartao(pBol){
    this.isPaymentCartao = pBol;
    if (pBol){
      this.objAssinatura.payment_method = "credit_card";
    } else {
      this.objAssinatura.payment_method = "boleto";
    }
  }

  cleanField(pStr: string){
    return (pStr.replace(/([\./-\s])+/g, ''));
  }

  runContractActions(){
    
    this.objAssinatura.card_cvv = this.formPag.get('card_cvv').value;
    this.objAssinatura.card_expiration_date = this.formPag.get('card_expiration_date').value;
    this.objAssinatura.card_holder_name = this.formPag.get('card_holder_name').value;
    this.objAssinatura.card_number = this.formPag.get('card_number').value;
    this.objAssinatura.customer.address.street = this.formPag.get('street').value;
    this.objAssinatura.customer.address.street_number = this.formPag.get('street_number').value;
    // this.objAssinatura.customer.address.complementary = this.formPag.get('complementary').value;
    this.objAssinatura.customer.address.neighborhood = this.formPag.get('neighborhood').value;
    this.objAssinatura.customer.address.zipcode = this.formPag.get('zipcode').value;
    this.objAssinatura.customer.email = this.formPag.get('email').value;
    this.objAssinatura.customer.name = this.formPag.get('name').value;
    this.objAssinatura.customer.phone.ddd = this.formPag.get('ddd').value;
    this.objAssinatura.customer.phone.number = this.formPag.get('number').value;
    this.objAssinatura.customer.document_number = this.formPag.get('document_number').value;

    this.objAssinatura.company_id = CompanyUtils.getCompanyId();
    this.objAssinatura.plan_id = this.plan.plan_id; // plan_id is the ID on Looping Database
    this.objAssinatura.gateway_id = this.plan.gateway_id; // gateway_id is the ID on Pagar.Me

    // clean values
    if (this.isPaymentCartao){
      this.objAssinatura.card_number = this.cleanField(this.objAssinatura.card_number);
      this.objAssinatura.card_expiration_date = this.cleanField(this.objAssinatura.card_expiration_date);
    }
    this.objAssinatura.customer.document_number = this.cleanField(this.objAssinatura.customer.document_number);
    this.objAssinatura.customer.address.zipcode = this.cleanField(this.objAssinatura.customer.address.zipcode);
    this.objAssinatura.customer.phone.number = this.cleanField(this.objAssinatura.customer.phone.number);

    // check objAssinatura fields
    if ( 
    this.objAssinatura.customer.address.street == undefined || this.objAssinatura.customer.address.street == "" ||
    this.objAssinatura.customer.address.street_number == undefined || this.objAssinatura.customer.address.street_number == "" ||
    this.objAssinatura.customer.address.neighborhood == undefined || this.objAssinatura.customer.address.neighborhood == "" ||
    this.objAssinatura.customer.address.zipcode == undefined || this.objAssinatura.customer.address.zipcode == "" ||
    this.objAssinatura.customer.email == undefined || this.objAssinatura.customer.email == "" ||
    this.objAssinatura.customer.name == undefined || this.objAssinatura.customer.name == "" ||
    this.objAssinatura.customer.phone.ddd == undefined || this.objAssinatura.customer.phone.ddd == "" ||
    this.objAssinatura.customer.phone.number == undefined || this.objAssinatura.customer.phone.number == "" ||
    this.objAssinatura.customer.document_number == undefined || this.objAssinatura.customer.document_number == "" ) {
      this.globals.openDialog("Erro", "Por favor preencha todos os campos");
      return;
    }

    if (this.isPaymentCartao){
      if (this.objAssinatura.card_cvv == undefined || this.objAssinatura.card_cvv == "" ||
      this.objAssinatura.card_expiration_date == undefined || this.objAssinatura.card_expiration_date == "" ||
      this.objAssinatura.card_holder_name == undefined || this.objAssinatura.card_holder_name == "" ||
      this.objAssinatura.card_number == undefined || this.objAssinatura.card_number == "" ){
        this.globals.openDialog("Erro", "Por favor preencha todos os campos");
        return;
      }
    }

    this.objAssinatura.affiliateId = (this.affiliateId === "0") ? 
      undefined 
      : this.affiliateId;

    this.ngxLoaderSvc.start();
    this.assinSvc.criarAssinatura(this.objAssinatura).subscribe({
      next: () => {
        //console.log(result);
        this.ngxLoaderSvc.stop();
        this.globals.openDialog("Sucesso", "Assinatura realizada com sucesso. O processamento do pagamento pode levar alguns instantes.");

        // disable paywall
        this.globals.setShowPaywallGlobals(false);
        localStorage.setItem('cmpBlocked', 'false');
        this.globals.companyNoPay = false;
        
        this.router.navigateByUrl('/ambiente/agenda/mobile-download');
      },
      error: (err) => {
        console.log(err);
        this.ngxLoaderSvc.stop();
        this.globals.openDialog("Erro", err.error.message);
      }
    });
  }

  tryContratar(){

    // check recaptcha
    this.recaptchaSolution = this.formPag.get('recaptchaSolution').value;

    if (!this.recaptchaSvc.solveRecaptcha(this.recaptchaSolution)){
      alert("A solução do recaptcha não está correta");
      return;
    }

    window.scroll(0,0);
    this.alertaConfNeg("Atenção", "Você está prestes a contratar o pacote de assinatura. Você pode cancelar seu plano a qualquer momento.", "Ok, concordo", 1)
  }

  async alertaConfNeg(pTitle, pMessage, pBtnText, handlerDest){
    this.globals.openConfirm(pMessage, () =>{
      if (handlerDest == 0) {
        return;
      } else if (handlerDest == 1) {
        this.runContractActions();
        //
      }
    });
  }

  async mostraAlerta(pTitle, pMessage, pBtnText, handlerDest ){

    this.globals.openConfirm(pMessage, () => {
      if (handlerDest == 0) {
        return;
      } else if (handlerDest == 2) {
        this.getPlanInfo();
        //
      }
    });
  }

  getPlanInfo(){
    this.ngxLoaderSvc.start();
    this.planSvc.getPlanId(this.plan.plan_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.plan = result[0];
        if (this.plan.keywords != null){
          this.plan.showKeyword = this.plan.keywords.split(";");
        }
        
        const creditEnabled = this.plan.payment_methods.includes('credit_card');
        const boletoEnabled = this.plan.payment_methods.includes('boleto');
        if (creditEnabled && boletoEnabled) {
          this.planoPermiteEscolherCreditoBoleto = true;
        } else if (creditEnabled && !boletoEnabled){
          this.planoPermiteEscolherCreditoBoleto = false;
          this.setPaymentCartao(true);
        } else if (!creditEnabled && boletoEnabled){
          // plano é só boleto, mas como os dados são os mesmos, setar o planoPermiteApenasCartao como false
          this.planoPermiteEscolherCreditoBoleto = false;
          this.setPaymentCartao(false);
        }

        if (this.plan.installments) {
          const installmentValue = (this.plan.amount / this.plan.installments).toFixed(2);
          this.installmentString = `${this.plan.installments} vezes de R$${installmentValue}`;
        } else {
          this.installmentString = '';
        }
        
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        this.mostraAlerta("Erro", "Não foi possível buscar os detalhes do plano", "Tentar novamente", 2);
      }
    );
  }

  getRecaptcha(){
    this.recaptchaSvc.getRecaptcha().subscribe(
      (result) => {
        this.recaptchaImgSrc = "data:image/png;base64," + result[0];
      },
      (err) => {
        // set to reload recaptcha after 3 sec
        setTimeout( () => {
          this.getRecaptcha();
        }, 3000);
      }
    );
  }

  getCompanyData(){
    this.ngxLoaderSvc.start();
    this.companySvc.getInfo(CompanyUtils.getUserId()).pipe(take(1)).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.companyData = result;

        const isCnpjFromApi = this.companyData.cnpj.length >= 15 ? true : false;
        this.isCPF = !isCnpjFromApi;

        // updates form data
        this.formPag.patchValue({
          email: this.companyData.email,
          name: this.companyData.nome_fantasia,
          document_number: this.companyData.cnpj,
          street: this.companyData.address_complete,
          ddd: this.parseTel(this.companyData.phone, 1),
          number: this.parseTel(this.companyData.phone, 2),
        });

        this.changeDetectorRef.detectChanges();

      },
      async () => {
        this.ngxLoaderSvc.stop();
        this.globals.openDialog('Erro', `Não foi possível carregar as informações da empresa. Por favor, tente novamente mais tarde.`);
      }
    );
  }

  parseTel(fullTelephone: string, mod1){
    if (mod1 === 1) {
      // returns ddd
      const res = fullTelephone.slice(1,3);
      return res;
    }
    if (mod1 === 2) {
      // returns telephone
      const res = fullTelephone.slice(4).replace('-', '');
      return res;

    }
  }

}
