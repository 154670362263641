
<div class="container-fluid appPaywall" style="padding-left: 40px;">

  <div class="row">

    <div class="col-6 containerEsq">
      
      <div class="row txtAlignCenter">
        
        <div class="col-1">
          <img class="coroaImg1" src="assets/paywall/coroa.png" alt="">
        </div>
        <div class="col-11">
          <p>{{paywallContent.head1}}</p>
        </div>
        
      </div>

      <div class="row txtAlignCenter">
        <p>{{paywallContent.description}}</p>
      </div>

      <div class="row" style="text-align: left;">
        <ul>
          <li *ngFor="let item of paywallContent.listaFunc">{{item}}</li>
        </ul>
      </div>

      <div class="row">
        <img class="imagem" src="{{paywallContent.imagePath}}" alt="">
      </div>

    </div>

    <div class="col-6 txtAlignCenter d-flex align-items-center">

      <div class="col">

        <div>
          <p class="watchVideo">Assista ao vídeo</p>
        </div>
        
        <iframe class="videoFrame" width="560" height="315" [src]="videoURL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <div style="padding-top: 30px;">
          <button class="desbloquearBtn" (click)="goToUsagePlans()">
            <img class="coroaImg" src="assets/paywall/coroa.png" alt="" style="margin-top: -10px;">
            Desbloquear funcionalidade!
          </button>
        </div>

      </div>

    </div>

  </div>

</div>
