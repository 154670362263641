import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CompanyUtils {
  static token = '';
  static companyId;
  static userType;
  static userId;
  static img_cartao_link: string;
  static companyName: string;
  private _companyHandle: string;
  static scheduleAlarmPreference;
  static unicodeLocaleId: string;
  static currencyIso4217Code: string;
  static currencySymbol: string;
  static razaoSocial: string;
  //permissoes do usuario
  static visualizarAgenda;
  static editarAgenda;
  static historicoAgenda;
  static financeiro;
  static relatoriosFaturamento;
  static listaEspera;
  static clientes;
  static gerenciarFuncionarios;
  static gerenciarRecessos;
  static planosAssinaturas;
  static controledecaixa;
  static comandas;
  static produtoseestoque;

  static setToken(newToken: string) {
    this.token = newToken;

    if (localStorage.getItem("cmpToken")) {
      localStorage.removeItem("cmpToken");
    }

    localStorage.setItem("cmpToken", newToken);
  }

  static getToken(): string {

    if (this.token.length == 0) {
      this.token = localStorage.getItem("cmpToken");
    }

    return this.token;
  }

  static jsDateToStamp(date: Date): string {

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    let monthStr = month.toString();
    if (month < 10) {
      monthStr = '0' + month.toString();
    }

    let dayStr = day.toString();
    if (day < 10) {
      dayStr = '0' + day.toString();
    }

    return year.toString() + monthStr + dayStr;
  }

  static setCompanyId(id) {
    this.companyId = id;

    if (localStorage.getItem("company_id")) {
      localStorage.removeItem("company_id");
    }

    localStorage.setItem("company_id", id);
  }

  static saveToLocalStorage(key: string, value: string){

    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }

    localStorage.setItem(key, value);

  }

  static getCompanyId() {

    if (!this.companyId) {
      this.companyId = localStorage.getItem("company_id");
    }

    return this.companyId;
  }

  static setUserType(type) {
    this.userType = type;

    if (localStorage.getItem("cmpUserType")) {
      localStorage.removeItem("cmpUserType");
    }

    localStorage.setItem("cmpUserType", type);
  }

  static getUserType() {

    if (!this.userType) {
      this.userType = localStorage.getItem("cmpUserType");
    }

    return this.userType;
  }

  static setUserId(id) {
    this.userId = id;

    if (localStorage.getItem("cmpUserId")) {
      localStorage.removeItem("cmpUserId");
    }

    localStorage.setItem("cmpUserId", id);
  }

  static getUserId() {

    if (!this.userId) {
      this.userId = localStorage.getItem("cmpUserId");
    }

    return this.userId;
  }

  static setCompanyName(name: string) {
    this.companyName = name;

    if (localStorage.getItem("cmpName")) {
      localStorage.removeItem("cmpName");
    }

    localStorage.setItem("cmpName", name);
  }

  static getCompanyName() {

    if (!this.companyName) {
      this.companyName = localStorage.getItem("cmpName");
    }

    return this.companyName;
  }

  static setImgCartaoLink(imgLink): void{
    this.img_cartao_link = imgLink;

    if (localStorage.getItem("img_cartao_link")) {
      localStorage.removeItem("img_cartao_link");
    }

    localStorage.setItem("img_cartao_link", imgLink);
  }

  static setPageTheme(objParam: any) {
    const themeObj = {
      button: objParam.button,
      font: objParam.font,
      font2: objParam.font2,
      primary: objParam.primary,
      secondary: objParam.secondary,
      tertiary: objParam.tertiary,
      sys_theme: objParam.sys_theme,
    }

    if (localStorage.getItem("page_theme")) {
      localStorage.removeItem("page_theme");
    }
    localStorage.setItem("page_theme", JSON.stringify(themeObj));
    return themeObj;
  }

  static getPageTheme() {
    const themeObj = JSON.parse(localStorage.getItem("page_theme"));
    return themeObj;
  }

  static getRazaoSocial() {
    if (this.razaoSocial == undefined) {
      this.razaoSocial = localStorage.getItem("razaoSocial");
    }
    return this.razaoSocial;
  }

  static setCurrencyIso4217Code(symbol: string){
    this.currencyIso4217Code = symbol;
    this.saveToLocalStorage('currencyIso4217Code', symbol);
  }

  static getCurrencyIso4217Code(): string{
    if(this.currencyIso4217Code == undefined){
      this.currencyIso4217Code = localStorage.getItem('currencyIso4217Code');
    }
    return this.currencyIso4217Code;
  }

  static setUnicodeLocaleId(unicodeLocaleId: string) {
    this.unicodeLocaleId = unicodeLocaleId;
    this.saveToLocalStorage('unicodeLocaleId', unicodeLocaleId);
  }

  static getUnicodeLocaleId(): string {
    if (!this.unicodeLocaleId) {
      this.unicodeLocaleId = localStorage.getItem('unicodeLocaleId');
    }
    return this.unicodeLocaleId;
  }

  static setCurrencySymbol(symbol: string){
    this.currencySymbol = symbol;

    this.saveToLocalStorage('currencySymbol', symbol);

  }

  static getCurrencySymbol(): string{

    if(this.currencySymbol == undefined){
      this.currencySymbol = localStorage.getItem('currencySymbol');
    }

    return this.currencySymbol;
  }

  static getImgCartaoLink(): string {
    if (!this.img_cartao_link) {
      this.img_cartao_link = localStorage.getItem("img_cartao_link");
    }

    return this.img_cartao_link;
  }

  static setAlarmPreference(schedulePref): void{
    this.scheduleAlarmPreference = schedulePref;

    if (localStorage.getItem("schedule_alarm_preference")) {
      localStorage.removeItem("schedule_alarm_preference");
    }

    localStorage.setItem("schedule_alarm_preference", schedulePref);
  }

  static getAlarmPreference(): number {
    const tmpAlarmPref = localStorage.getItem("schedule_alarm_preference");
    if (!tmpAlarmPref) {
      this.setAlarmPreference(1);
      this.scheduleAlarmPreference = 1;
    } else {
      this.scheduleAlarmPreference = parseInt(tmpAlarmPref);
    }

    return this.scheduleAlarmPreference;
  }

  static toBrMoneyMask(value: string): string {

    let maskedValue: string;

    if(value == undefined || value.length == 0){
      maskedValue = '0,00';
    }
    else {

      value = value.replace(new RegExp('[^0-9]'), '');
      value = Number.parseInt(value).toString();

      if(Number.parseInt(value) == 0){
        value = '';
      }

      if(value.length == 0){
        maskedValue = '0,00';
      }
      else if(value.length == 1){
        maskedValue = '0,0' + value;
      }
      else if(value.length == 2){
        maskedValue = '0,' + value;
      }
      else{
        maskedValue = value.substring(0, value.length-2) + ',' + value.substr(value.length-2);
      }
    }

    return maskedValue;
  }

  public get companyHandle() {
    return this._companyHandle;
  }

  public set companyHandle(value) {
    this._companyHandle = value;
  }

  static clearStorageForLogout(): void {
    localStorage.removeItem("cmpToken");
    localStorage.removeItem("company_id");
    localStorage.removeItem("cmpUserType");
    localStorage.removeItem("cmpUserId");
    localStorage.removeItem("cmpName");
    localStorage.removeItem("img_cartao_link");
    localStorage.removeItem("page_theme");
    this.clearPermissions();
  }

  static clearPermissions(): void {
    localStorage.removeItem("visualizarAgenda");
    localStorage.removeItem("editarAgenda");
    localStorage.removeItem("historicoAgenda");
    localStorage.removeItem("financeiro");
    localStorage.removeItem("relatoriosFaturamento");
    localStorage.removeItem("listaEspera");
    localStorage.removeItem("clientes");
    localStorage.removeItem("gerenciarFuncionarios");
    localStorage.removeItem("gerenciarRecessos");
    localStorage.removeItem("planosAssinaturas");
    localStorage.removeItem("controledecaixa");
    localStorage.removeItem("comandas");
    localStorage.removeItem("produtoseestoque");
  }

  static setUserAccess(access: string): void{
    let ids;
    this.clearPermissions();
    if(!access){
      ids = [];
    } else {
      ids = access.split(',');
    }

    ids.forEach(id => {
      //console.log(id == 7)
      if(id == 1) {
        this.visualizarAgenda = true;
        if (localStorage.getItem("visualizarAgenda")) {
          localStorage.removeItem("visualizarAgenda");
        }
        localStorage.setItem("visualizarAgenda", 'true');
      }
      if(id == 2) {
        this.editarAgenda = true;
        if (localStorage.getItem("editarAgenda")) {
          localStorage.removeItem("editarAgenda");
        }
        localStorage.setItem("editarAgenda", 'true');
      }
      if(id == 3) {
        this.historicoAgenda = true;
        if (localStorage.getItem("historicoAgenda")) {
          localStorage.removeItem("historicoAgenda");
        }
        localStorage.setItem("historicoAgenda", 'true');
      }
      if(id == 4) {
        this.financeiro = true;
        if (localStorage.getItem("financeiro")) {
          localStorage.removeItem("financeiro");
        }
        localStorage.setItem("financeiro", 'true');
      }
      if(id == 5) {
        this.relatoriosFaturamento = true;
        if (localStorage.getItem("relatoriosFaturamento")) {
          localStorage.removeItem("relatoriosFaturamento");
        }
        localStorage.setItem("relatoriosFaturamento", 'true');
      }
      if(id == 6) {
        this.listaEspera = true;
        if (localStorage.getItem("listaEspera")) {
          localStorage.removeItem("listaEspera");
        }
        localStorage.setItem("listaEspera", 'true');
      }
      if(id == 7) {
        this.clientes = true;
        if (localStorage.getItem("clientes")) {
          localStorage.removeItem("clientes");
        }
        localStorage.setItem("clientes", 'true');
      }
      if(id == 8) {
        this.gerenciarFuncionarios = true;
        if (localStorage.getItem("gerenciarFuncionarios")) {
          localStorage.removeItem("gerenciarFuncionarios");
        }
        localStorage.setItem("gerenciarFuncionarios", 'true');
      }
      if(id == 9) {
        this.gerenciarRecessos = true;
        if (localStorage.getItem("gerenciarRecessos")) {
          localStorage.removeItem("gerenciarRecessos");
        }
        localStorage.setItem("gerenciarRecessos", 'true');
      }
      if(id == 10) {
        this.planosAssinaturas = true;
        if (localStorage.getItem("planosAssinaturas")) {
          localStorage.removeItem("planosAssinaturas");
        }
        localStorage.setItem("planosAssinaturas", 'true');
      }
      if(id == 11) {
        this.controledecaixa = true;
        if (localStorage.getItem("controledecaixa")) {
          localStorage.removeItem("controledecaixa");
        }
        localStorage.setItem("controledecaixa", 'true');
      }
      if(id == 12) {
        this.comandas = true;
        if (localStorage.getItem("comandas")) {
          localStorage.removeItem("comandas");
        }
        localStorage.setItem("comandas", 'true');
      }
      if(id == 13) {
        this.produtoseestoque = true;
        if (localStorage.getItem("produtoseestoque")) {
          localStorage.removeItem("produtoseestoque");
        }
        localStorage.setItem("produtoseestoque", 'true');
      }
    });
  }

  static canVisulizarAgenda(): boolean {
    if (!this.visualizarAgenda) {
      this.visualizarAgenda = localStorage.getItem("visualizarAgenda");
    }
    return this.visualizarAgenda;
  }

  static canEditarAgenda(): boolean {
    if (!this.editarAgenda) {
      this.editarAgenda = localStorage.getItem("editarAgenda");
    }
    return this.editarAgenda;
  }

  static canHistoricoAgenda(): boolean {
    if (!this.historicoAgenda) {
      this.historicoAgenda = localStorage.getItem("historicoAgenda");
    }
    return this.historicoAgenda;
  }

  static canFinanceiro(): boolean {
    if (!this.financeiro) {
      this.financeiro = localStorage.getItem("financeiro");
    }
    return this.financeiro;
  }

  static canRelatoriosFaturamento(): boolean {
    if (!this.relatoriosFaturamento) {
      this.relatoriosFaturamento = localStorage.getItem("relatoriosFaturamento");
    }
    return this.relatoriosFaturamento;
  }

  static canListaEspera(): boolean {
    if (!this.listaEspera) {
      this.listaEspera = localStorage.getItem("listaEspera");
    }
    return this.listaEspera;
  }

  static canClientes(): boolean {
    if (!this.clientes) {
      this.clientes = localStorage.getItem("clientes");
    }
    return this.clientes;
  }

  static canGerenciarFuncionarios(): boolean {
    if (!this.gerenciarFuncionarios) {
      this.gerenciarFuncionarios = localStorage.getItem("gerenciarFuncionarios");
    }
    return this.gerenciarFuncionarios;
  }

  static canGerenciarRecessos(): boolean {
    if (!this.gerenciarRecessos) {
      this.gerenciarRecessos = localStorage.getItem("gerenciarRecessos");
    }
    return this.gerenciarRecessos;
  }

  static canPlanosAssinaturas(): boolean {
    if (!this.planosAssinaturas) {
      this.planosAssinaturas = localStorage.getItem("planosAssinaturas");
    }
    return this.planosAssinaturas;
  }

  static canControleDeCaixa(): boolean {
    if (!this.controledecaixa) {
      this.controledecaixa = localStorage.getItem("controledecaixa");
    }
    return this.controledecaixa;
  }

  static canComandas(): boolean {
    if (!this.comandas) {
      this.comandas = localStorage.getItem("comandas");
    }
    return this.comandas;
  }

  static canProdutosEEstoque(): boolean {
    if (!this.produtoseestoque) {
      this.produtoseestoque = localStorage.getItem("produtoseestoque");
    }
    return this.produtoseestoque;
  }
}
