import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyUser } from '../../svc/company/company-user/CompanyUser';
import { CompanyUtils } from '../../svc/company/company-utils';
import { Globals } from '../../svc/globals';
import { NewEmailService } from '../../svc/company/new-email/new-email.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-company-edit-login',
  templateUrl: './company-edit-login.component.html',
  styleUrls: ['./company-edit-login.component.scss']
})
export class CompanyEditLoginComponent implements OnInit {

  data = new CompanyUser();
  constructor(
    private globals: Globals,
    private emailSvc: NewEmailService,
    private router: Router,
    private ngxLoaderSvc: NgxUiLoaderService,
  ) {
    this.data = new CompanyUser();
  }

  ngOnInit() { 
    this.globals.headerTitle = 'Alterar email de login';
    this.data = new CompanyUser();
    this.getEmail();
  }

  async change(){

    if (this.data.email != undefined && this.data.email.length == 0){
      this.globals.openDialog('Atenção', 'Insira o novo email.');
      return;
    }

    this.data.company_id = CompanyUtils.getCompanyId();
    this.data.companyuser_id = CompanyUtils.getUserId();

    this.globals.openConfirm(`Você deseja realmente alterar seu email de login para ${this.data.email}?`, () => {
      this.ngxLoaderSvc.start();

      this.emailSvc.patch(this.data).subscribe(
        async (message) => {
          this.ngxLoaderSvc.stop();
          this.globals.openDialog('Sucesso', 'Email alterado com sucesso');
        },
        async (err) => {
          this.ngxLoaderSvc.stop();
          this.globals.openDialog('Erro', err);
        }
      );
    });
  }


  getEmail() {
    this.ngxLoaderSvc.start();
    this.emailSvc.getEmail(CompanyUtils.getUserId()).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        // console.log('debug: result from getEmail = ', result);
        this.data.email = result.email;
      },
      async () => {
        this.ngxLoaderSvc.stop();
        this.globals.openDialog('Erro', 'Não foi possível carregar o email do usuário. Por favor, tente novamente mais tarde.');
        
      }
    );
  }

}
