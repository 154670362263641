
export class Plan {
    amount: number = 0;
    assinatura_id: number = 0;
    days: number = 0;
    descricao: string = '';
    gateway_id: string = '';
    installments: number;
    keywords: string = '';
    name: string = '';
    payment_methods: string = '';
    plan_id: number = 0;
    publicName: string = '';
    showKeyword: Array<string> = [];
    status: string = '';
    trial_days: number = 0;
}