import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../svc/company/client/client.service';
import { CompanyUtils } from '../../svc/company/company-utils';
import { Globals } from '../../svc/globals';

class Client {
  appuser_id: number;
  name: string;
  value: string;
  phone: string;
  email: string;
  ticket: string;
  codigo: string;
  data_consumo: string;
}

@Component({
  selector: 'app-agenda-client-detail',
  templateUrl: './agenda-client-detail.component.html',
  styleUrls: ['./agenda-client-detail.component.scss']
})
export class AgendaClientDetailComponent implements OnInit {
  isLoading = false;

  data: Client;

  constructor(
    public globals: Globals,
    public clientSvc: ClientService,
    public route: ActivatedRoute

  ) {
    this.data = new Client();
  }

  ngOnInit() {
    this.globals.headerTitle = "Detalhes Cliente";

    this.data = new Client();
    if (this.route.snapshot.paramMap.get('id') != null) {
      this.data.appuser_id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    }

    this.getData();
  }

  async showMessageAlert(title, messageText) {
    this.globals.openDialog(title, messageText);
    return;
  }


  getData() {
    this.isLoading = true;
    this.clientSvc.getDetailsAgenda(CompanyUtils.getCompanyId(), this.data.appuser_id).subscribe(
      (result) => {
        this.isLoading = false;
        this.data = result;

        this.data.ticket = Number.parseFloat(this.data.ticket).toFixed(2);
      },
      async () => {
        this.isLoading = false;
        this.showMessageAlert('Atenção', 'Não foi possível carregar os dados do cliente. Por favor, verifique a sua conexão e tente novamente mais tarde.');
        return;
      }
    );
  }

}

