import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';
@Injectable({
    providedIn: 'root'
})
export class AgendamentoSettingsServicePubl extends BaseService {
    baseURL = `${Config.vWebUrl}agendamento_settings_edp/`;
    constructor(private h: HttpClient) {
        super(h);
    }
    getAgendamentoSettings(company_id){
        return this.http.get(this.baseURL + `${company_id}/-1`, {
        context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
        })
        .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
            catchError(this.handleErrors);
            }
            return data.result;
        }),
        catchError(this.handleErrors)
        );
    }
}