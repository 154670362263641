import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { BaseService } from '../../base.service';
import { CompanyUtils } from '../company-utils';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})

export class PermissionsService extends BaseService  {
    baseURL = `${Config.vBusUrl}permissions_edp/`;

    constructor(private h: HttpClient) {
        super(h);
    }

    getUserPermissions(user_id) {
        return this.http.get(this.baseURL + `${user_id}/-1`)
        .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }
            return data.result;
        }),
        catchError(this.handleErrors)
        );
    }
}
