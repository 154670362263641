import { Injectable } from '@angular/core';
import { Pedido } from './pedido';
import { Product } from '../product/product';
import { DeliveryMethod } from '../company/delivery/deliveryMethod';

@Injectable({
  providedIn: 'root'
})
export class PedidoHandler {

  protected pedidoData: Pedido;
  protected produto: Product;
  protected retornoDoFrete: any = {};
  protected shopStatus: boolean = true;

  constructor() {
    this.pedidoData = new Pedido();
    this.pedidoData.productLst = [];
    this.produto = new Product();
  }

  getPedido(): Pedido{
    return this.pedidoData;
  }

  setPedido(pPed: Pedido): void{
    this.pedidoData = pPed;
  }

  pushProdutcToPedido(pProduct: Product): void {
    if (!this.pedidoData.productLst) {
      this.pedidoData.productLst = [];
    }
    this.pedidoData.productLst.push(Object.assign({}, pProduct));
    this.updatePedidoPrice();
  }

  getProduto(): Product{
    return this.produto;
  }

  setProduto(pProd: Product): void{
    this.produto = {...pProd};
  }

  setRetornoDoFrete(pRetornoDoFrete: any): void {
    this.retornoDoFrete = pRetornoDoFrete;
  }

  getRetornoDoFrete(){
    return this.retornoDoFrete
  }

  setShopStatus(pShopStatus: boolean){
    this.shopStatus = pShopStatus;
  }

  getShopStatus(){
    return this.shopStatus;
  }

  updatePedidoPrice() {
    let totalPrice = 0;
    this.pedidoData?.productLst?.forEach(prod => {
      totalPrice += (+prod.subtotal);
    });
    this.pedidoData.total_pedido = totalPrice;
    
    if (this.pedidoData.opcao_retirada_id == 2) {
      
      if (this.retornoDoFrete.price != undefined && this.retornoDoFrete.price > 0
        && (this.retornoDoFrete.frete_mode === 1 || this.retornoDoFrete.frete_mode === -3) ) {
          console.log(this.pedidoData.total_pedido);
          console.log(this.retornoDoFrete.price);
        this.pedidoData.total_pedido += this.retornoDoFrete.price;
      }
    }
  }

  computeAProductSubTotal(pProduct: Product){
    pProduct.price = Number.parseFloat(pProduct.price.toString());

    // console.log("debug: computing subtotal");
    if (pProduct.hasVariant) {
      pProduct.subtotal = 0;
      pProduct.variants.forEach(vari => {
        pProduct.subtotal += vari.price * vari.order_quant;
      });
    } else {
      pProduct.subtotal = 0;

      if (pProduct.productOptions != undefined) {
        // this.optionIsSelected = true;
        pProduct.productOptions.forEach(elem => {
          if (elem.selectedOption === undefined || elem.selectedOption === null) {
            // this.optionIsSelected = false;
          }
        });
      }

      let aditionalSubtotal = 0;
      if (pProduct.hasAditional) {
        pProduct.productAditionals.forEach((adt) => {
          adt.values.forEach(value => {
            if (value.added) {
              aditionalSubtotal += (value.order_quant * value.price);
            }
          });
        });
      }
      // pProduct.subtotal = ((+aditionalSubtotal) + (+pProduct.subtotal));

      let optionSubtotal = 0;
      let isIncremental = true;
      const toCalculateGreaterPrice = [];
      const toCalculateMediumPrice = [];
      if (pProduct.productOptions != undefined) {
        pProduct.productOptions.forEach((opt) => {
          if (opt.selectedOption != undefined) {

            if(opt.is_greater_price == true){

              if(opt.selectedOption.price > pProduct.price){
                toCalculateGreaterPrice.push(opt.selectedOption.price);
                isIncremental = false;
              }
            }
            else if(opt.considers_medium_price == true){
              if(opt.selectedOption.price > 0){
                toCalculateMediumPrice.push(opt.selectedOption.price);
                isIncremental = false;
              }
            }
            else{
              optionSubtotal += (+opt.selectedOption.price);
            }
            
          }
        });
      }

      if(!isIncremental){
        if(toCalculateGreaterPrice.length > 0){

          let greaterPrice = 0;
          toCalculateGreaterPrice.forEach(price => {
            if(price > greaterPrice){
              greaterPrice = price;
            }
          });

          optionSubtotal += greaterPrice;
        }

        if(toCalculateMediumPrice.length > 0){

          if(pProduct.price > 0){

            let priceSum = pProduct.price;
            toCalculateMediumPrice.forEach(price => {
              priceSum += price;
            });

            optionSubtotal += priceSum/(toCalculateMediumPrice.length+1);
          }
          else {

            let priceSum = 0;
            toCalculateMediumPrice.forEach(price => {
              priceSum += price;
            });

            optionSubtotal += priceSum/(toCalculateMediumPrice.length);
          }
        }
      }

      pProduct.subtotal = ((+optionSubtotal) + (+pProduct.subtotal));

      if(isIncremental){
        pProduct.subtotal = (+pProduct.price) + (+pProduct.subtotal);
      }

      let variantSubtotal = 0;
      if (pProduct.hasVariant) {
        pProduct.variants.forEach((vari) => {
          if (vari.added) {
            variantSubtotal += (vari.order_quant * vari.price);
          }
        });
      }
      pProduct.subtotal = ((+variantSubtotal) + (+pProduct.subtotal));
      pProduct.subtotal *= pProduct.order_quant;
      pProduct.subtotal = pProduct.subtotal + (aditionalSubtotal * pProduct.order_quant);  // adds the aditional subtotal here, instead of upper there
      this.updatePedidoPrice();
    }
  }

  removeProdutoOrder(productIndex: number) {
    const newProductList = this.pedidoData.productLst.filter((element,index) => {
      if (index !== productIndex){
        return element;
      }
    });
    this.pedidoData.productLst = newProductList;
    this.updatePedidoPrice();
    return this.pedidoData;
  }

  increaseProdutoOrder(productIndex: number, productItem: Product) {
    this.pedidoData.productLst[productIndex].order_quant += 1;
    this.computeAProductSubTotal(this.pedidoData.productLst[productIndex]);
    return this.pedidoData.productLst[productIndex];
  }

  decreaseProdutoOrder(productIndex: number, productItem: Product) {
    if (this.pedidoData.productLst[productIndex].order_quant > 1) {
      this.pedidoData.productLst[productIndex].order_quant = 
        ((+this.pedidoData.productLst[productIndex].order_quant) - 1);
      this.computeAProductSubTotal(this.pedidoData.productLst[productIndex]);
    }
    return this.pedidoData.productLst[productIndex];
  }

  clearPedidoProperties(){
    this.pedidoData = new Pedido();
    this.pedidoData.productLst = [];
    this.produto = new Product();
  }

  chooseDeliveryOption(opcao_retirada: DeliveryMethod[], opcDelivery: DeliveryMethod, opcDelIndex: number){
    // clear others opc.selected
    opcao_retirada.forEach((opc, index) => {
      if (index != opcDelIndex) {
        opc.selected = false;
      } else {
        opc.selected = true;
      }
    });

    this.pedidoData.opcao_retirada_id = opcDelivery.delivery_method_id;
    return this.pedidoData;
  }


}
