import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CaptchaService } from '../svc/captcha/captcha.service';
import { Plan } from '../svc/company/plan/plan';
import { PlanService } from '../svc/company/plan/plan.service';
import { State } from '../svc/state/State';
import { StateService} from '../svc/state/state.service';
import { City } from '../svc/city/City';
import { CityService} from '../svc/city/city.service';
import { Category } from '../svc/company/category/Category';
import { CategoryService} from '../svc/company/category/category.service';
import { Globals } from '../svc/globals';
import { CompanyUtils } from '../svc/company/company-utils';

import { PayRegisterService } from '../svc/company/register/pay-register.service';

@Component({
  selector: 'app-page-pay-register',
  templateUrl: './page-pay-register.component.html',
  styleUrls: ['./page-pay-register.component.scss']
})
export class PagePayRegisterComponent implements OnInit {

  subscriptionForm = this.formBuilder.group({
    // card data
    card_number: [],
    card_holder_name: [],
    card_expiration_date: [],
    card_cvv: [],

    // form general data
    document_numberCPF: [undefined],
    document_numberCNPJ: [undefined],

    // cadastro data
    email: [undefined, [Validators.required, Validators.minLength(7)]],
    name: [undefined, [Validators.required, Validators.minLength(3)]],
    street: [undefined, [Validators.required, Validators.minLength(2)]],
    street_number: [undefined, [Validators.required, Validators.minLength(1)]],
    complement: [undefined],
    neighborhood: [undefined, [Validators.required, Validators.minLength(2)]],
    zipcode: [undefined, [Validators.required]],
    ddd: [undefined, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
    number: [undefined, [Validators.required, Validators.minLength(8)]],
    whatsphone: [undefined, [Validators.required, Validators.minLength(14)]],
    nome_fantasia: [undefined, [Validators.required, Validators.minLength(3)]],
    password: [undefined, [Validators.required, Validators.minLength(6)]],
    passAux: [undefined, [Validators.required, Validators.minLength(6)]],

    // recaptcha
    recaptchaSolution: [undefined, [Validators.required]],

    state_id: [undefined, [Validators.required]],
    city_id: [undefined, [Validators.required]], 
    category_id: [0],

    plan_id: [undefined, [Validators.required]]
    
  });

  states: State[] = [];
  cityLst: City[] = [];
  filteredCityLst: City[] = [];
  chosenCity: string = 'Selecione a cidade';
  cityStr = '';
  showCityLst = false;

  categories: Category[] = [];
  selectedCat = new Array<number>();

  array1 = [5,4,3,2,9,8,7,6,5,4,3,2];
	array2 = [6,5,4,3,2,9,8,7,6,5,4,3,2];
  array3 = [11,10,9,8,7,6,5,4,3,2];

  isCPF = false;

  plans = new Array<Plan>();
  planoPermiteEscolherCreditoBoleto: boolean = false;
  isPaymentCartao: boolean = true;
  payment_method = 'credit_card';
  trial_days = 0;

  recaptchaImgSrc: string;

  acceptedTerms = false;

  isMobile = false;

  constructor(
    private router: Router,
    private registerSvc: PayRegisterService,
    private planSvc: PlanService,
    private recaptchaSvc: CaptchaService,
    private formBuilder: UntypedFormBuilder,
    private ngxLoaderSvc: NgxUiLoaderService,
    private stateSvc: StateService,
    private citySvc: CityService,
    private categSvc: CategoryService,
    public globals: Globals
  ) { }

  ngOnInit(): void {

    this.isMobile = window.screen.width < 480;

    this.getCategs();
    this.getStates();
    this.getPlans();

    this.getRecaptcha();
  }

  cleanField(pStr: string){
    return (pStr.replace(/([./-\s])+/g, ''));
  }

  getStates(){
    this.ngxLoaderSvc.start();
    this.stateSvc.getAllFromCountry(1).subscribe(
      (rows) => {
        this.ngxLoaderSvc.stop();
        this.states = rows;
      },
      async () => {
        this.ngxLoaderSvc.stop();
        alert(`Não foi possível carregar os estados, tente novamente mais tarde.`);
      }
    );
  }

  getCities(){
    this.ngxLoaderSvc.start();
    this.citySvc.getRows(this.subscriptionForm.get('state_id')?.value).subscribe(
      (rows) => {
        this.ngxLoaderSvc.stop();
        this.cityLst = rows;
        this.filteredCityLst = this.cityLst;
      },
      async () => {
        this.ngxLoaderSvc.stop();
        alert(`Não foi possível carregar as cidades, tente novamente mais tarde.`);
      }
    );
  }

  getCategs(){
    this.ngxLoaderSvc.start();
    this.categSvc.getAll().subscribe(
      (rows) => {
        this.ngxLoaderSvc.stop();
        // this.categories.push({'category_id': 0, 'name': 'Selecione uma opção', 'selected': true});
        rows.forEach(element => {
          this.categories.push(element);
        });

      },
      async () => {
        this.ngxLoaderSvc.start();
        alert('Não foi possível carregar os categorias, tente novamente mais tarde.');
      }
    );
  }

  getPlans(){
    this.ngxLoaderSvc.start();
    this.planSvc.getPlans(1, -1, false).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.plans = result;
        this.plans.forEach(plan => {

          if (plan.keywords != null){
            plan.showKeyword = plan.keywords.split(";");
          }
          
        });
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.log(err);
        alert('Não foi possível trazer os planos');
      }
    );
  }

  isPaymentCartaoSelect(pBol){
    this.isPaymentCartao = pBol;
    if (pBol){
      this.payment_method = "credit_card";
    } else {
      this.payment_method = "boleto";
    }
  }

  selectPlan(){

    for(let i = 0; i < this.plans.length; i++){
      if(this.plans[i].plan_id == this.subscriptionForm.get('plan_id')?.value){

        this.trial_days = this.plans[i].trial_days;

        const creditEnabled = this.plans[i].payment_methods.includes('credit_card');
        const boletoEnabled = this.plans[i].payment_methods.includes('boleto');
        if (creditEnabled && boletoEnabled) {
          this.planoPermiteEscolherCreditoBoleto = true;
        } else if (creditEnabled && !boletoEnabled){
          this.planoPermiteEscolherCreditoBoleto = false;
          this.isPaymentCartaoSelect(true);
        } else if (!creditEnabled && boletoEnabled){
          // plano é só boleto, mas como os dados são os mesmos, setar o planoPermiteApenasCartao como false
          this.planoPermiteEscolherCreditoBoleto = false;
          this.isPaymentCartaoSelect(false);
        }

        break;
      }
    }
  }

  searchCity() {

    this.filteredCityLst = [];

    if(this.cityStr == undefined || this.cityStr.length == 0){
      this.filteredCityLst = this.cityLst;
      return;
    }

    this.cityStr = this.cityStr.substr(0,1).toUpperCase() + this.cityStr.substr(1, this.cityStr.length-1);
    this.cityLst.forEach(c => {
      if(c.name.startsWith(this.cityStr)){
        this.filteredCityLst.push(c);
      }
    });

  }

  onCitySelected(cityItem: City){
    this.subscriptionForm.get('city_id')?.setValue(cityItem.city_id);
    // this.registerData.state_id = cityItem.state_id;
    this.chosenCity = cityItem.name;
    this.showCityLst = false;
    this.globals.presentToast("Cidade selecionada!");
    this.cityStr = '';
    this.filteredCityLst = this.cityLst;
  }

  checkPassword(): boolean{

    if(this.subscriptionForm.get('password')?.value == this.subscriptionForm.get('passAux')?.value){
      return true;
    }

    return false;
  }

  validateCNPJ(){

    const cnpj = this.subscriptionForm.get('document_numberCNPJ')?.value.replace(/\W/g, '');

    let d1 = 0;

    for(let i = 0; i < this.array1.length; i++){
      d1 += Number.parseInt(cnpj[i]) * this.array1[i];
    }

    d1 %= 11;

    if(d1 < 2){
      if(cnpj[this.array1.length] != "0"){
        return false;
      }
    }
    else{
      if(Number.parseInt(cnpj[this.array1.length]) != 11 - d1){
        return false;
      }
    }


    let d2 = 0;

    for(let i = 0; i < this.array2.length; i++){
      d2 += Number.parseInt(cnpj[i]) * this.array2[i];
    }

    d2 %= 11;

    if(d2 < 2){
      if(cnpj[this.array2.length] != "0"){
        return false;
      }
    }
    else{
      if(Number.parseInt(cnpj[this.array2.length]) != 11 - d2){
        return false;
      }
    }

    return true;

  }

  validateCPF(){

    const cpf = this.subscriptionForm.get('document_numberCPF')?.value.replace(/\W/g, '');

    let d1 = 0;

    for(let i = 1; i < this.array3.length; i++){
      d1 += Number.parseInt(cpf[i-1]) * this.array3[i];
    }

    d1 %= 11;

    if(d1 < 2){
      if(cpf[9] != "0"){
        return false;
      }
    }
    else{
      if(Number.parseInt(cpf[9]) != 11 - d1){
        return false;
      }
    }

    let d2 = 0;

    for(let i = 0; i < this.array3.length; i++){
      d2 += Number.parseInt(cpf[i]) * this.array3[i];
    }

    d2 %= 11;

    if(d2 < 2){
      if(cpf[10] != "0"){
        return false;
      }
    }
    else{
      if(Number.parseInt(cpf[10]) != 11 - d2){
        return false;
      }
    }

    return true;

  }

  isCpfSelect(pBol: boolean){
    this.isCPF = pBol;
  }

  getRecaptcha(){
    this.recaptchaSvc.getRecaptcha().subscribe(
      (result) => {
        this.recaptchaImgSrc = "data:image/png;base64," + result[0];
      },
      (err) => {
        // set to reload recaptcha after 3 sec
        setTimeout( () => {
          this.getRecaptcha();
        }, 3000);
      }
    );
  }

  register() {

    const formValues = this.subscriptionForm.value;

    if(!this.subscriptionForm.valid){

      if(formValues.password == undefined || formValues.password.length < 6){
        alert('Por favor, informe uma senha de pelo menos 6 dígitos.');
      }
      else{
        alert('Por favor, preencha todos os campos');
      }

      return;
    }

    if(this.isCPF){
      if(!this.validateCPF()){
        alert("Informe um CPF válido");
        return;
      }
    }
    else{
      if(!this.validateCNPJ()){
        alert("Informe um CNPJ válido");
        return;
      }
    }

    const checkPassRes = this.checkPassword();
    if(!checkPassRes){
      this.globals.openDialog('Atenção', 'As senhas são diferentes. Por favor repita a senha no campo confirmar senha.');
      return;
    }

    if (this.isPaymentCartao){
      if (formValues.card_cvv == undefined || formValues.card_cvv == "" ||
      formValues.card_expiration_date == undefined || formValues.card_expiration_date == "" ||
      formValues.card_holder_name == undefined || formValues.card_holder_name == "" ||
      formValues.card_number == undefined || formValues.card_number == "" ){
        alert("Por favor preencha todos os dados do cartão");
        return;
      }
    }

    // check recaptcha
    if (!this.recaptchaSvc.solveRecaptcha(formValues.recaptchaSolution)){
      alert("A solução do recaptcha não está correta");
      return;
    }

    if (!this.acceptedTerms) {
      alert('Por favor, leia e aceite os termos de uso.');
      return;
    }

    const registerData = {
      nome_fantasia: '',
      state_id: 0,
      city_id: 0,
      address_complete: '',
      whatsapp_phone: '',
      phone: '',
      cnpj: '',
      nome_responsavel: '',
      email: '',
      rawPassword: '',
      category_id: 0,
      payment_method: '',
      card_cvv: '',
      card_expiration_date: '',
      card_holder_name: '',
      card_number: '',
      customer: {
        address: {
          street: '',
          street_number: '',
          complementary: '',
          neighborhood: '',
          zipcode: ''
        },
        email: '',
        name: '',
        phone: {
          ddd: '',
          number: ''
        },
        document_number: ''
      },
      plan_id: 0,
      gateway_id: '',
      system_platform: 2

    };

    registerData.nome_fantasia = formValues.nome_fantasia;
    registerData.state_id = formValues.state_id;
    registerData.city_id = formValues.city_id;
    registerData.address_complete = formValues.street + ', ' + formValues.street_number + ', ' + formValues.neighborhood;
    registerData.whatsapp_phone = formValues.whatsphone;
    registerData.phone = formValues.phone;
    if(this.isCPF){
      registerData.cnpj = formValues.document_numberCPF;
    }
    else{
      registerData.cnpj = formValues.document_numberCNPJ;
    }
    registerData.nome_responsavel = formValues.name;
    registerData.phone = '(' + formValues.ddd + ') ' + formValues.number;

    registerData.email = formValues.email;
    registerData.rawPassword = formValues.password;
    registerData.category_id = formValues.category_id;

    registerData.payment_method = this.payment_method;
    registerData.card_cvv = formValues.card_cvv;
    registerData.card_expiration_date = formValues.card_expiration_date;
    registerData.card_holder_name = formValues.card_holder_name;
    registerData.card_number = formValues.card_number;
    registerData.customer.address.street = formValues.street;
    registerData.customer.address.street_number = formValues.street_number;
    registerData.customer.address.complementary = formValues.complement;
    registerData.customer.address.neighborhood = formValues.neighborhood;
    registerData.customer.address.zipcode = formValues.zipcode;
    registerData.customer.email = formValues.email;
    registerData.customer.name = formValues.name;
    registerData.customer.phone.ddd = formValues.ddd;
    registerData.customer.phone.number = formValues.number;
    registerData.customer.document_number = (this.isCPF ? formValues.document_numberCPF : formValues.document_numberCNPJ );

    for(let i = 0; i < this.plans.length; i++){
      if(this.plans[i].plan_id == formValues.plan_id){

        registerData.plan_id = this.plans[i].plan_id; // plan_id is the ID on Looping Database
        registerData.gateway_id = this.plans[i].gateway_id; // gateway_id is the ID on Pagar.Me

        break;
      }
    }

    // clean values
    if (this.isPaymentCartao){
      registerData.card_number = this.cleanField(registerData.card_number);
      registerData.card_expiration_date = this.cleanField(registerData.card_expiration_date);
    }
    registerData.customer.document_number = this.cleanField(registerData.customer.document_number);
    registerData.customer.address.zipcode = this.cleanField(registerData.customer.address.zipcode);
    registerData.customer.phone.number = this.cleanField(registerData.customer.phone.number);

    this.ngxLoaderSvc.start();
    this.registerSvc.register(registerData).subscribe(
      async (data) => {
        this.ngxLoaderSvc.stop();
        if (data.status == '200') {
          // save credentials on DB
          CompanyUtils.setToken(data.result.token);
          CompanyUtils.setCompanyId(data.result.company_id);
          CompanyUtils.setUserId(data.result.companyuser_id);
          CompanyUtils.setUserType(data.result.user_type);
          CompanyUtils.setCompanyName(registerData.nome_fantasia);
          this.globals.showAdminOpt = true;

          alert('Sucesso! Cadastro e assinatura realizados com sucesso. Faça login para accessar o WhatsAgenda.');
          this.router.navigateByUrl("/empresa/login");
        }
        else {
          this.globals.openDialog('Erro', `${data.message}`);
        }
      },
      async (error) => {
        this.ngxLoaderSvc.stop();
        console.log(error);
        this.globals.openDialog('Erro', `${error}`);
      }
    );

  }


}
