import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrMaskerModule } from 'br-mask';
import { AgmCoreModule } from '@agm/core';
import { PriceBRModule } from '../app/svc/priceBR/priceBR.module';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPrintModule } from 'ngx-print';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaywallModule } from './company/paywall/paywall.module';
import { CapitalizeFirstLetterModule } from './svc/capitalizeFirstLetter/capitalizeFirstLetter.module';
import { Globals } from './svc/globals';
import { AuthGuard } from './svc/company/auth/auth.guard';
import { CalendarModule, DateAdapter, CalendarWeekModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AmbienteAgendaModule } from './ambiente-agenda/ambiente-agenda.module';
import { PagePayRegisterModule } from './page-pay-register/page-pay-register.module';

// Custom components
import { AppComponent } from './app.component';
// import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { AgendamentoPublNewComponent } from './agendamento-publ-new/agendamento-publ-new.component';
import { AgendamentoConfNewComponent } from './agendamento-conf-new/agendamento-conf-new.component';
import { environment } from '../environments/environment';
import { CompanyNotFoundModule } from './components/company-not-found/company-not-found.module';
import { httpInterceptorProvider } from './interceptors';
import { CompanyLinkTreeComponent } from './company-link-tree/company-link-tree.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { ConfirmaAgendamentoComponent } from './confirma-agendamento/confirma-agendamento.component';
import { NovaAssinaturaModule } from './client-assinatura/nova-assinatura/nova-assinatura.module';
import { AppNativeLoader } from './_core';
import { CashRegisterStateService } from './services/CashRegisterStateService/cash-register-state.service';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    // CadastrarComponent,
    AgendamentoPublNewComponent,
    AgendamentoConfNewComponent,
    CompanyLinkTreeComponent,
    CatalogoComponent,
    ConfirmaAgendamentoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    PriceBRModule,
    CapitalizeFirstLetterModule,
    FormsModule,
    NgxUiLoaderModule,
    HttpClientModule,
    AmbienteAgendaModule,
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgxChartsModule,
    BrMaskerModule,
    NgxPrintModule,
    QRCodeModule,
    ClipboardModule,
    PagePayRegisterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwSB5_1xf6RKWQojoLXerSnh_bI4fh0Nc',
      libraries: ['places'],
    }),
    PaywallModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CompanyNotFoundModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
      // business rule: register service worker after 5 mins
      registrationStrategy: 'registerWhenStable:300000',
    }),
    CalendarWeekModule,
    NovaAssinaturaModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: AppNativeLoader, deps: [], multi: true },
    Globals,
    CashRegisterStateService,
    // NgbModule,
    AuthGuard,
    httpInterceptorProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
