<ngx-ui-loader></ngx-ui-loader>

<div class="mainBackground" [class]="[currentTheme]">

  <div class="topBlueBackground" [class]="[currentTheme]"> </div>


    <!-- ############################################################ -->
    <!--                            Topbar                            -->
    <!-- ############################################################ -->

    <app-company-top-bar (menuBtnClicked)="closeSideBar()"></app-company-top-bar>

    <app-subscription-feedback [triggerInputAux]="triggerBool"></app-subscription-feedback>

    <div class="cursorPointer marginHorizontalSmall marginVerticalMedium position-absolute top-0 start-0"
            *ngIf="globals.showMenuBtn && globals.showPageMainContent && globals.showTopBar" (click)="openSideBar()">

        <i class="fa fa-bars textBig mainColorPrimary200" aria-hidden="true"></i>

    </div>

    <!-- <div class="autoLayout align-items-start w-100"> -->
    <div class="d-flex align-items-start">
        <!-- ############################################################ -->
        <!--                            Sidebar                           -->
        <!-- ############################################################ -->


        <div class="sideBar" id="sidebar" [ngStyle]="{'left' : ((globals.showPageMainContent && globals.showMenuBtn) || !globals.showTopBar ? '-100%' : '0')}">

            <div class="flex-column" style="margin-left: 7%;">

                <div class="imgNameCompany">

                    <img src="{{company.img_cartao_link}}" class="companyLogo" alt="">

                    <div class="companyInfoDiv">
                        <label class="companyName">Olá,<br>{{nomeCompany}}</label>
                    </div>

                </div>
                <p class="companyName2">O link da sua agenda digital é:</p>
                <div class="autoLayout flex-column marginVerticalSmall paddingHorizontalSmall">

                    <div class="btn-copy-border autoLayout borderMainColorSecondary500 borderRadiusMedium justify-content-start">
                        <a class="body1Semibold textSmall neutralColor100 paddingSmall linkAgd" [href]="company.handle" target="_blank">{{company.handle}}</a>
                        <i *ngIf="linkCopied == false" class="btn-copy colorBtnCopy fa fa-files-o autoLayout cursorPointer paddingSmall align-self-stretch borderRadiusRightSmall"
                            aria-hidden="true" ngxClipboard [cbContent]="company.handle" (click)="linkCopied = true;"></i>
                        <i *ngIf="linkCopied == true" class="fa fa-check colorBtnCopy autoLayout btn-copy cursorPointer paddingSmall align-self-stretch borderRadiusRightSmall"
                            aria-hidden="true" ngxClipboard [cbContent]="company.handle"></i>

                    </div>

                </div>

            </div>

            <div class="menu lateral autoLayout flex-column">

                  <div class="iconDiv" (click)="goToAgendamentoList(); setSelectedButton(1) " [ngClass]="{'selected': selectedButton === 1}">

                    <span class="iconLabel"><i class="fa fa-home" aria-hidden="true"></i> Minha agenda</span>
                  </div>

                  <div class="iconDiv" (click)="goToAgendamentoCadastro2(3); setSelectedButton(4)" [ngClass]="{'selected': selectedButton === 4}" >
                    <span class="iconLabel">
                      <i class="fa fa-briefcase" aria-hidden="true"></i>
                      Serviços
                      <!-- <span *ngIf="globals.servicos.length === 0" class="services-status-indicator warning"></span> -->
                    </span>
                  </div>



                  <div class="iconDiv" (click)="goToAgendamentoCadastro(1); setSelectedButton(2) " [ngClass]="{'selected': selectedButton === 2}" >

                    <span class="iconLabel"><i class="fa fa-user" aria-hidden="true"></i>Profissionais</span>
                  </div>

                  <div  class="iconDiv " (click)="goToAgendaHistory(); setSelectedButton(13) " [ngClass]="{'selected': selectedButton === 13}" *ngIf="historicoAgenda">

                    <span class="iconLabel"><i class="fa fa-history" aria-hidden="true"></i>Histórico de agendamentos</span>
                  </div>

                  <!-- <div class="iconDiv" (click)="goToAgendamentoCadastro()">
                    <img class="sideIcon" src="assets/icons/editarAgenda.png"/>
                    <span class="iconLabel">Configurar agenda</span>
                  </div> -->


                  <!-- <div class="iconDiv " (click)="goToAgendamentoCadastro(2); setSelectedButton(3) " [ngClass]="{'selected': selectedButton === 3}">

                    <span class="iconLabel"><i class="fa fa-clock-o" aria-hidden="true"></i>Horário de atendimento</span>
                  </div> -->



                  <!-- <div class="iconDiv " (click)="goToAgendamentoCadastro(4); setSelectedButton(5) " [ngClass]="{'selected': selectedButton === 5}">

                    <span class="iconLabel"><i class="fa fa-users" aria-hidden="true"></i>Serviço por profissional</span>
                  </div> -->

                  <!-- <div class="iconDiv " (click)="goToAgendamentoCadastro(5); setSelectedButton(6) " [ngClass]="{'selected': selectedButton === 6}">

                    <span class="iconLabel"><i class="fa fa-cogs" aria-hidden="true"></i>Configurações Avançadas</span>
                  </div> -->

                  <div class="iconDiv" (click)="goToUserProfile(); setSelectedButton(7) " [ngClass]="{'selected': selectedButton === 7}" *ngIf="editarAgenda">

                    <span class="iconLabel"><i class="fa fa-cogs" aria-hidden="true"></i>Configurações Gerais</span>
                  </div>

                  <div  class="iconDiv" (click)="goToClientes(); setSelectedButton(10) " [ngClass]="{'selected': selectedButton === 10}" *ngIf="clientes">

                    <span class="iconLabel"><i class="fa fa-users" aria-hidden="true"></i>Clientes e prontuário</span>
                  </div>

                  <div class="iconDiv" (click)="setSelectedButton(16); goToCaixa()" routerLink="./pdv/2" [ngClass]="{'selected': selectedButton === 16}" *ngIf="controledecaixa">
                    <span class="iconLabel">
                      <i class="fa fa-fax" aria-hidden="true"></i>
                      Controle de Caixa
                      <span *ngIf="isCashRegisterOpen" class="status-indicator open"></span>
                      <span *ngIf="!isCashRegisterOpen" class="status-indicator closed"></span>
                    </span>
                  </div>


                  <div class="iconDiv" (click)=" setSelectedButton(17); goToComandas()" routerLink="./pdv/3" [ngClass]="{'selected': selectedButton === 17}">

                    <span class="iconLabel"><i class="fa fa-id-card" aria-hidden="true"></i>Comandas</span>
                  </div>

                </div>
                  <div class="menu lateral collapsible autoLayout flex-column">

                  <div class="iconDiv" (click)="setSelectedButton(17); openMenu(); goToProdutos()" *ngIf="produtoseestoque" [ngClass]="{'selected': selectedButton === 17}">

                    <span class="iconLabel"><i class="fa fa-shopping-basket" aria-hidden="true"></i>Produtos e Estoque</span>
                  </div>

                  <!-- <div class="iconDiv" (click)="setSelectedButton(18); openMenu(); goToClubeAssinaturas()" [ngClass]="{'selected': selectedButton === 18}" >

                    <span class="iconLabel"><i class="fa fa-users" aria-hidden="true"></i>Clube de Assinatura</span>
                  </div> -->

                  <!-- <div class="iconDiv" (click)="setSelectedButton(19); openMenu(); goToPacotes()" [ngClass]="{'selected': selectedButton === 19}" >

                    <span class="iconLabel"><i class="fa fa-book" aria-hidden="true"></i>Pacotes</span>
                  </div> -->

                  <div  class="iconDiv" (click)="goToFinancial(); setSelectedButton(8) " [ngClass]="{'selected': selectedButton === 8}" *ngIf="financeiro">

                      <span class="iconLabel"><i class="fa fa-credit-card-alt" aria-hidden="true"></i>Cálculo de comissões</span>
                  </div>

                  <div class="iconDiv" (click)="goToDashboard(); setSelectedButton(9) " [ngClass]="{'selected': selectedButton === 9}" *ngIf="relatoriosFaturamento">

                      <span class="iconLabel"><i class="fa fa-money" aria-hidden="true"></i>Relatórios de Faturamento</span>
                  </div>

                  <!-- <div  class="iconDiv" (click)="goToFinancial2(); setSelectedButton(8) " [ngClass]="{'selected': selectedButton === 8}" *ngIf="financeiro">

                    <span class="iconLabel"><i class="fa fa-credit-card-alt" aria-hidden="true"></i>Financeiro</span>
                  </div> -->


                  <!-- <div  class="iconDiv" (click)="goToListaEspera(); setSelectedButton(10) " [ngClass]="{'selected': selectedButton === 10}">

                    <span class="iconLabel"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i>Lista de espera</span>
                  </div> -->

                  <!-- <div  class="iconDiv" (click)="goToLinkTheme(); setSelectedButton(12) " [ngClass]="{'selected': selectedButton === 12}">

                  <span class="iconLabel"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Personalize o seu sistema</span>
                  </div>  -->

                  <div  class="iconDiv" (click)="goToIntegracaoPagamento(); setSelectedButton(15) " [ngClass]="{'selected': selectedButton === 14}" *ngIf="isAdmin">

                    <span class="iconLabel neutralColor10"><i class="fa fa-qrcode" aria-hidden="true"></i>Integração de pagamento</span>
                  </div>


                  <div  class="iconDiv" (click)="goToMeuLink(); setSelectedButton(14) " [ngClass]="{'selected': selectedButton === 14}" *ngIf="isAdmin">

                    <span class="iconLabel neutralColor10"><i class="fa fa-question-circle-o" aria-hidden="true"></i>Como funciona o WhatsAgenda</span>
                  </div>

                  

                  <!-- <div  class="iconDiv" (click)="openExternalLink('https://www.youtube.com/channel/UCaKbRezEFnrOcNNiDyjnWzA/featured'); setSelectedButton(15) " [ngClass]="{'selected': selectedButton === 15}">

                    <span class="iconLabel"><i class="fa fa-youtube" aria-hidden="true"></i>Tutoriais</span>
                  </div> -->

                </div>

                <div class="assinatura" (click)="goToUsagePlans(); setSelectedButton(18) " [ngClass]="{'selected': selectedButton === 18}">
                  <div *ngIf="companyPaymentStatus === 'paid'">
                    <h5 class="txt-supp">Você é PRO</h5>
                    <span class="txt-planos"> Sua assinatura vence dia: {{assinaturaFim}}</span>
                    <button class="btn-planos1" (click)="openPagarmeLink()" *ngIf="planosAssinaturas">Agendar Pagamento</button>
                  </div>
                  <div *ngIf="companyPaymentStatus === 'null' || !companyPaymentStatus ">
                    <h5 class="txt-supp">Torne-se PRO</h5>
                    <span class="txt-planos"> Assine o WhatsAgenda e tenha todas as funcionalidades PREMIUM!</span>
                    <button class="btn-planos" (click)="goToUsagePlans()" *ngIf="planosAssinaturas">Ver Planos</button>
                  </div>
                  <ng-container *ngIf="companyPaymentStatus === 'pending_payment' || companyPaymentStatus === 'unpaid'">
                    <div class="assinatura1" *ngIf="isPendingPaymentOneDayTolerance(); else elsePaymentPlusOneDayTolerance">
                      <img src="assets/ALERTNOPAY.png" class="imgalert">
                      <h5 class="txt-supp">Seu plano venceu hoje</h5>
                      <span class="txt-planos"> É necessário regularizar o seu plano! Sua assinatura venceu dia: {{assinaturaFim}}</span>
                      <button class="btn-planos" (click)="goToUsagePlans()" *ngIf="planosAssinaturas">PAGAR</button>
                    </div>
                    <ng-template #elsePaymentPlusOneDayTolerance>
                      <img src="assets/ALERTNOPAY.png" class="imgalert">
                      <h5 class="txt-supp">Sua agenda está bloqueada</h5>
                      <span class="txt-planos"> É necessário regularizar o seu plano! Sua assinatura venceu dia: {{assinaturaFim}}</span>
                      <button class="btn-planos" (click)="goToUsagePlans()" *ngIf="planosAssinaturas">PAGAR</button>
                    </ng-template>
                  </ng-container>
              </div>

                  <div class="assinatura2">
                    <h5 class="txt-supp">Suporte</h5>
                    <span class="txt-suporte">Fale pelo WhatsApp com nosso suporte. Horário comercial.</span>
                    <button class="btn-suporte" (click)="openSuporte()">Falar com Suporte</button>
                  </div>


              </div>




        <!-- ############################################################ -->
        <!--                              Page                            -->
        <!-- ############################################################ -->

        <div class="mainContent" id="pageContent">
            <!-- <div class="w-100" *ngIf="globals.showPageMainContent"> -->
              <div *ngIf="globals.showPageMainContent">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

</div>


<!-- -----------------------------------------------------------------------  -->
<!-- Agenda Refresh prompt -->
<!-- -----------------------------------------------------------------------  -->

<div class="refreshPrompt" [@refreshPromptAnimation] *ngIf="globals.showAgendaRefreshPrompt">

    <label style="font-weight: 600; color: white; margin-top: 2%;">Existem novos agendamentos no sistema, deseja ir para a agenda?</label>

    <div style="display: flex; justify-content: space-evenly; margin-top: 5%;">
      <button class="jumpBtn" style="display: inline-block;" (click)="pauseAlarm(); newAgendaRedirect()">Sim</button>
      <button class="jumpBtn" style="display: inline-block; background-color: white; color: black;" (click)="globals.showAgendaRefreshPrompt = false; pauseAlarm()">Não</button>
    </div>

</div>

<div *ngIf="globals.showAlert" [@inOutAnimation] class="mainContainer">
    <div class="modal-header noBorder">
        <h5 class="modal-title msgTitle">{{globals.alert.title}}</h5>
    </div>
    <div class="modal-body noBorder">
        <p>{{globals.alert.message}}</p>
    </div>
    <div class="modal-footer noBorder">
        <button type="button" class="btn blueBtn" (click)="globals.showAlert = false">Ok</button>
    </div>
</div>
