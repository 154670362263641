import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';

import { Config } from '../config';
import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER, NEEDS_USER_BEARER } from 'src/app/interceptors/context-bearer';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoPublService extends BaseService {

  baseURL = `${Config.vWebUrl}agendamento_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAllForClient(company_id, dataIni){
    return this.http.get(this.baseURL + `-1/${company_id}/${dataIni}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  salvarAgendamento(agendamento_array, company_id){
    agendamento_array.forEach(element => {
      element.agendamento_status_id = 1;
    });
    const obj = JSON.stringify({company_id: company_id, agendamento_array: agendamento_array});
    return this.http.post(this.baseURL, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      timeout(70000),  // Configura o timeout para 70 segundos
      map((data: any) => {
        if (data.status !== "200") {  // Verifica o status da resposta
          throw new Error('Erro na resposta da API');
        }

        // Exibe o agendamento_id no console, caso esteja presente
        if (data.result && data.result[0] && data.result[0].agendamento_id) {
          console.log('Agendamento ID:', data.result[0].agendamento_id);
        } else {
          console.warn('Nenhum agendamento_id retornado');
        }

        return data;  // Retorna a resposta completa
      }),
      catchError(this.handleErrors)
    );
  }

  salvarAgendamentoUnpaid(agendamento_array, company_id){
    agendamento_array.forEach(element => {
      element.agendamento_status_id = 100;
    });
    const obj = JSON.stringify({company_id: company_id, agendamento_array: agendamento_array});
    return this.http.post(this.baseURL, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      timeout(70000),  // Configura o timeout para 70 segundos
      map((data: any) => {
        if (data.status !== "200") {  // Verifica o status da resposta
          throw new Error('Erro na resposta da API');
        }

        // Exibe o agendamento_id no console, caso esteja presente
        if (data.result && data.result[0] && data.result[0].agendamento_id) {
          console.log('Agendamento ID:', data.result[0].agendamento_id);
        } else {
          console.warn('Nenhum agendamento_id retornado');
        }

        return data;  // Retorna a resposta completa
      }),
      catchError(this.handleErrors)
    );
  }


  getByAgendamentoId(agendamento_id){
    return this.http.get(this.baseURL + '-2/' + agendamento_id, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    })
    .pipe(
      map((data: any) => {
        //
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  patchConfirmaAgendamento(agendamento_id) {
    const requestBody = {
      agendamento_id: agendamento_id
    };

    return this.http.patch(this.baseURL, requestBody, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, false).set(NEEDS_USER_BEARER, false)
    }).pipe(
      catchError(this.handleErrors)
    );
  }



}
