import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Config } from '../../config';
import { BaseService } from '../base.service';
import { CompanyUtils } from '../company-utils';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoStatusService extends BaseService  {

  baseURL = `${Config.vBusUrl}agendamento_status_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getAll() {
    return this.http.get(this.baseURL + `-1`)
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  patch(agendamento_id, agendamento_status_id) {

    let obj = {
      company_id: CompanyUtils.getCompanyId(),
      agendamento_id: agendamento_id,
      agendamento_status_id: agendamento_status_id
    };

    return this.http.patch(this.baseURL, JSON.stringify(obj))
    .pipe(
        map((data: any) => {
            //
            if (data.status != "200") {
                catchError(this.handleErrors);
            }

            return data.message;
        }),
        catchError(this.handleErrors)
    );
  }

}
