import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'priceBR'})
export class PriceBRPipe implements PipeTransform {
  transform(price: any): string {
    if (typeof price == 'string') {
      price = parseFloat(price);
    }

    else if (price == undefined ){
      price = 0;
    }

    let priceStr = "";
    priceStr = price.toFixed(2).replace('.', ',');
    return priceStr;
  }
}
