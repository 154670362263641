
export class Address {
    street: string;
    street_number: string;
    complementary: string;
    neighborhood: string;
    zipcode: string;
}

export class PhoneAssinatura {
    ddd: string;
    number: string;
}

export class Customer {
    email: string;
    name: string;
    document_number: string;
    address: Address;
    phone: PhoneAssinatura;
}

export class FormAssinatura {
    plan_id: number;
    gateway_id: string;
    company_id: number;
    payment_method: string;
    card_number: string;
    card_holder_name: string;
    card_expiration_date: string;
    card_cvv: string;
    postback_url: string;
    customer: Customer;
    affiliateId: string;
}
