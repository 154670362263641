import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';


import { BaseService } from '../base.service';
import { NEEDS_COMPANY_BEARER } from 'src/app/interceptors/context-bearer';
import { Config } from '../../config';
import { CompanyUtils } from '../company-utils';


@Injectable({
  providedIn: 'root'
})
export class LinksService extends BaseService {

  baseURL = `${Config.vBusUrl}link_tree_edp/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  // Método GET
  getAllLinks(company_id){
    const httpParams = new HttpParams({ fromObject: { company_id } });

    return this.http.get(this.baseURL, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true),
      params: httpParams,
    })
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          throw new Error(data.message || 'Erro ao buscar links');
        }
        return data.result; 
      }),
      catchError(this.handleErrors)
    );
  }

  // Método POST
  createLink(linkData){
    linkData.company_id = CompanyUtils.getCompanyId();
    const obj = JSON.stringify(linkData);

    return this.http.post(this.baseURL, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true)
    })
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          throw new Error(data.message || 'Erro ao criar link');
        }
        return data.result; 
      }),
      catchError(this.handleErrors)
    );
  }

  // Método PATCH
  updateLink(linkId, linkData) {
    const obj = JSON.stringify(linkData);

    return this.http.patch(`${this.baseURL}${linkId}`, obj, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true)
    })
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          throw new Error(data.message || 'Erro ao atualizar link');
        }
        return data.result; 
      }),
      catchError(this.handleErrors)
    );
  }

  // Método DELETE
  deleteLink(linkId){
    return this.http.delete(`${this.baseURL}${linkId}`, {
      context: new HttpContext().set(NEEDS_COMPANY_BEARER, true)
    })
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          throw new Error(data.message || 'Erro ao deletar link');
        }
        return data.result; 
      }),
      catchError(this.handleErrors)
    );
  }
}
