import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../svc/globals';
import { CompanyUser } from '../../svc/company/company-user/CompanyUser';
import { CompanyUtils } from '../../svc/company/company-utils';
import { Md5 } from 'ts-md5/dist/md5';
import { NewPasswordService } from '../../svc/company/new-password/new-password.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-company-new-password',
  templateUrl: './company-new-password.component.html',
  styleUrls: ['./company-new-password.component.scss'],
  providers: [
    NewPasswordService
  ]
})
export class CompanyNewPasswordComponent implements OnInit {

  data = new CompanyUser();
	passAux: string;

  constructor(
    public globals: Globals,
    private profileSvc: NewPasswordService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoaderSvc: NgxUiLoaderService,
	) { }

  ngOnInit() {

    this.globals.headerTitle = 'Alterar senha';

    this.data = new CompanyUser();
    this.passAux = '';
  }

  checkPassword(): boolean {

    if(!this.data.password.match(this.passAux)){
      return false;
    }

    return true;
  }

  checkPassLength(): boolean {
    if(this.data.password.length < 6){
      return false;
    }
    return true;
  }

  change(){

  	if(this.data.password != undefined && this.data.password.length > 0){
      if(!this.checkPassLength()){
        this.globals.openDialog('Atenção!', `A senha deve ter pelo menos 6 caracteres.`);
        return;
      }
  
      if(!this.checkPassword()){
        this.globals.openDialog('Atenção!', `As senhas são diferentes. Por favor repita a senha no campo confirmar senha.`);
        return;
      }
    }

    this.data.company_id = CompanyUtils.getCompanyId();
    this.data.companyuser_id = CompanyUtils.getUserId();

    this.globals.openConfirm('Você deseja realmente alterar sua senha?', () => {
      this.data.password = Md5.hashStr(this.data.password).toString();
      this.ngxLoaderSvc.start();
      this.profileSvc.patch(this.data).subscribe(
        async (message) => {
          this.ngxLoaderSvc.stop();
          this.globals.openDialog('Sucesso!', `Senha alterada com sucesso`);
        },
        async () => {
          this.ngxLoaderSvc.stop();
          this.globals.openDialog('Erro', `Aconteceu um erro em sua requisição. Por favor verifique sua conexão com a internet ou tente novamente mais tarde.`);
        }
      );
    });
  }

  goToProfile(){
    if(this.route.snapshot.routeConfig.path.includes('agenda')){
      this.router.navigateByUrl('/ambiente/agenda/user-profile');
    }
    else{
      this.router.navigateByUrl('/ambiente/catalogo/user-profile');
    }
  }

}
