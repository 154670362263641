import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})

export class Config {

  private static prodFlag = environment.prodFlag;

  // new certificate
  private static prod = environment.CONFIG_API_URL; // 0

  static getRootApiIP(): string {
    return Config.prod;
  }

  private static getVWebApiIp(): string {
    return this.getRootApiIP() + 'api/v_web/';
  }

  private static getVRuralApiIp(): string {
    return this.getRootApiIP() + 'api/v_rur/';
  }

  private static getVBusApiIp(): string {
    return this.getRootApiIP() + 'api/v2_bus/';
  }

  private static getVAppApiIp(): string {
    return this.getRootApiIP() + 'api/v_app/';
  }

  public static vWebUrl = Config.getVWebApiIp();
  public static vRuralUrl = Config.getVRuralApiIp();
  public static vBusUrl = Config.getVBusApiIp();
  public static vAppUrl = Config.getVAppApiIp();

  public static getProdFlag() {
    return this.prodFlag;
  }

}
