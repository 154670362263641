import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDatepickerI18n, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Agendamento } from '../svc/agendamento/agendamento';
import { AgendamentoPublService } from '../svc/agendamento/agendamento-publ.service';
import { Company } from '../svc/company/company';
import { CompanyService } from '../svc/company/company.service';
import { CustomDatepickerI18n, I18n } from '../svc/datepickerI18n/datepicker-i18n';
import { Globals } from '../svc/globals';
import { CompanyAgendamentoSettings } from '../svc/agendamento-settings/CompanyAgendamentoSettings';
import { PrestadorWorkhour } from '../svc/prestador-workhour/PrestadorWorkhour';
import { PrestadorPublService } from '../svc/prestador/prestador-publ.service';
import { ServicoPrestador } from '../svc/servico-prestador/ServicoPrestador';
import { Servico } from '../svc/servico/servico';
import { ServicoPublService } from '../svc/servico/servico-publ.service';
import { OpenSchedule } from './open-schedule';
import * as smoothscroll from "smoothscroll-polyfill";
import { take } from 'rxjs/operators';
import { ServiceCategory } from '../svc/servico/service-category';
import { AgendamentoEspera } from 'src/app/svc/agendamento-espera/agendamento-espera';
import { AgendamentoEsperaService } from 'src/app/svc/agendamento-espera/agendamento-espera.service';
import { AgendamentoSettingsServicePubl } from '../svc/company/agendamento-settings/agendamento-settings-publ';
import { AppuserAgendamentoListService } from '../svc/appuser/appuser-agendamento-list.service';
import { forkJoin, Subscription } from 'rxjs';
import { AgendamentoService } from '../agendamento-conf-new/agendamento.service';
import { CompanyUser } from '../svc/company/company-user/CompanyUser';
import { APP_RUN_MODE, APP_RUN_MODE_DEF } from '../_core';
import { CompanyUtils } from '../svc/company/company-utils';
import { RootStylesService } from '../svc/root-styles/root-styles.service';
import { PaymentOpenpixService } from '../svc/company/payment-openpix/payment-openpix.service';
import { v4 as uuidv4 } from 'uuid';
import { PaymentOpenpixQrcodeService } from '../svc/company/payment-openpix-qrcode/payment-openpix-qrcode.service';


type AgendamentoStatuses = {
  serviceSelected: boolean;
  prestadorSelected: boolean;
  timeSelected: boolean;
}

interface ScheduleCompareObject {
  hour: number,
  mins: number
}


@Component({
  selector: 'app-agendamento-publ-new',
  templateUrl: './agendamento-publ-new.component.html',
  styleUrls: [
    './agendamento-publ-new.component.scss'
  ],
  providers: [
    I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ],
})
export class AgendamentoPublNewComponent implements OnInit {
  private subscription: Subscription;

  wrongHandle: boolean = false;
  whatsapp_phone: string;
  company_handle: string = '';
  company_id: string;
  company: Company;
  companyId: number;
  link_social: string;

  serviceCategoryList: ServiceCategory[] = [];
  servicesLst: Servico[] = [];
  prestadorLst: ServicoPrestador[] = [];
  prestadorLstView: ServicoPrestador[] = [];
  agendamentoAFazer: Agendamento;
  agendamentoAFazerDuracao: string;

  showDatePicker: boolean = false;
  filterDateStart: NgbDateStruct;
  filterDateStartView: string;
  filterDateStartDbFormat: string;

  loadingHorarios: boolean = false;

  prestadorWorkhourLst: PrestadorWorkhour[] = [];

  agendados = Array<Agendamento>();
  agendadosBKP: Agendamento[] = [];

  openSchedule: OpenSchedule[] = [];

  destinationPhone = '';
  whatsLink = '';
  closeOpenTime = undefined;
  servicoDescription: string;
  prestadorDescription: string;

  zoom: number = 17;
  shopStatus: boolean;
  vagasDisponiveis: number;   // this variable is written in each openSchedule selection.
  // If verifies if user can choose the amount of vacancies it want

  preSelectedHour: string;
  preSelectedPrestador: number;
  showNoOpenSchedule = false;

  names = [''];
  namesNgFor = [''];

  unavailableDivHeight: number = 768;
  closeResult = '';
  agendamentoAFazerStatuses: AgendamentoStatuses = {
    serviceSelected: false,
    prestadorSelected: false,
    timeSelected: false,
  };

  isClientLoggedin = false;

  agendamentoCart: Array<Agendamento> = new Array<Agendamento>();
  agendamentoEspera: AgendamentoEspera;

  pixKeyType: string = '';
  pixKey: string = '';
  nomeContaPix: string = '';
  pixValue: number;

  currentTheme: string = 'theme-default';
  settings = new CompanyAgendamentoSettings();

  auxCompanyId: number;
  subscriptionCount: number = 0;
  companyUser: CompanyUser;

  selectedPaymentOption: string | null = null;
  percentage_value_payment: string;
  accept_sign_and_value_total: boolean = false;

  currentAgendamento: any;
  payment_fixed: boolean;
  payment_percentage: boolean;

  selectedPaymentAmount: number;
  qrCodeUrl: any;
  copiaecola: any;
  payment_integration_completed: boolean;

  loadingPayment: boolean = false;

  constructor(
    private companySvc: CompanyService,
    private actRoute: ActivatedRoute,
    private servicoSvc: ServicoPublService,
    private prestadorSvc: PrestadorPublService,
    private agendamentoSvc: AgendamentoPublService,
    private esperaSvc: AgendamentoEsperaService,
    public globals: Globals,
    private refresher: ChangeDetectorRef,
    private modalService: NgbModal,
    private ngxLoaderSvc: NgxUiLoaderService,
    private router: Router,
    private settingsSvcPubl: AgendamentoSettingsServicePubl,
    private appuserAgendamentoSvc: AppuserAgendamentoListService,
    private agendamentoService: AgendamentoService,
    private rootStylesService: RootStylesService,
    private companyPaymentSvc: PaymentOpenpixService,
    private paymentService: PaymentOpenpixQrcodeService,
    private paymentOpenpixService: PaymentOpenpixService,
  ) {
    this.company = new Company();
    this.company.latitude = 0;
    this.company.longitude = 0;
    this.agendamentoAFazer = new Agendamento();
    this.agendamentoEspera = new AgendamentoEspera();

    const d = new Date();
    this.filterDateStart = new NgbDate(d.getFullYear(), d.getMonth()+1, d.getDate());
    this.filterDateStartView = this.dateToBrString(this.filterDateStart);
    this.filterDateStartDbFormat = this.dateToDBString(this.filterDateStart);

    smoothscroll.polyfill();

    // test data
    // this.openSchedule = OpenSchedule.returnMockOpenSchedule();
  }

  ngOnInit() {
    localStorage.removeItem('selectedPaymentAmount');
    this.destinationPhone = localStorage.getItem('companyPhone');
    // console.log('Número de destino carregado:', this.destinationPhone);
    //this.getSettings(); essa função não deve ser chamada pois company_id não esta definido
    this.globals.checkAgenda = false;
    this.company_handle = this.actRoute.snapshot.paramMap.get("cid");
    this.company_id = this.actRoute.snapshot.paramMap.get("cid");

    if(this.actRoute.snapshot.paramMap.get("date") != undefined && this.actRoute.snapshot.paramMap.get("hour") != undefined
        && this.actRoute.snapshot.paramMap.get("pid") != undefined){

      const dateStr = this.actRoute.snapshot.paramMap.get("date");
      this.filterDateStart.year = Number.parseInt(dateStr.substring(0, 4));
      this.filterDateStart.month = Number.parseInt(dateStr.substring(5, 7));
      this.filterDateStart.day = Number.parseInt(dateStr.substring(8));

      this.filterDateStartView = this.dateToBrString(this.filterDateStart);
      this.filterDateStartDbFormat = this.dateToDBString(this.filterDateStart);

      this.preSelectedHour = this.actRoute.snapshot.paramMap.get("hour");
      this.preSelectedPrestador = Number.parseInt(this.actRoute.snapshot.paramMap.get("pid"));
    }

    // param can be a int or string
    const idAux = Number(this.company_handle);
    if (idAux) {
      // to get here, we came from an inside route
      this.getInfosIniciais();
      localStorage.setItem('CompanyId', this.company_id);
    } else {
      this.getCompanyGuestRoomData();
    }

    // document.getElementById('openconfirmsBookingDone').click();

    if(localStorage.getItem('appuser_id') != undefined){
      this.isClientLoggedin = true;
      this.checkIfLoggedUserIsLinkedToCompany();
    }

    const nomeCliente = localStorage.getItem('nomeCliente');
    const telefoneCliente = localStorage.getItem('telefoneCliente');

    // Se os valores existirem no localStorage, atribuí-los ao agendamento atual
    if (nomeCliente) {
      this.agendamentoCart[0].names[0] = nomeCliente;
    }
    if (telefoneCliente) {
      this.agendamentoCart[0].telefone_cliente = telefoneCliente;
  }

  }



  async getSubscriptionCount() {
    this.ngxLoaderSvc.start();
    await this.appuserAgendamentoSvc.asyncGetSubscriptionCount(this.auxCompanyId).then((data) => {
      this.subscriptionCount = data;
      // console.log('SBSCPT COUNT: ', this.subscriptionCount)
      this.ngxLoaderSvc.stop();
    });
  }

  checkIfLoggedUserIsLinkedToCompany(): void {
    this.ngxLoaderSvc.start();
    this.appuserAgendamentoSvc.checkIfUserIsLinkedToCompany().subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        alert("Erro ao trazer informações da empresa");
        return;
      }
    );
  }
  // testeSocialShare() {
  //   window.open("whatsapp://send?phone=+5535998832780&text=Hello");
  // }

  getInfosConfirmAgendamento() {
    // Carregue os dados do localStorage na array agendamentoCart
    const storedAgendamentos = JSON.parse(localStorage.getItem('agendamentoCart') || '[]');
    this.agendamentoCart = storedAgendamentos;
    this.salvarAgendamento();
  }


  // ngOnDestroy() {
  //   // Lembre-se de cancelar a inscrição para evitar vazamentos de memória
  //   this.subscription.unsubscribe();
  // }

  formatWhatsAppNumber(number: string): string {
    if (!number) {
      return '';
    }
    const digitsOnly = number.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    return digitsOnly.startsWith('55') ? digitsOnly : '55' + digitsOnly;
  }

  getCompanyGuestRoomData(): void {
    this.ngxLoaderSvc.start();
    this.companySvc.getGuestRoomData(this.company_handle).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        //
        if (result == null) {
          // not found
          this.wrongHandle = true;
          this.router.navigate(['not-found']);
        } else {
          this.wrongHandle = false;
          this.company_id = result.company_id.toString();
          this.link_social = result.fb_mess_link;
          localStorage.setItem('CompanyId', this.company_id);


          this.getInfosIniciais();
          return;
        }

      },
      (err) => {
        this.ngxLoaderSvc.stop();
        alert("Erro ao trazer informações da empresa");
        return;
      }
    );
  }

  getInfosIniciais(){
    // this function is also called after a sucessful reservation
    this.getCompanyData();
    this.getServiceCategories();
    this.getPrestadores();
    this.getSettings();
    this.getAgendaDoDia();
    this.setRootStyles();

  }

  getSettings(){
    this.ngxLoaderSvc.start();
    this.settingsSvcPubl.getAgendamentoSettings(this.company_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.settings.sends_whats_message = false;
        this.settings.is_login_mandatory = false;
        if(result != undefined && result != null){
          this.settings = result[0];
          if(this.settings.pix_info != undefined){
            const infoArray = this.settings.pix_info.split(';');
            this.pixKeyType = infoArray[0];
            this.pixKey = infoArray[1];
            this.nomeContaPix = infoArray[2];
          }
          if(this.settings.price_perc != undefined){
            this.settings.price_perc *= 100;
          }
        }
        // // console.log(this.settings);
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        // console.log(err);
        this.globals.openDialog('Erro', 'Não foi possivel buscar as informações de configurações de agendamento');
      }
    );
  }

  getCompanyData() {
    // Iniciar carregamento
    this.ngxLoaderSvc.start();

    // Executa ambas as chamadas de API em paralelo
    forkJoin({
      companyData: this.companySvc.getAgendamentoCompData(this.company_id),
      paymentData: this.companyPaymentSvc.getCompanyPaymentOpenpix(this.company_id)
    }).subscribe(
      ({ companyData, paymentData }) => {
        this.ngxLoaderSvc.stop();

        // Processar dados da empresa
        this.company = companyData;
        this.auxCompanyId = companyData.company_id;
        this.getSubscriptionCount();

        if (this.company.tempo_agendar_antecedencia != undefined) {
          this.company.tempo_agendar_antecedencia *= 3600000;
        }

        if (this.company.is_login_mandatory && localStorage.getItem('appuser_id') == undefined) {
          this.router.navigateByUrl('client/login');
          return;
        }

        if (this.company.pix_enable) {
          const pixInfoArray = this.company.pix_info.split(';');
          this.pixKeyType = pixInfoArray[0];
          this.pixKey = pixInfoArray[1];
          this.nomeContaPix = pixInfoArray[2];
        }

        try {
          this.company.latitude = Number.parseFloat(this.company.latitude.toString());
          this.company.longitude = Number.parseFloat(this.company.longitude.toString());
        } catch (e) {
          this.company.latitude = 0;
          this.company.longitude = 0;
        }

        this.destinationPhone = '+55' + this.company.whatsapp_phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
        localStorage.setItem('companyPhone', this.destinationPhone);

        this.currentTheme = !this.company.linkThemeName ? 'theme-default' : this.company.linkThemeName;

        // Processar dados de pagamento
        if (paymentData.status === '200') {
          const paymentResponse = paymentData.result.length > 0 ? paymentData.result[0] : paymentData;

          this.accept_sign_and_value_total = paymentResponse.accept_sign_and_value_total;
          this.percentage_value_payment = paymentResponse.percentage_value_payment;
          this.payment_integration_completed = paymentResponse.payment_integration_completed;
          this.payment_fixed = paymentResponse.payment_fixed;
          this.payment_percentage = paymentResponse.payment_percentage;

          localStorage.setItem('payment_integration_completed', this.payment_integration_completed ? 'true' : 'false');

          // Forçar detecção de mudanças
          this.refresher.detectChanges();
        } else {
          console.warn('Erro retornado pela API de pagamento:', paymentData.message);
        }

        this.checkTodayWorkhour();
        this.updateUnavailableDivHeight();
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        console.error('Erro ao obter dados:', err);
        alert("Não foi possível carregar os dados da empresa ou de pagamento.");
      }
    );
  }


  setRootStyles() {
    const styleObj = CompanyUtils.getPageTheme();
    this.rootStylesService.setRootStyles(styleObj);
  }

  getServiceCategories(){
    this.ngxLoaderSvc.start();
    this.servicoSvc.getAllServiceCategoriesLink01(this.company_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();

        this.serviceCategoryList = result;
        this.serviceCategoryList.forEach((categItem) => {
          categItem.imgUrl = categItem.imgUrl ? categItem.imgUrl : '../../assets/default_image.png';

          categItem.serviceList.forEach((item) => {
            item.selected = false;
            item.image_link = item.image_link ? item.image_link : '../../assets/default_image.png';
          });
        });

      },
      (err) => {
        this.ngxLoaderSvc.stop();
        // console.log(err);
        alert("Erro ao trazer serviços");
      }
    );
  }

  getPrestadores(){
    this.ngxLoaderSvc.start();
    this.prestadorSvc.getPrestadorWithServiceIdLst(this.company_id).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.prestadorLst = result;
      },
      (err) => {
        this.ngxLoaderSvc.stop();
        // console.log(err);
        alert("Erro ao trazer prestadores");
      }
    );
  }

  onServicoSelected(serviceCategory: ServiceCategory, servicoId: number){
    // clear selected prestador option
    this.agendamentoAFazer.prestador_id = 0;
    this.openSchedule = [];
    this.agendamentoAFazerStatuses.prestadorSelected = false;
    this.agendamentoAFazerStatuses.timeSelected = false;

    this.prestadorLstView.forEach(p => {
      p.selected = false;
    });

    // clear service selected = true
    this.serviceCategoryList.forEach((categItem) => {
      categItem.serviceList.forEach((item) => {
        item.selected = false;
      });
    });

    // filter prestadores based on serviceId
    this.agendamentoAFazer.servico_id = servicoId;
    this.prestadorLstView = this.prestadorLst.filter( el => {
      if (el.servico_id == this.agendamentoAFazer.servico_id) {
        el.image_link = el.image_link ? el.image_link : '../../assets/default_image.png';
        return true;
      }
    });

    // set descricao servico
    serviceCategory.serviceList.forEach((servico) => {
      if (this.agendamentoAFazer.servico_id == servico.servico_id) {
        this.servicoDescription = servico.description;
        servico.selected = true;
      } else {
        servico.selected = false;
      }
    });

    if(this.preSelectedPrestador != undefined){
      this.agendamentoAFazer.prestador_id = this.preSelectedPrestador;
      this.preSelectedPrestador = undefined;
      this.onPrestadorSelected(this.agendamentoAFazer.prestador_id);
    }
    this.agendamentoAFazerStatuses.serviceSelected = true;
  }

  onPrestadorSelected(prestador_id: number){
    this.agendamentoAFazer.prestador_id = prestador_id;
    // filtra agendados baseado em prestador_id
    this.agendados = this.agendadosBKP.filter( (ag) => ag.prestador_id == this.agendamentoAFazer.prestador_id);

    this.openSchedule = [];
    this.agendamentoAFazerStatuses.prestadorSelected = false;
    this.agendamentoAFazerStatuses.timeSelected = false;

    this.prestadorLstView.forEach(p => {
      if(p.prestador_id == this.agendamentoAFazer.prestador_id){
        this.agendamentoAFazerStatuses.prestadorSelected = true;
        this.prestadorDescription = p.description;

        const spaceRegex = /\s/g;
        const leftParenthesesRegex = /\(/g;
        const rightParenthesesRegex = /\)/g;
        const traceRegex = /-/g;

        if(!!p.phone && p.phone.length > 4){
          this.destinationPhone = '+55' + p.phone
            .replace(spaceRegex, '')
            .replace(leftParenthesesRegex, '')
            .replace(rightParenthesesRegex, '')
            .replace(traceRegex, '');
        }
        else {
          this.destinationPhone = '+55' + this.company.whatsapp_phone
            .replace(spaceRegex, '')
            .replace(leftParenthesesRegex, '')
            .replace(rightParenthesesRegex, '')
            .replace(traceRegex, '');
        }

        p.selected = true;
      } else {
        p.selected = false;
      }
    });

    // query prestador workhour
    this.getPrestadorWorkhour();

    const servicoDetails = this.prestadorLst.filter(p => {
      return p.servico_id == this.agendamentoAFazer.servico_id && p.prestador_id == this.agendamentoAFazer.prestador_id;
    })[0];

    this.agendamentoAFazer.preco_mode = servicoDetails.preco_mode ? servicoDetails.preco_mode : 1;
    this.agendamentoAFazer.preco = servicoDetails.preco;

    const duracaoInfo = servicoDetails.duracao?.split(';');
    if(duracaoInfo[1] == 'm'){
      this.agendamentoAFazer.duracao = duracaoInfo[0] + ' min';
      this.agendamentoAFazerDuracao = this.formatarDuracao(this.agendamentoAFazer.duracao);
    }
    else{
      this.agendamentoAFazer.duracao = duracaoInfo[0] + 'h';
    }
    // console.log(this.agendamentoAFazer)
  }

  formatarDuracao(duracaoEmString: string): string {
    // Extrai o número de minutos da string
    const duracaoEmMinutos = parseInt(duracaoEmString);

    // Calcula horas e minutos
    const horas = Math.floor(duracaoEmMinutos / 60);
    const minutos = duracaoEmMinutos % 60;

    let duracaoFormatada = '';

    // Formata a string de duração
    if (horas > 0) {
      duracaoFormatada += `${horas}h`;
    }

    if (minutos > 0) {
      duracaoFormatada += ` ${minutos}m`;
    }

    return duracaoFormatada.trim();
  }

  getPrestadorWorkhour(){
    // this.ngxLoaderSvc.start();
    this.toggleLoadingHorarios();
    this.prestadorSvc.getPrestadorWorkhoursOfDay(this.agendamentoAFazer.prestador_id, this.filterDateStartDbFormat).subscribe(
      (result) => {
        // this.ngxLoaderSvc.stop();
        this.toggleLoadingHorarios();
        this.prestadorWorkhourLst = result;
        this.generateOpenSchedule();
      },
      (error) => {
        // this.ngxLoaderSvc.stop();
        this.toggleLoadingHorarios();
        // console.log(error);
        alert('Erro ao buscar os horários de atendimento.');
      }
    );
  }

  getAgendaDoDia(){
    this.ngxLoaderSvc.start();
    this.agendamentoSvc.getAllForClient(this.company_id, this.filterDateStartDbFormat).subscribe(
      (result) => {
        this.ngxLoaderSvc.stop();
        this.agendados = result;

        this.agendamentoCart.forEach(a => {
          if(a.data_agendamento == this.filterDateStartDbFormat){
            this.agendados.push(a);
          }
        });

        this.agendadosBKP = result;

        if(this.agendamentoAFazer.servico_id > 0 && this.agendamentoAFazer.prestador_id > 0){
          this.getPrestadorWorkhour();
        }
      },
      (error) => {
        this.ngxLoaderSvc.stop();
        // console.log(error);
        alert("Erro ao trazer agenda do dia.");
      }
    );
  }

  dateToDBString(d: NgbDateStruct): string{
    return d.year + '-' + (d.month < 10? ('0' + d.month) : d.month) + '-' + (d.day < 10? ('0' + d.day) : d.day);
  }

  dateToBrString(d: NgbDateStruct): string{
    return (d.day < 10? ('0' + d.day) : d.day) + '/' + (d.month < 10? ('0' + d.month) : d.month) + '/' + d.year;
  }

  toggleShowDatepicker(){
    this.showDatePicker = !this.showDatePicker;

    if(!this.showDatePicker){
      this.filterDateStartView = this.dateToBrString(this.filterDateStart);
      this.filterDateStartDbFormat = this.dateToDBString(this.filterDateStart);

      // clear
      this.openSchedule = [];
      this.agendamentoAFazerStatuses.timeSelected = false;

      this.getAgendaDoDia();
    }
  }

  toggleLoadingHorarios(){
    this.loadingHorarios = !this.loadingHorarios;
  }

  escolheHorario(horarioEscolhido: OpenSchedule){
    // deselect others
    this.openSchedule.forEach((element) => {element.selected = false});

    // set selected
    horarioEscolhido.selected = true;

    // set agendamentoAFazer
    this.agendamentoAFazer.time_start = horarioEscolhido.openScheduleStr;

    // set end time of agendamentoAFazer
    this.computeEndTimeOfAgendamento(horarioEscolhido);

    this.agendamentoAFazer.data_agendamento = this.filterDateStartDbFormat;
    this.agendamentoAFazer.data_agendamentoView = this.filterDateStartView;

    this.vagasDisponiveis = horarioEscolhido.vagas;

    this.agendamentoAFazerStatuses.timeSelected = true;

    setTimeout(() => {
      document.getElementById('avanca1Div').scrollIntoView({behavior: "smooth"});
    }, 300);
  }

  computeEndTimeOfAgendamento(horarioEscolhido: OpenSchedule){
    // get task duration from servicoPrestador
    this.prestadorLstView.forEach( (itemPrestador) => {
      if (itemPrestador.prestador_id == this.agendamentoAFazer.prestador_id) {
        // time object
        const horaInicioObj = this.makeTimeObj(this.agendamentoAFazer.time_start);
        const horaFimObj = this.addTime(horaInicioObj, itemPrestador.duracao);
        const horaFimStr = this.toHourStr(horaFimObj);

        this.agendamentoAFazer.time_end = horaFimStr;

        // aproveitar a funcao para pegar outros atributos
        this.agendamentoAFazer.preco = itemPrestador.preco;
        this.agendamentoAFazer.servico_name = itemPrestador.servicoName;
        this.agendamentoAFazer.prestador_name = itemPrestador.prestadorName;
        this.agendamentoAFazer.preco_mode = itemPrestador.preco_mode;

      }
    });
  }

  avancaParaConfirmar(content) {
    if (!this.isServicoValid() || !this.isPrestadorValid() || !this.isDateValid()) {
      return;
    }

    let choosedHorario = false;
    this.openSchedule.forEach((element) => {
      if (element.selected == true) {
        choosedHorario = true;
      }
    });
    if (!choosedHorario) {
      alert("Por favor selecione um horário");
      return;
    }

    // Adicionar ao carrinho antes de abrir o modal
    this.addToCart();

    // Carregar o nome e telefone do localStorage
    const nomeCliente = localStorage.getItem('nomeCliente');
    const telefoneCliente = localStorage.getItem('telefoneCliente');

    // Certifique-se de que existe um item no agendamentoCart
    if (this.agendamentoCart.length > 0) {
      if (nomeCliente) {
        this.agendamentoCart[0].names[0] = nomeCliente;
      }
      if (telefoneCliente) {
        this.agendamentoCart[0].telefone_cliente = telefoneCliente;
      }
    }

    // Abrir o modal e exibir os valores carregados
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // Lógica de fechamento do modal
    });
  }


  agendarMais(content) {

    if(!this.isServicoValid() || !this.isPrestadorValid() || !this.isDateValid()){
      return;
    }

    let choosedHorario = false;
    this.openSchedule.forEach((element) => {
      if (element.selected == true) {
        choosedHorario = true;
      }
    });
    if (!choosedHorario) {
      alert("Por favor selecione um horário");
      return;
    }

    this.addToCart();
    // everything ok, open confirmation modal
    // document.getElementById("openConfirmacaoAgendModal").click();
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {

    // });

     this.router.navigate([this.company_id]).then(() => {
    location.reload(); // Recarrega a página após a navegação
  });
  }

  updateNames(a: Agendamento){

    while(a.names.length < a.vagas){
      a.names.push('');
      a.namesNgFor.push('');
    }

    while(a.names.length > a.vagas){
      a.names.pop();
      a.namesNgFor.pop();
    }
  }

  salvarAgendamento() {
    if (!this.checkAgendamentoObject()) {
      return;
    }

    // Salvar nome e telefone no localStorage
    const nomeCliente = this.agendamentoCart[0]?.names[0];
    const telefoneCliente = this.agendamentoCart[0]?.telefone_cliente;
    if (nomeCliente && telefoneCliente) {
      localStorage.setItem('nomeCliente', nomeCliente);
      localStorage.setItem('telefoneCliente', telefoneCliente);
    }

    this.agendamentoCart.forEach(a => {
      a.preco = a.preco * a.vagas;
    });

    // Salvar POST
    this.ngxLoaderSvc.start();
    this.agendamentoSvc.salvarAgendamento(this.agendamentoCart, this.company_id).pipe(take(1)).subscribe({
    next: (result) => {
        this.ngxLoaderSvc.stop();
        document.getElementById("dismissContentModal").click();

        // Verifica se a empresa envia mensagens no WhatsApp
        if (this.company.sends_whats_message) {
            this.createAgendamentoMessage();

            // Calcula o valor de pagamento
            const paymentValue = this.calcPixValue();
            // console.log("Valor calculado do pagamento:", paymentValue);

            // Verifica o valor de paymentValue e abre o modal apropriado
            if (this.payment_integration_completed === true && paymentValue > 0) {
                // console.log("Integração completa.");
                this.openEnvioMensagemModalCheckout();
            } else {
                // console.log("Integração falsa, modal sem checkout.");
                this.openEnvioMensagemModal();
            }

            this.agendamentoCart = new Array<Agendamento>();
            this.resetVars();
        } else {
            // console.log("A empresa não envia mensagens no WhatsApp, abrindo modal de confirmação...");
            this.openConfirmsBookingModal();
            this.agendamentoCart = new Array<Agendamento>();
            this.resetVars();
        }
    },
    error: (err) => {
        this.ngxLoaderSvc.stop();
        // console.log("Erro ao salvar o agendamento:", err);
        document.getElementById("dismissContentModal").click();
        alert(err);
        this.router.navigate([this.company_id]).then(() => {
            // console.log("Recarregando a página após erro.");
            location.reload();
        });
    }
});

  }

  /**
   * Saves a pre-agendamento so openpix postback can update its status in our API
   * @returns Promise<boolean>
   */
  salvarPreAgendamento_StillUnpaid(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.checkAgendamentoObject()) {
        reject(false);
        return;
      }

      // Salvar nome e telefone no localStorage
      const nomeCliente = this.agendamentoCart[0]?.names[0];
      const telefoneCliente = this.agendamentoCart[0]?.telefone_cliente;
      if (nomeCliente && telefoneCliente) {
        localStorage.setItem('nomeCliente', nomeCliente);
        localStorage.setItem('telefoneCliente', telefoneCliente);
      }

      this.agendamentoCart.forEach(a => {
        a.preco = a.preco * a.vagas;
      });

      this.ngxLoaderSvc.start();
      this.agendamentoSvc.salvarAgendamentoUnpaid(this.agendamentoCart, this.company_id).pipe(take(1)).subscribe({
        next: (result) => {
          this.ngxLoaderSvc.stop();
          // document.getElementById("dismissContentModal").click();

          // Verifica se a empresa envia mensagens no WhatsApp
          // if (this.company.sends_whats_message) {
          //     this.createAgendamentoMessage();

          //     // Calcula o valor de pagamento
          //     const paymentValue = this.calcPixValue();
          //     // console.log("Valor calculado do pagamento:", paymentValue);

          //     // Verifica o valor de paymentValue e abre o modal apropriado
          //     if (this.payment_integration_completed === true && paymentValue > 0) {
          //         // console.log("Integração completa.");
          //         this.openEnvioMensagemModalCheckout();
          //     } else {
          //         // console.log("Integração falsa, modal sem checkout.");
          //         this.openEnvioMensagemModal();
          //     }

          //     this.agendamentoCart = new Array<Agendamento>();
          //     this.resetVars();
          // } else {
          //     // console.log("A empresa não envia mensagens no WhatsApp, abrindo modal de confirmação...");
          //     this.openConfirmsBookingModal();
          //     this.agendamentoCart = new Array<Agendamento>();
          //     this.resetVars();
          // }

          resolve(true);
        },
        error: (err) => {
          this.ngxLoaderSvc.stop();
          // console.log("Erro ao salvar o agendamento:", err);
          // document.getElementById("dismissContentModal").click();
          alert(err);
          resolve(false);
          this.router.navigate([this.company_id]).then(() => {
            // console.log("Recarregando a página após erro.");
            // TODO: replace reload by variables reset
            location.reload();
          });
        }
      });
    });
  }


  openPaymentModal(content: any, a: any) {
    if (!a) {
      console.error('Objeto de agendamento é indefinido. Verifique o contexto do botão.');
      return;
    }

    // Verifique se os dados de pagamento foram carregados corretamente
    if (this.payment_fixed === undefined || this.payment_percentage === undefined) {
      console.error('Dados de pagamento não estão disponíveis. Aguarde o carregamento completo.');
      return;
    }

    this.currentAgendamento = a;
    this.modalService.open(content, { centered: true });
  }



  proceedToPayment(content: any): void {

    const uid_payment = Date.now(); // Utilizando timestamp como ID único
    sessionStorage.setItem('paymentUid', uid_payment.toString());

        // console.log('UID gerado e salvo na sessão:', uid_payment);
    // save first a 'pre-agendamento'
    this.salvarPreAgendamento_StillUnpaid().then(
      (promiseRes) => {
        this.loadingPayment = true;  // Ativar o estado de carregamento

        // Verifica se a opção selecionada é para pagamento total ou sinal
        if (this.selectedPaymentOption === 'total') {
          // Usa o valor total calculado (pixValue) para pagamento total
          this.selectedPaymentAmount = this.pixValue;
        } else if (this.selectedPaymentOption === 'sinal') {
          const paymentValue = parseFloat(this.percentage_value_payment);
          if (this.payment_fixed) {
            // Caso o pagamento seja um valor fixo, utiliza diretamente
            this.selectedPaymentAmount = paymentValue;
          } else {
            // Se não for fixo, aplica a porcentagem calculada sobre o valor total (pixValue)
            this.selectedPaymentAmount = this.pixValue * (paymentValue / 100);
          }
        }

        // Armazenar selectedPaymentAmount no localStorage
        localStorage.setItem('selectedPaymentAmount', this.selectedPaymentAmount.toString());
        // console.log('Valor de pagamento armazenado no localStorage:', this.selectedPaymentAmount);

        const pix_key_recipient = this.company.pix_payment_key;  // A chave PIX da empresa
        const value = Math.round(this.selectedPaymentAmount * 100);  // Multiplicado por 100 para enviar valores em centavos
        const storedSchedulingId = Number(sessionStorage.getItem('paymentUid')); // Recupera o UID da sessão e converte para número

        // Verifique se o UID está sendo recuperado corretamente
        // console.log('UID recuperado da sessão:', storedSchedulingId);

        // Chamada para o serviço de pagamento
        this.paymentService.makePaymentRequest(pix_key_recipient, value, storedSchedulingId).subscribe(
          (response) => {
            // console.log('Resposta do pagamento:', response);
            this.qrCodeUrl = response.payment.charge.qrCodeImage;
            this.copiaecola = response.payment.charge.brCode;

            this.loadingPayment = false;  // Desativa o estado de carregamento após o sucesso

            // Abre o modal com o QR code
            this.modalService.open(content, { centered: true });

            // Verifica o status do pagamento
            this.checkPaymentStatus(this.company.company_id, storedSchedulingId, content);
          },
          (error) => {
            this.loadingPayment = false;  // Desativa o estado de carregamento em caso de erro
            console.error('Erro ao criar o pagamento:', error);
            alert(`Erro ao criar o pagamento: ${error.error.message}`);
          }
        );
      }
    ).catch((reason) => {
      console.log('Didnt save payment', reason);
      return;
    });

  }


  checkPaymentStatus(companyId: number, uid_payment: number, modal: any) {
    const intervalId = setInterval(() => {
      this.companyPaymentSvc.getPaymentStatus(companyId, uid_payment).subscribe(
        (statusResponse) => {
          // console.log('Status do pagamento:', statusResponse);

          if (statusResponse && statusResponse.status === 'paid') {
            // TODO: update agendamento AS 'Pendente'

            // console.log('Pagamento realizado com sucesso!');
            clearInterval(intervalId);
            this.modalService.dismissAll(modal);

            // Verifica se a empresa envia mensagens no WhatsApp
            if (this.company.sends_whats_message) {
                this.createAgendamentoMessage();

                // Calcula o valor de pagamento
                const paymentValue = this.calcPixValue();
                // console.log("Valor calculado do pagamento:", paymentValue);

                // Verifica o valor de paymentValue e abre o modal apropriado
                if (this.payment_integration_completed === true && paymentValue > 0) {
                    // console.log("Integração completa.");
                    this.openEnvioMensagemModalCheckout();
                } else {
                    // console.log("Integração falsa, modal sem checkout.");
                    this.openEnvioMensagemModal();
                }

                this.resetVars();
            } else {
                // console.log("A empresa não envia mensagens no WhatsApp, abrindo modal de confirmação...");
                this.openConfirmsBookingModal();
                this.agendamentoCart = new Array<Agendamento>();
                this.resetVars();
            }

          } else if (statusResponse && statusResponse.status === 'failed') {
            // console.log('Pagamento falhou.');
            clearInterval(intervalId);
            this.modalService.dismissAll(modal);
          }
        },
        (error) => {
          console.error('Erro ao verificar o status do pagamento:', error);
        }
      );
    }, 5000); // 5000 milissegundos = 5 segundos
  }


  openEnvioMensagemModal(){
    document.getElementById('openEnviaMensagemWhats').click();
  }

  openEnvioMensagemModalCheckout(){
    document.getElementById('openEnviaMensagemWhatsCheckout').click();
  }

  openConfirmsBookingModal() {
    document.getElementById('openconfirmsBookingDone').click();
  }

  copyToClipboard() {
    const copyText = document.getElementById('pixCopy') as HTMLInputElement;
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999); // Para dispositivos móveis
      document.execCommand('copy');
      // console.log('Texto copiado:', copyText.value);
    }
  }


  createAgendamentoMessage() {
    let today = new Date();
    let todayStruct: NgbDateStruct = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
    let agendamentoMessage = `Olá ${this.company.nome_fantasia}, gostaria de realizar um agendamento:\n`;

    // Recupera o valor de selectedPaymentAmount do localStorage
    const selectedPaymentAmount = localStorage.getItem('selectedPaymentAmount');
    const payment_integration_completed = localStorage.getItem('payment_integration_completed');

    this.agendamentoCart.forEach(a => {
      agendamentoMessage = agendamentoMessage.concat('\n');
      agendamentoMessage = agendamentoMessage.concat(this.getMessageObservationString(a.obs), '\n');
      agendamentoMessage = agendamentoMessage.concat('Pedido feito no dia ' + this.dateToBrString(todayStruct) + '\n\n');
      agendamentoMessage = agendamentoMessage.concat('Eu marquei um horário para:\n');
      agendamentoMessage = agendamentoMessage.concat(this.getMessageServiceString(a.servico_name));
      agendamentoMessage = agendamentoMessage.concat(this.getMessageProfessionalString(a.prestador_name));
      agendamentoMessage = agendamentoMessage.concat(this.getMessageDayString(a.data_agendamentoView, a.time_start));
      agendamentoMessage = agendamentoMessage.concat(this.getMessagePriceString(a.preco_mode, a.preco));
      agendamentoMessage = agendamentoMessage.concat(this.getMessageClientString(a.nome_cliente));
      agendamentoMessage = agendamentoMessage.concat(this.getMessagePhoneString(a.telefone_cliente));

      // Passa o valor recuperado para o método getPaymentValueString
      if (selectedPaymentAmount && payment_integration_completed) {
        agendamentoMessage = agendamentoMessage.concat(this.getPaymentValueString(selectedPaymentAmount));
      }

      agendamentoMessage = agendamentoMessage.concat(
        this.getMessageFlexibleFieldString(a.campo_flexivel_label, a.campo_flexivel)
      );

      agendamentoMessage = agendamentoMessage.concat('_______\n');
    });

    agendamentoMessage = agendamentoMessage.concat(this.getMessageEndString());

    this.buildWhatsLink(agendamentoMessage);
    // Remove o valor do localStorage após utilizá-lo
    // console.log('Valor de pagamento removido do localStorage.');
  }



  createWaitListMessage(){
    let waitListMessage = `Olá ${this.company.nome_fantasia}, gostaria de entrar na *LISTA DE ESPERA*':\n`;

    const tmpList = [this.agendamentoEspera];
    tmpList.forEach(a => {

      waitListMessage = waitListMessage.concat('\n');

      waitListMessage = waitListMessage.concat(this.getMessageServiceString(a.servico_name));
      waitListMessage = waitListMessage.concat(this.getMessageProfessionalString(a.prestador_name));
      waitListMessage = waitListMessage.concat(this.getMessageDayString(a.data_agendamentoView, undefined));
      waitListMessage = waitListMessage.concat(this.getMessagePriceString(a.preco_mode, a.preco));

      waitListMessage = waitListMessage.concat(this.getMessageClientString(a.nome_cliente));
      waitListMessage = waitListMessage.concat(this.getMessagePhoneString(a.telefone_cliente));
      // waitListMessage = waitListMessage.concat(this.getMessageObservationString(a.obs));
      waitListMessage = waitListMessage.concat(
        this.getMessageFlexibleFieldString(a.campo_flexivel_label, a.campo_flexivel)
      );

      waitListMessage = waitListMessage.concat('_______\n');

    });

    waitListMessage = waitListMessage.concat(this.getMessageEndString());

    this.buildWhatsLink(waitListMessage);
  }

  sendWhatsappMessage(){
    window.open(this.whatsLink);
    document.getElementById("dismissEnviaMensagemWhats").click();
    this.showOkAndResetVars();
  }

  sendWhatsappMessageCheckout() {
    // Verifica se o número de destino foi definido corretamente
    if (!this.destinationPhone) {
        console.error("Destination phone está vazio ou indefinido.");
        return;
    }

    this.createAgendamentoMessage();


    // console.log("WhatsApp Link:", this.whatsLink);

    // Abre o link do WhatsApp, se ele foi corretamente gerado
    if (this.whatsLink && (this.whatsLink.startsWith('https://web.whatsapp.com') || this.whatsLink.startsWith('whatsapp://'))) {
        window.open(this.whatsLink);
        // console.log("Mensagem enviada via WhatsApp.");
    } else {
        console.error("WhatsApp Link não foi gerado corretamente.");
    }

    // Fecha o modal e reseta as variáveis
    document.getElementById("dismissEnviaMensagemWhatsCheckout").click();
    this.showOkAndResetVars();
    this.agendamentoCart = new Array<Agendamento>();
  }


  finalizeBooking() {
    document.getElementById("dismissconfirmsBookingDone").click();
    this.agendamentoAFazer = new Agendamento();
    this.resetVars();
  }

  showOkAndResetVars(){
    // this.globals.openDialog("Ok!", "Agendamento criado com sucesso!");
    this.agendamentoAFazer = new Agendamento();
    this.resetVars();
  }

  resetVars(){
    this.openSchedule = [];
    this.servicoDescription = '';
    this.getInfosIniciais();
  }

  /**
   *
   * @param timeStr in the format 00:00
   * @returns {hour: hora, mins: minuto}
   */
  makeTimeObj(timeStr: string): ScheduleCompareObject {
    const hora = parseInt(timeStr.substring(0,2));
    const minuto = parseInt(timeStr.substring(3));
    return {hour: hora, mins: minuto};
  }

  toHourStr(time): string{

    let hStr;
    let mStr;

    if(time.hour < 10){
      hStr = '0' + time.hour;
    }
    else{
      hStr = time.hour;
    }

    if(time.mins < 10){
      mStr = '0' + time.mins;
    }
    else {
      mStr = time.mins;
    }

    return hStr + ':' + mStr;

  }

  isNormalWorkhour(h: PrestadorWorkhour): boolean{
    const start = this.makeTimeObj(h.start_time);
    const end = this.makeTimeObj(h.end_time);

    return start.hour < end.hour;
  }

  isInBetween(workhour: PrestadorWorkhour, slot): boolean{
    const start = this.makeTimeObj(workhour.start_time);
    const end = this.makeTimeObj(workhour.end_time);

    if(this.isNormalWorkhour(workhour)){

      return this.isGreaterEqualTime(slot, start) && this.isGreaterTime(end, slot);

    }
    else {
      return this.isGreaterEqualTime(slot, start) || this.isGreaterTime(end, slot);
    }

  }

  addTime(time, increment: string): ScheduleCompareObject{

    const auxTime = {hour: time.hour, mins: time.mins};

    const info = increment.split(';');

    if(info[1] == 'm'){

      auxTime.mins += parseInt(info[0]);

    }
    else {
      auxTime.hour += parseInt(info[0]);
    }

    while(auxTime.mins > 59){
      auxTime.hour++;
      auxTime.mins -= 60;
    }

    while(auxTime.hour > 23){
      auxTime.hour -= 24;
    }

    return auxTime;

  }

  isGreaterTime(a: ScheduleCompareObject, b: ScheduleCompareObject): boolean {

    if(a.hour > b.hour){
      return true;
    }
    else {
      return a.hour === b.hour && a.mins > b.mins;
    }

  }

  isGreaterEqualTime(a: ScheduleCompareObject, b: ScheduleCompareObject): boolean {

    if(a.hour > b.hour){
      return true;
    }
    else {
      return a.hour === b.hour && a.mins >= b.mins;
    }

  }

  isLessTime(a: ScheduleCompareObject, b: ScheduleCompareObject): boolean {
    if (a.hour < b.hour) {
        return true;
    } else {
        return a.hour === b.hour && a.mins < b.mins;
    }
  }

  isLessEqualTime(a: ScheduleCompareObject, b: ScheduleCompareObject): boolean {
    if (a.hour < b.hour) {
        return true;
    } else {
        return a.hour === b.hour && a.mins <= b.mins;
    }
  }


  generateOpenSchedule(){
    let maxSlots;
    this.openSchedule = [];

    if(new Date(this.filterDateStart.year, this.filterDateStart.month-1, this.filterDateStart.day, 23, 59, 59).getTime() < new Date().getTime()){
      this.showNoOpenSchedule = true;
      return;
    }

    const servicoDetails = this.prestadorLst.filter(p => {
      return p.servico_id == this.agendamentoAFazer.servico_id && p.prestador_id == this.agendamentoAFazer.prestador_id;
    })[0];

    const whs = this.prestadorWorkhourLst;

    if(this.settings.intervalo_agendamento == "optimizado" || this.settings.intervalo_agendamento == null || this.settings.intervalo_agendamento == undefined){
      const duracaoInfo = servicoDetails.duracao.split(';');
      if(duracaoInfo[1] == 'm'){
        maxSlots = 24*60 / Number.parseInt(duracaoInfo[0]);
      }
      else{
        maxSlots = 24 / Number.parseInt(duracaoInfo[0]);
      }
    } else {
      maxSlots = 24*60 / Number.parseInt(this.settings.intervalo_agendamento);
    }

    const slots: ScheduleCompareObject[] = [];
    whs.forEach(h => {
      // perstador workhour. Ex: 08:00 to 12:00 then 13:00 to 19:00
      let slot = this.makeTimeObj(h.start_time);
      const end = this.makeTimeObj(h.end_time);

      while(this.isInBetween(h, slot) && this.isGreaterEqualTime(end, this.addTime(slot, servicoDetails.duracao)) && slots.length < maxSlots){
        const dateSlot = new Date(this.filterDateStart.year, this.filterDateStart.month-1, this.filterDateStart.day, slot.hour, slot.mins);

        if(this.company.tempo_agendar_antecedencia != undefined ?
            ( (dateSlot.getTime() - this.company.tempo_agendar_antecedencia) > new Date().getTime() ) : ( dateSlot.getTime() > new Date().getTime() )
        ){
          slots.push({hour: slot.hour, mins: slot.mins});
        }
        else {
          maxSlots--;
        }

        slot = this.addTime(slot, this.settings.intervalo_agendamento == "optimizado" ? servicoDetails.duracao : (this.settings.intervalo_agendamento+';m'));
      }

    });

    // // console.log('servicoDetails.vacations', servicoDetails.vacations);

    // slots are each of the already generated time slots
    slots.forEach((slot, i) => {

      //CHECK RECESSO
      //=========================================
      let onVacation = false;
      const slotTimestamp = Number.parseInt(
        this.getFilterDateStartComponents()
        + (slot.hour > 9 ? slot.hour.toString() : ('0' + slot.hour.toString()))
        + (slot.mins > 9 ? slot.mins.toString() : ('0' + slot.mins.toString()))
      );
      // // console.log('timestamp', slotTimestamp);

      servicoDetails.vacations.forEach(vacationItem => {

        vacationItem.start_timestamp = Number.parseInt(vacationItem.start_timestamp.toString());
        vacationItem.end_timestamp = Number.parseInt(vacationItem.end_timestamp.toString());

        // // console.log('v.start_timestamp <= timestamp', vacationItem.start_timestamp <= slotTimestamp);
        // // console.log('v.end_timestamp > timestamp', vacationItem.end_timestamp > slotTimestamp);
        if(vacationItem.start_timestamp <= slotTimestamp && vacationItem.end_timestamp > slotTimestamp){
          onVacation = true;
        }

        // Check intersections, the end of a slotTimestamp cant be in the vacation intersection
        const slotPlusService = this.addTime(slot, servicoDetails.duracao);
        const timestampPlusServicoTime = Number.parseInt(
          this.getFilterDateStartComponents()
          + (slotPlusService.hour > 9 ? slotPlusService.hour.toString() : ('0' + slotPlusService.hour.toString()))
          + (slotPlusService.mins > 9 ? slotPlusService.mins.toString() : ('0' + slotPlusService.mins.toString()))
        );
        // console.log('timestampPlusServicoTime', timestampPlusServicoTime);
        if (vacationItem.start_timestamp < timestampPlusServicoTime && vacationItem.start_timestamp > slotTimestamp) {
          onVacation = true;
        }


      });

      if(onVacation){
        return;
      }
      //===========================================
      //END CHECK RECESSO

      let add = true;
      let vagasOcupadas = 0;
      this.agendados.forEach(a => {

        if(a.prestador_id == servicoDetails.prestador_id){

          if(a.servico_id == servicoDetails.servico_id && this.makeTimeObj(a.time_start).hour == slot.hour
              && this.makeTimeObj(a.time_start).mins == slot.mins){
            vagasOcupadas += a.vagas;
          }
          else {

            const start = this.makeTimeObj(a.time_start);
            const end = this.makeTimeObj(a.time_end);
            const slotEnd = this.addTime(slot, servicoDetails.duracao);

            if(this.isGreaterTime(end, start) && this.isGreaterEqualTime(slot, start) && this.isGreaterTime(end, slot)){
              add = false;
            }
            else if(this.isGreaterTime(start, end) && (this.isGreaterEqualTime(slot, start) || this.isGreaterTime(end, slot))){
              add = false;
            }
            else if(this.isGreaterTime(slotEnd, slot) && this.isGreaterEqualTime(start, slot) && this.isGreaterTime(slotEnd, start)){
              add = false;
            }
            else if(this.isGreaterTime(slot, slotEnd) && (this.isGreaterEqualTime(start, slot) || this.isGreaterTime(slotEnd, start))){
              add = false;
            }

          }
        }

      });

      if(vagasOcupadas >= servicoDetails.vagas){
        add = false;
      }

      if(add){
        const o = new OpenSchedule();
        o.id = i;
        o.openScheduleStr = this.toHourStr(slot);
        o.selected = false;
        o.vagas = servicoDetails.vagas - vagasOcupadas;
        this.openSchedule.push(o);
      }

    });

    if(this.openSchedule.length === 0){
      this.showNoOpenSchedule = true;
    }
    else{
      this.showNoOpenSchedule = false;
    }

    if(this.preSelectedHour != undefined){
      this.openSchedule.forEach(s => {
        if(s.openScheduleStr == this.preSelectedHour){
          this.escolheHorario(s);
          this.preSelectedHour = undefined;
        }
      });
    }

  }


  checkTodayWorkhour() {

    const dateNow = new Date();

    let adjustedDay: number;
    if (dateNow.getDay() === 0) {
      adjustedDay = 7;
    } else {
      adjustedDay = dateNow.getDay();
    }

    // // console.log(adjustedDay);

    const todayWhs = this.company.workhours.filter(wh => {
      return wh.week_day_id == adjustedDay;
    });

    todayWhs.forEach(todayWorkhour =>{

      if(this.shopStatus == true){
        return;
      }

      // tslint:disable: radix
      let viraDia = false;

      const openHourToday = Number.parseInt(todayWorkhour.open_time.substr(0, 2));
      const openMinToday = Number.parseInt(todayWorkhour.open_time.substr(3, 2));
      const closeHourToday = Number.parseInt(todayWorkhour.close_time.substr(0, 2));
      const closeMinTpday = Number.parseInt(todayWorkhour.close_time.substr(3, 2));

      if (openHourToday > closeHourToday) {
        viraDia = true;
      } else if (openHourToday == closeHourToday) {
        if (openMinToday > closeMinTpday) {
          viraDia = true;
        }
      }
      if (openHourToday < dateNow.getHours()
        && closeHourToday > dateNow.getHours()) {
        this.shopStatus = true;
      } else if (openHourToday === dateNow.getHours()
        && openMinToday < dateNow.getMinutes()
        && closeHourToday > dateNow.getHours()) {
        this.shopStatus = true;
      } else if (closeHourToday === dateNow.getHours()
        && closeMinTpday > dateNow.getMinutes()) {
        this.shopStatus = true;
      } else {
        this.shopStatus = false;
      }
      if (this.shopStatus) {
        this.closeOpenTime = todayWorkhour.close_time;
      } else {
        if (openHourToday > dateNow.getHours()) {
          this.closeOpenTime = todayWorkhour.open_time;
        } else if (openHourToday === dateNow.getHours()
          && openMinToday > dateNow.getMinutes()) {
          this.closeOpenTime = todayWorkhour.open_time;
        } else {
          this.closeOpenTime = undefined;
        }
        if (openHourToday === closeHourToday
          && openMinToday === closeMinTpday) {
          this.closeOpenTime = undefined;
        }
      }

      if (!this.shopStatus) {

        let yesterdayId: number;
        if (adjustedDay == 1) {
          yesterdayId = 7;
        } else {
          yesterdayId = adjustedDay - 1;
        }

        const yesterdayWhs = this.company.workhours.filter(wh => {
          return wh.week_day_id == yesterdayId;
        });

        yesterdayWhs.forEach(yesterday => {

          const openHourYesterday = Number.parseInt(yesterday.open_time.substr(0, 2));
          const openMinYesterday = Number.parseInt(yesterday.open_time.substr(3, 2));
          const closeHourYesterday = Number.parseInt(yesterday.close_time.substr(0, 2));
          const closeMinYesterday = Number.parseInt(yesterday.close_time.substr(3, 2));

          if (closeHourToday < openHourToday
            && dateNow.getHours() > openHourToday) {
            this.shopStatus = true;
            this.closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourToday == openHourToday
            && closeMinTpday < openMinToday
            && dateNow.getHours() > openHourToday) {
            this.shopStatus = true;
            this.closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourToday < openHourToday
            && dateNow.getHours() == openHourToday
            && dateNow.getMinutes() > openMinToday) {
            this.shopStatus = true;
            this.closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourToday == openHourToday
            && closeMinTpday < openMinToday
            && dateNow.getHours() == openHourToday
            && dateNow.getMinutes() > openMinToday) {
            this.shopStatus = true;
            this.closeOpenTime = todayWorkhour.close_time;
          } else if (closeHourYesterday < openHourYesterday
            && dateNow.getHours() < closeHourYesterday) {
            this.shopStatus = true;
            this.closeOpenTime = yesterday.close_time;
          } else if (closeHourYesterday == openHourYesterday
            && closeMinYesterday < openMinYesterday
            && dateNow.getHours() < closeHourYesterday) {
            this.shopStatus = true;
            this.closeOpenTime = yesterday.close_time;
          } else if (closeHourYesterday < openHourYesterday
            && dateNow.getHours() == closeHourYesterday
            && dateNow.getMinutes() < closeMinYesterday) {
            this.shopStatus = true;
            this.closeOpenTime = yesterday.close_time;
          } else if (closeHourYesterday == openHourYesterday
            && closeMinYesterday < openMinYesterday
            && dateNow.getHours() == closeHourYesterday
            && dateNow.getMinutes() < closeMinYesterday) {
            this.shopStatus = true;
            this.closeOpenTime = yesterday.close_time;
          }

        });
      }

    });

    if(this.shopStatus == false && todayWhs.length > 1){

      const hour = dateNow.getHours();
      const min = dateNow.getMinutes();

      const whAux = todayWhs.filter(wh => {

        return Number.parseInt(wh.open_time.substr(0, 2)) > hour
            || (Number.parseInt(wh.open_time.substr(0, 2)) == hour && Number.parseInt(wh.open_time.substr(3, 2)) >= min)

      });

      if(whAux.length == 0){
        this.closeOpenTime = undefined;
      }
      else if(whAux.length == 1){
        this.closeOpenTime = whAux[0].open_time;
      }
      else {
        if(whAux[0].open_time < whAux[1].open_time){
          this.closeOpenTime = whAux[0].open_time;
        }
        else{
          this.closeOpenTime = whAux[1].open_time;
        }
      }

    }

  }

  updateUnavailableDivHeight(){

    if (this.company.blocked_by_payment) {
      //
      const div = document.getElementById('agendamentoRootDiv');
      const tmpWindowHeight = div.offsetHeight;
      this.unavailableDivHeight = (tmpWindowHeight >= this.unavailableDivHeight) ? (tmpWindowHeight+100) : this.unavailableDivHeight;
      this.refresher.detectChanges();
    }
  }

  // openWorkhourModal(): void{
  //   return;
  //   // document.getElementById('openWorkhourModalBtn').click();
  // }

  expandCategory(pcat: ServiceCategory) {
    pcat.expanded = !pcat.expanded;
  }

  scrollToCategory(categItemIndex: number){
    const itemId = "categItemVertical" + categItemIndex;
    this.serviceCategoryList[categItemIndex].expanded = true;
    document.getElementById(itemId).scrollIntoView({behavior: "smooth"});
  }

  goToClientLogin(){
    this.router.navigateByUrl('client/login');
  }

  goToClientAgendamentoList(){
    document.getElementById("dismissAcessoModal").click();
    if(localStorage.getItem('appuser_id') != undefined){
      this.router.navigateByUrl('client/agendamento-list');
    } else {
      this.router.navigateByUrl('client/login');
    }
  }

  goToClubeDeAssinaturas(){
    if(localStorage.getItem('appuser_id') != undefined){
      this.router.navigateByUrl('client/assinatura');
    } else {
      this.router.navigateByUrl('client/login');
    }
  }

  copyPixKey() {
    const pixKeyInput = document.getElementById('pixKeyInput') as HTMLInputElement;
    pixKeyInput.select();
    document.execCommand('copy');
  }

  addToCart() {
    this.agendamentoAFazer.system_source = "link_empresa";
    this.agendamentoAFazer.timeZone = new Date().getTimezoneOffset();

    if (localStorage.getItem('appuser_id') != undefined) {
      this.agendamentoAFazer.appuser_id = Number.parseInt(localStorage.getItem('appuser_id'));
    }

    this.agendamentoAFazer.campo_flexivel_label = this.company.campo_flexivel_label;

    const { ...newAgendamento } = this.agendamentoAFazer;
    this.agendamentoCart.push(newAgendamento);

    // Verifica se o carrinho está recebendo os agendamentos
    // console.log('Agendamentos no carrinho:', this.agendamentoCart);

    this.agendamentoAFazer = new Agendamento();
    this.agendamentoAFazerStatuses.prestadorSelected = false;
    this.agendamentoAFazerStatuses.serviceSelected = false;
    this.agendamentoAFazerStatuses.timeSelected = false;

    if (this.company.price_perc != undefined) {
      this.calcPixValue();
    }

    this.serviceCategoryList.forEach(categ => {
      categ.expanded = false;
      categ.serviceList.forEach(s => {
        s.selected = false;
      });
    });
  }




  saveAgendamentosToLocalStorage(agendamentos: Agendamento[]) {
    localStorage.setItem('agendamentoCart', JSON.stringify(agendamentos));
  }




  continueBooking(){
    document.getElementById("dismissContentModal").click();
    this.getAgendaDoDia();
  }

  avancaListaEspera(content) {

    if(!this.isServicoValid() || !this.isPrestadorValid() || !this.isDateValid()){
      return;
    }


    this.agendamentoAFazer.data_agendamento = this.filterDateStartDbFormat;
    this.agendamentoAFazer.data_agendamentoView = this.filterDateStartView;

    this.prestadorLstView.forEach( (itemPrestador) => {
      if (itemPrestador.prestador_id == this.agendamentoAFazer.prestador_id) {

        this.agendamentoAFazer.preco = itemPrestador.preco;
        this.agendamentoAFazer.servico_name = itemPrestador.servicoName;
        this.agendamentoAFazer.prestador_name = itemPrestador.prestadorName;
        this.agendamentoAFazer.preco_mode = itemPrestador.preco_mode;

      }
    });

    // everything ok, open confirmation modal
    // document.getElementById("openConfirmacaoAgendModal").click();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  saveDataToLocalStorage(dataKey: string, dataValue: any) {
    localStorage.setItem(dataKey, JSON.stringify(dataValue));
  }

  getAgendamentosFromLocalStorage(): Agendamento[] {
    const storedData = localStorage.getItem('agendamentoCart');
    return storedData ? JSON.parse(storedData) : [];
  }



  salvarListaEspera(){

    if(!this.isNameValid(this.agendamentoEspera.nome_cliente)){
      return;
    }

    if(!this.isPhoneValid(this.agendamentoEspera.telefone_cliente)){
      return;
    }

    this.agendamentoEspera.company_id = parseInt(this.company_id);
    this.agendamentoEspera.data_agendamento = this.agendamentoAFazer.data_agendamento;
    this.agendamentoEspera.prestador_name = this.agendamentoAFazer.prestador_name;
    this.agendamentoEspera.servico_name = this.agendamentoAFazer.servico_name;
    this.agendamentoEspera.data_agendamentoView = this.filterDateStartView;
    this.agendamentoEspera.preco = this.agendamentoAFazer.preco;
    this.agendamentoEspera.preco_mode = this.agendamentoAFazer.preco_mode;

    if(localStorage.getItem('appuser_id') != undefined){
      this.agendamentoEspera.appuser_id = Number.parseInt(localStorage.getItem('appuser_id'));
    }

    this.ngxLoaderSvc.start();
    this.esperaSvc.salvarAgendamentoEspera(this.agendamentoEspera).subscribe({
      next: (data) => {
        this.ngxLoaderSvc.stop();

        document.getElementById("dismissEsperaModal").click();

        // check if whats message enabled
        if (this.company.sends_whats_message) {
          this.createWaitListMessage();
          this.openEnvioMensagemModal();
        } else {
          // this.openConfirmsBookingModal();
          document.getElementById("openConfirmsEsperaDone").click();
        }

        this.agendamentoAFazer = new Agendamento();
        this.agendamentoEspera = new AgendamentoEspera();
        this.resetVars();
        this.agendamentoAFazerStatuses.serviceSelected = false;

      },
      error: (error) => {
        this.ngxLoaderSvc.stop();
        // console.log(error);
        alert(error);
      }
    });

  }

  isServicoValid(): boolean{

    if (this.agendamentoAFazer.servico_id == 0) {
      alert("Por favor selecione o serviço");
      return false;
    }

    return true;
  }

  isPrestadorValid(): boolean {

    if (this.agendamentoAFazer.prestador_id == 0) {
      alert("Por favor selecione o profissional");
      return false;
    }

    return true
  }

  isDateValid(): boolean {

    // verifica se data menor que hoje
    const hoje = new Date();
    const dataEscolhida = new Date(this.filterDateStartDbFormat);
    dataEscolhida.setDate(dataEscolhida.getDate() + 1);
    const timeDiff = dataEscolhida.getTime() - hoje.getTime();
    if (timeDiff < 0) {
      alert("Por favor selecione uma data a partir de hoje");
      return false;
    }

    // verifica se agendamento esta sendo feito alem da tolerancia
    const daysDiff = timeDiff / (1000*60*60*24);
    if (daysDiff > this.company.tempo_abertura) {
      alert(`Por favor selecione uma data que não ultrapasse ${this.company.tempo_abertura} dias a partir de hoje`);
      return false;
    }

    return true;
  }

  isNameValid(name: string): boolean{
    if (!name || name.length == 0 || name.length < 4){
      alert("O nome deve ter mais de 3 caracteres");
      return false;
    }

    return true;
  }

  isPhoneValid(phone: string): boolean {

    if (!phone || phone == ""
        || phone.length < 14){
      alert("Por favor digite o telefone do responsável pelo agendamento");
      return false;
    }

    return true;
  }

  checkWorkhours(): boolean {

    let showBtn = false;
    this.prestadorWorkhourLst.forEach(h => {
      if(h.start_time != h.end_time){
        showBtn = true;
      }
    });

    return showBtn;
  }

  calcPixValue(): number {
    this.pixValue = 0;
    let paymentValue = 0; // Nova variável para o valor a ser pago

    // Itera sobre os agendamentos no carrinho
    this.agendamentoCart.forEach(a => {
      if (a.preco_mode !== 2) {
        this.pixValue += parseFloat(a.preco.toString());
      }
    });

    // Verifica se payment_integration_completed é true para aplicar a porcentagem no valor a ser pago
    if (this.payment_integration_completed) {
      // Converte percentage_value_payment para número e aplica a porcentagem
      paymentValue = this.pixValue * (parseFloat(this.percentage_value_payment.toString()) / 100);
    } else {
      // Caso contrário, usa o company.price_perc
      paymentValue = this.pixValue * this.company.price_perc;
    }

    // Exibe os valores calculados
    // console.log('Valor total dos serviços:', this.pixValue);
    // console.log('Valor calculado do PIX:', paymentValue);

    return paymentValue; // Retorna o valor calculado a pagar
  }



  openPaymentAndCalculate(a, templateRef) {
    this.openPaymentModal(templateRef, a);  // Abre o modal de pagamento
    this.calcPixValue();  // Calcula o valor dos serviços
  }



  buildWhatsLink(message: string) {
    // Verificar o valor de APP_RUN_MODE
    // console.log('APP_RUN_MODE:', APP_RUN_MODE);
    // console.log('destinationPhone antes do replace:', this.destinationPhone);

    if (APP_RUN_MODE === APP_RUN_MODE_DEF.WEB_ANDROID || APP_RUN_MODE === APP_RUN_MODE_DEF.WEB_IOS) {
        const destinationPhone2 = this.destinationPhone.replace('+', '');   // remove the plus from original
        // console.log('destinationPhone2 após o replace:', destinationPhone2);

        this.whatsLink = "whatsapp://send?phone=";
        this.whatsLink = this.whatsLink.concat(destinationPhone2);
        this.whatsLink = this.whatsLink.concat('&text=');

        // console.log('WhatsApp link para mobile:', this.whatsLink);
    } else {
        // console.log('Destino não é mobile, gerando link para web');

        this.whatsLink = 'https://web.whatsapp.com/send?phone=' + this.destinationPhone;
        this.whatsLink = this.whatsLink.concat('&text=');

        // console.log('WhatsApp link para web:', this.whatsLink);
    }

    this.whatsLink = this.whatsLink.concat(encodeURIComponent(message));
    // console.log('WhatsApp link final:', this.whatsLink);
}


  getMessageServiceString(servico_name): string {
    return (`💼 *Serviço:* ${servico_name}\n\n`);
  }

  getMessageProfessionalString(prestador_name): string {
    return (`👤 *Profissional:* ${prestador_name}\n\n`);
  }

  getMessageDayString(data_agendamentoView, time_start): string {
    let retString = `🗓️ *Dia:* ${data_agendamentoView}`;
    if (!!time_start) {
      retString = retString.concat(` às ${time_start}`);
    }
    retString = retString.concat('\n\n');
    return retString;
  }

  getMessagePriceString(preco_mode: number, preco: number): string {
    let retString = '';
    const priceStr = preco ?
      preco.toString().replace('.', ',')
      : '';

    if(preco_mode == 1){
      retString = `💵 *Valor:* R$${priceStr}\n\n`;
    }
    else if(preco_mode == 2){
      retString = `💵 *Valor:* Sob consulta\n\n`;
    }
    else if(preco_mode == 3){
      retString = `💵 *Valor:* A partir de R$${priceStr}\n\n`;
    }
    return retString;
  }

  getMessageClientString(nome_cliente: string): string {
    return (`*Cliente(s):* ${nome_cliente}\n`);
  }

  getMessagePhoneString(telefone_cliente: string): string {
    return (`*Telefone:* ${telefone_cliente}\n`);
  }

  getPaymentValueString(selectedPaymentAmount: any): string {
    let retString = '';
    const selectedPaymentAmountStr = selectedPaymentAmount.toString().replace('.', ',');
    retString = `💰 *Valor pago:* R$${selectedPaymentAmountStr}\n\n`;
    return retString;
}



  getMessageObservationString(obs: string): string {
    let resString = '';
    if(obs != undefined && obs.length > 0){
      resString = `*Observação:* ${obs}\n`;
    }
    return resString;
  }

  getMessageFlexibleFieldString(campo_flexivel_label: string, campo_flexivel: string): string {
    let resString = '';
    if(campo_flexivel != undefined && campo_flexivel.length > 0){
      resString = `*${campo_flexivel_label}:* ${campo_flexivel}\n`;
    }
    return resString;
  }

  getMessageEndString(): string {
    const dateNow = new Date();
    const strDiaMesAno = `${dateNow.getDate()}/${(dateNow.getMonth() + 1)}/${dateNow.getFullYear()}`;
    const strHoraMin = `${dateNow.getHours()}:${dateNow.getMinutes()}`;
    const endStr = `\n✅ Agendamento realizado no dia ${strDiaMesAno} às ${strHoraMin} pelo WhatsAgenda Web`;
    return endStr;
  }

  navigateToHome(){
    document.getElementById("dismissAcessoModal").click();
    this.router.navigateByUrl('/');
  }

  navigateToAcessoCliente() {
    document.getElementById("dismissAcessoModal").click();

    // Check if localStorage has the required items before setting isClientLoggedin
    const appuserToken = localStorage.getItem('appuserToken');
    const appuserId = localStorage.getItem('appuser_id');
    this.isClientLoggedin = appuserToken && appuserId ? true : false;
    // console.log('isClientLoggedin: ', this.isClientLoggedin);
    if(this.isClientLoggedin){
      this.goToClientAgendamentoList();
    } else {
      this.goToClientLogin();
    }
  }


  logout(){
    document.getElementById("dismissAcessoModal").click();
    localStorage.removeItem('appuser_id');
    localStorage.removeItem('appuserToken');
    window.location.reload();
  }



  /**
   * Returns filterDateStart as '20241003', for example
   * @returns string
   */
  private getFilterDateStartComponents(): string {
    return (
      this.filterDateStart.year.toString()
      + (this.filterDateStart.month > 9
          ? this.filterDateStart.month.toString() : ('0' + this.filterDateStart.month.toString()))
      + (this.filterDateStart.day > 9
          ? this.filterDateStart.day.toString() : ('0' + this.filterDateStart.day.toString()))
    );
  }

  /**
   * checks agendamentoCart properties to check if can proceed or not
   */
  private checkAgendamentoObject(): boolean {
    const shouldContinue = [true, true, true, true, true, true, true];

    // Recuperar o UID de pagamento gerado
    const uid_payment = Number(sessionStorage.getItem('paymentUid'));
    // console.log('UID de pagamento recuperado da sessão para salvar agendamento:', uid_payment);

    this.agendamentoCart.forEach(a => {
      const agendamentoDate = new Date(this.filterDateStart.year, this.filterDateStart.month - 1, this.filterDateStart.day);
      agendamentoDate.setHours(Number.parseInt(a.time_start.substring(0, 2)));
      agendamentoDate.setMinutes(Number.parseInt(a.time_start.substring(3, 5)));

      if (agendamentoDate.getTime() < new Date().getTime()) {
        this.getPrestadorWorkhour();
        shouldContinue[0] = false;
      }

      // Verificar variáveis, se não estão vazias
      a.nome_cliente = '';
      for (let i = 0; i < a.names.length; i++) {
          if (a.names[i] == undefined || a.names[i].length == 0 || a.names[i].length < 4) {
            shouldContinue[1] = false;
            break;
          }

          if (i < a.names.length-1) {
            a.nome_cliente += a.names[i] + ', ';
          }
          else{
              a.nome_cliente += a.names[i];
          }
      }

      if (a.telefone_cliente == undefined || a.telefone_cliente == "" || a.telefone_cliente.length < 14) {
          shouldContinue[2] = false;
      }

      const phoneNumber = a.telefone_cliente.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
      let validNumber = false;
      const lastChar = phoneNumber.charAt(0);
      for(let i = 0; i < phoneNumber.length; i++){
        if(phoneNumber.charAt(i) != lastChar){
          validNumber = true;
        }
      }

      if(!validNumber){
          shouldContinue[3] = false;
      }

      if (a.vagas == undefined || a.vagas <= 0){
          shouldContinue[4] = false;
      }

      if (a.vagas > this.vagasDisponiveis) {
          shouldContinue[5] = false;
      }

      if(this.company.is_campo_flexivel_mandatory && !a.campo_flexivel){
          shouldContinue[6] = false;
      }

      // Atribuir o UID de pagamento gerado ao agendamento
      a.uid_payment = uid_payment;
    });

    if (!shouldContinue[0]) {
      alert('Data de agendamento inválida');
    }

    if (!shouldContinue[1]) {
      alert("O nome de todos os participantes deve ter mais de 3 caracteres");
    }

    if (!shouldContinue[2]) {
      alert("Por favor digite o telefone do responsável pelo agendamento");
    }

    if (!shouldContinue[3]) {
      alert("Por favor digite um número de telefone válido");
    }

    if (!shouldContinue[4]) {
      alert("Por favor digite quantas vagas deseja utilizar");
    }

    if (!shouldContinue[5]) {
      alert(`O limite de vagas foi ultrapassado, são ${this.vagasDisponiveis} vagas disponíveis.`);
    }

    if (!shouldContinue[6]) {
      alert(`Por favor preencha o campo ${this.company.campo_flexivel_label}.`);
    }

    const hasProhibition = shouldContinue.findIndex(el => el === false);
    if (hasProhibition === -1) {
      return true;
    }
    return false;
  }
}
