import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { Config } from 'src/app/svc/config';
import { City } from './City';
import { BaseService } from 'src/app/svc/company/base.service';

@Injectable({
  providedIn: 'root'
})

export class CityService extends BaseService{
  baseURL = `${Config.vWebUrl}city_uf/`;

  constructor(private h: HttpClient) {
    super(h);
  }

  getRows(stateId) {
    return this.http.get<City>(this.baseURL + `${stateId}/-1`, {
      headers: this.getCommonHeaders_compToken2Conditional(false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getById(id){
    return this.http.get<City>(this.baseURL + `${id}/1`, {
      headers: this.getCommonHeaders_compToken2Conditional(false)
    })
    .pipe(
      map((data: any) => {
          //
          if (data.status != "200") {
              catchError(this.handleErrors);
          }
          return data.result;
      }),
      catchError(this.handleErrors)
    );
  }

  getCityStateByCityName(pCityName){
    return this.http.get<City>(this.baseURL + `-3/${pCityName}/0`, {
      headers: this.getCommonHeaders_compToken2Conditional(false)
    })
    .pipe(
      map((data: any) => {
        if (data.status != "200") {
          catchError(this.handleErrors);
        }
        return data.result;
      }),
      catchError(this.handleErrors)
    );
  }
  
}
