import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { PaywallContent } from './paywall-content';


@Component({
  selector: 'app-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss']
})
export class PaywallComponent implements OnInit {

  @Input() paywallContent: PaywallContent;
  @Input() videoLinkInput = '';
  videoURL: any;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.paywallContent = new PaywallContent();
  }

  ngOnInit() {
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoLinkInput);
  }

  goToUsagePlans(){
    if(this.route.snapshot.routeConfig.path.includes('ageda')){
      this.router.navigate(['ambiente/agenda/usage-plans']);
    }
    else{
      this.router.navigate(['ambiente/catalogo/usage-plans']);
    }
  }

}
