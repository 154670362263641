<div class="container" *ngIf="!agendamentoConfirmado && !agendamentoCancelado">
    <div class="card">
      <h1>Confirme o seu agendamento</h1>
      <div class="appointment-details">
        <h2>CONFIRMAR AGENDAMENTO</h2>
        <p><strong>Serviço:</strong> {{agendamento[0].servico_name}}</p>
        <p><strong>Profissional:</strong> {{agendamento[0].prestador_name}}</p>
        <p><strong>Dia:</strong> {{agendamento[0].data_agendamento}}</p>
        <p><strong>Horário:</strong> {{agendamento[0].time_start}} às {{agendamento[0].time_end}}</p>
        <p *ngIf="preco_mode == 1"><strong>Preço:</strong> R$ {{agendamento[0].preco}}</p>
        <p *ngIf="preco_mode == 2"><strong>Preço:</strong> Sob consulta</p>
        <p *ngIf="preco_mode == 3"><strong>Preço:</strong> A partir de R$ {{agendamento[0].preco}}</p>

      </div>
      <div class="actions">
        <button type="button" class="cancel" (click)="cancelAppointment()">Cancelar</button>
        <button type="button" class="confirm" (click)="confirmar()">Confirmar</button>
      </div>
    </div>
  </div>


  <div *ngIf="agendamentoConfirmado" class="confirmado">
    <h1>Seu horário foi confirmado<br>com sucesso!</h1><br>
    <img class="imgConfirmCancel" src="../../assets/icons/confirm.png" alt="Agendamento confirmado"><br>
    <br><p>Te aguardamos no dia e horário agendado!</p>
  </div>

  <div *ngIf="agendamentoCancelado" class="confirmado">
    <h1>Seu horário foi cancelado!</h1><br>
    <img class="imgConfirmCancel" src="../../assets/icons/cancel.png" alt="Agendamento cancelado"><br>
    <br><p>Avise o estabelecimento sobre o cancelamento e tente já remarcar para uma próxima data!</p>
  </div>