import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'porcentTwoDigits'})
export class PorcentTwoDigitsPipe implements PipeTransform {
  transform(pValor: any): string {
    if (typeof pValor == 'string') {
      pValor = parseFloat(pValor);
    }

    else if (pValor == undefined ){
      pValor = 0;
    }

    let pValorStr = "";
    pValorStr = pValor.toFixed(2).replace('.', ',');
    return pValorStr;
  }
}
